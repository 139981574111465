import {
  CommonEntitiesListResponse,
  PaginatedProfessionalHrDepartmentSearchCriteriaDto,
  ProfessionalQuickSearchResponse,
  ProfessionalResponseDto,
  QuickSearchQueryDto,
} from '@docbay/schemas';

// axiosInstance is imported to make HTTP requests to the API.
import axiosInstance from './axiosInstance';

export const HrDepartmentSearchAPI = {
  /**
   * Fetches a paginated list of professionals based on the given search criteria.
   *
   * @param data - The search criteria for fetching professionals.
   * @returns A promise that resolves to a CommonEntitiesListResponse containing the fetched professionals.
   */
  fetchHrDepartmentSearch: (
    data: PaginatedProfessionalHrDepartmentSearchCriteriaDto,
  ) => {
    return axiosInstance.post<
      CommonEntitiesListResponse<ProfessionalResponseDto>
    >('/professionals/hr-department-search', data);
  },

  /**
   * Fetches a professional by their ID.
   *
   * @param professionalId - The ID of the professional to fetch.
   * @returns A promise that resolves to the ProfessionalResponseDto containing the fetched professional's details.
   */
  getHrDepartmentSearchProfessional: (professionalId: string) => {
    return axiosInstance.get<ProfessionalResponseDto>(
      `professionals/hr-department-search/${professionalId}`,
    );
  },

  /**
   * Performs a quick search for professionals based on the given query criteria.
   *
   * @param data - The query criteria for performing the quick search.
   * @returns A promise that resolves to a ProfessionalQuickSearchResponse containing the search results.
   */
  quickSearch: (data: QuickSearchQueryDto) => {
    return axiosInstance.post<ProfessionalQuickSearchResponse>(
      '/professionals/hr-department-search/quick-search',
      data,
    );
  },
};
