import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { HelpBar } from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { HrDepartmentSearchActions } from 'application/store/reducers/HrDepartmentSearch/actions';
import { requestSelector } from 'application/store/reducers/request/selectors';
import {
  clearPaymentData,
  getSubscriptionDoctorId,
} from 'application/helpers/sessionStorage/assignEmployees';
import {
  setAssignToPackage,
  setChosenPackage,
} from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { resetProfessionalProfile } from 'application/store/reducers/HrDepartmentSearch/hrDepartmentSearchSlice';

import { Loader } from 'common';

import { DoctorInfo, ClinicInfo } from './components';
import { Wrapper } from './styles';

const DoctorProfile: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getHrDepartmentSearchProfessional } = useAppSelector(requestSelector);

  const isLoading = getHrDepartmentSearchProfessional.loading;

  useEffect(() => {
    if (id) {
      const subscriptionDoctorId = getSubscriptionDoctorId();
      if (subscriptionDoctorId !== id) {
        dispatch(setAssignToPackage([]));
        dispatch(setChosenPackage(null));
        clearPaymentData();
      }
      dispatch(HrDepartmentSearchActions.getHrDepartmentSearchProfessional(id));
    }
    return () => {
      dispatch(resetProfessionalProfile());
    };
  }, []);

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HelpBar text={t('global.back')} />
      <DoctorInfo />
      <ClinicInfo />
    </Wrapper>
  );
};

export default DoctorProfile;
