import {
  CommonEntitiesListResponse,
  CompanyPurchasedSubscriptionExtendedResponse,
  CompanyPurchasedSubscriptionResponse,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

/**
 * API methods for managing company purchased subscriptions.
 */
export const SubscriptionsAPI = {
  /**
   * Retrieves a list of company purchased subscriptions.
   *
   * @param page - The page number to retrieve. Defaults to 1 if not provided.
   * @returns A response object containing the list of company purchased subscriptions.
   */
  getSubscriptions: (page: number) => {
    return axiosInstance.get<
      CommonEntitiesListResponse<CompanyPurchasedSubscriptionResponse>
    >('/hr-departments/subscriptions/purchased', {
      params: { limit: 10, page },
    });
  },

  /**
   * Retrieves a company purchased subscription by its ID.
   *
   * @param companyPurchasedSubscriptionId - The unique identifier of the company purchased subscription to retrieve.
   * @returns A response object containing the details of the company purchased subscription.
   */
  getSubscriptionById: (companyPurchasedSubscriptionId: string) => {
    return axiosInstance.get<CompanyPurchasedSubscriptionExtendedResponse>(
      `/hr-departments/subscriptions/purchased/${companyPurchasedSubscriptionId}`,
    );
  },

  /**
   * Deletes a company purchased subscription by its ID.
   *
   * @param companyPurchasedSubscriptionId - The unique identifier of the company purchased subscription to delete.
   */
  deleteSubscription: (companyPurchasedSubscriptionId: string) => {
    return axiosInstance.delete(
      `/hr-departments/subscriptions/purchased/${companyPurchasedSubscriptionId}`,
    );
  },
};
