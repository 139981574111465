import { useState, useEffect } from 'react';

/**
 * The useDebounce hook provides a debounced version of a string value,
 * meaning it will only update after a certain amount of time has passed.
 *
 * @param value - The string value to debounce.
 * @param milliSeconds - The number of milliseconds to wait before updating the debounced value.
 * @returns The debounced version of the input value.
 */
export const useDebounce = (value: string, milliSeconds: number) => {
  // State hook to store the debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Effect hook to update the debounced value after a delay
  useEffect(() => {
    // Set up a timeout handler to update the debounced value
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    // Clear the timeout handler if the component unmounts or the dependencies change
    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  // Return the debounced value
  return debouncedValue;
};
