import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronLeft } from '@mui/icons-material';

import { DocBaySupportLink } from 'application/constants/externalLinks';
import { HelpIcon } from 'application/assets';

import { HelpBarProps } from './models';
import { Wrapper, ButtonBack } from './styles';

/**
 * Functional component that renders a help bar with a back button and a link to the help center.
 * @param text - The text to display on the back button
 * @param pathBack - The path to navigate to when the back button is clicked. If not provided, will navigate to the previous page in history.
 */
const HelpBar: FC<HelpBarProps> = ({ text, pathBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    if (pathBack) {
      navigate(pathBack);
    } else {
      navigate(-1);
    }
  };

  return (
    <Wrapper>
      <ButtonBack type="button" onClick={goBack}>
        <ChevronLeft />
        {text}
      </ButtonBack>

      <Link to={DocBaySupportLink} target="_blank">
        <HelpIcon />
        {t('global.help_center')}
      </Link>
    </Wrapper>
  );
};

export default HelpBar;
