import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { MapBoxAllLocations } from 'application/types/mapBox';

// Generate a unique session token for each request to prevent caching issues
const sessionToken = uuid();
// Get the access token from environment variables
const accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

// Create an axios instance with the base URL for Mapbox API requests
const axiosInstance = axios.create({
  baseURL: 'https://api.mapbox.com/search/searchbox/v1',
});

/**
 * Fetch all locations based on the given address and country.
 *
 * @param params - An object containing the address and country to search for.
 * @returns A Promise that resolves to a MapBoxAllLocations response from the API.
 */
export const fetchAllLocations = (params: {
  address: string;
  country: string;
}) =>
  axiosInstance.get<MapBoxAllLocations>(
    `suggest?q=${params.address}&country=${params.country}&types=address,street,city,postcode&access_token=${accessToken}&session_token=${sessionToken}`,
  );

/**
 * Retrieve a specific location by its ID.
 *
 * @param id - The unique ID of the location to retrieve.
 * @returns A Promise that resolves to any response from the API.
 */
export const getLocations = (id: string) =>
  axiosInstance.get<any>(
    `retrieve/${id}?access_token=${accessToken}&session_token=${sessionToken}`,
  );

/**
 * Get a location based on its geographical coordinates.
 *
 * @param longitude - The longitude of the location to search for.
 * @param latitude - The latitude of the location to search for.
 * @returns A Promise that resolves to any response from the API.
 */
export const getLocationByCoordinate = (longitude: number, latitude: number) =>
  axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}&types=region`,
  );
