import { useEffect, useState } from 'react';

import { geolocationService } from 'application/helpers/geolocationService';
import { calculateDistance } from 'application/helpers/location';
/**
 * useGeolocation custom hook for handling user's geolocation.
 *
 * @property {function} getDistance - Returns the distance between current user coordinates and provided coordinates.
 * @property {function} requestGeoPermissions - Requests user's permission to access their location.
 * @property {boolean} isGeoPermissionsGranted - Indicates whether or not the user has granted permission to access their location.
 */
export const useGeolocation = () => {
  /**
   * State for storing the current user's geolocation coordinates.
   *
   * @type {Object|null}
   */
  const [userCoordinates, setUserCoordinates] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);

  /**
   * State for indicating whether or not the user has granted permission to access their location.
   *
   * @type {boolean}
   */
  const [isGeoPermissionsGranted, setIsGeoPermissionsGranted] = useState(false);

  /**
   * Requests user's permission to access their location and updates state accordingly.
   */
  const requestGeoPermissions = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(function (result) {
          if (result.state == 'granted') {
            setIsGeoPermissionsGranted(true);
          } else {
            setIsGeoPermissionsGranted(false);
          }
        });
    } else {
      setIsGeoPermissionsGranted(false);
    }
  };

  /**
   * Updates the user's geolocation coordinates state whenever the user grants permission to access their location.
   */
  useEffect(() => {
    requestGeoPermissions();
  }, []);

  /**
   * Fetches the current user's geolocation coordinates and updates state accordingly.
   *
   * @listens {isGeoPermissionsGranted}
   */
  useEffect(() => {
    setUserCoordinates(geolocationService.getGeolocation());
  }, [isGeoPermissionsGranted]);

  /**
   * Calculates the distance between the current user's geolocation coordinates and provided coordinates.
   *
   * @param {{latitude?: string; longitude?: string}} coordinates - Object containing latitude and longitude values.
   *
   * @returns {number|null} The distance between the two sets of coordinates, or null if either set is missing a value.
   */
  const getDistance = ({
    latitude,
    longitude,
  }: {
    latitude?: string;
    longitude?: string;
  }) => {
    if (!userCoordinates?.latitude || !userCoordinates.longitude) {
      return null;
    }
    if (!latitude || !longitude) {
      return null;
    }
    return calculateDistance(
      userCoordinates.latitude,
      userCoordinates.longitude,
      +latitude,
      +longitude,
    );
  };

  /**
   * Returns an object containing the custom hook's properties.
   *
   * @returns {{getDistance: function; requestGeoPermissions: function; isGeoPermissionsGranted: boolean}}
   */
  return {
    getDistance,
    requestGeoPermissions,
    isGeoPermissionsGranted,
  };
};
