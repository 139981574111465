import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEnum } from 'application/store/reducers/request/model';
import { DepartmentAPI } from 'integration/api/department';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  search?: string;
}

/**
 * Redux thunk that fetches departments by company.
 *
 * @param params - Object containing parameters for the API call.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
export const getDepartmentsByCompany = createAsyncThunk(
  RequestEnum.getDepartmentsByCompany,
  async (params: Props | undefined, { rejectWithValue }) => {
    try {
      const { data } = await DepartmentAPI.getDepartmentsByCompany(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk that creates a new department.
 *
 * @param name - The name of the new department to create.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
export const createDepartment = createAsyncThunk(
  RequestEnum.createDepartment,
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await DepartmentAPI.createDepartment(name);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk that deletes a department.
 *
 * @param params - Object containing parameters for the API call.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
export const deleteDepartment = createAsyncThunk(
  RequestEnum.deleteDepartment,
  async (
    params: { departmentForDelete: string; isForceDelete?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await DepartmentAPI.deleteDepartment(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk that edits an existing department.
 *
 * @param params - Object containing parameters for the API call.
 * @returns A promise that resolves to the response data or rejects with an error.
 */
export const editDepartment = createAsyncThunk(
  RequestEnum.editDepartment,
  async (
    params: { name: string; departmentId?: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await DepartmentAPI.editDepartment(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
