export enum CommonErrors {
  requiredField = 'This field is required',
  emailFormat = 'Check Email format',
}

export enum ERROR_CODE {
  APPOINTMENT_REQUEST_ALREADY_EXISTS = 'APPOINTMENT_REQUEST_ALREADY_EXISTS',
  APPOINTMENT_REQUEST_NOT_FOUND = 'APPOINTMENT_REQUEST_NOT_FOUND',
  CLINIC_OWNER_SUBSCRIPTION_CHANGE_TYPE_NOT_ALLOWED = 'CLINIC_OWNER_SUBSCRIPTION_CHANGE_TYPE_NOT_ALLOWED',
  CLINIC_OWNER_SUBSCRIPTION_CHANGE_NUMBER_OF_PROFESSIONAL_NOT_ALLOWED = 'CLINIC_OWNER_SUBSCRIPTION_CHANGE_NUMBER_OF_PROFESSIONAL_NOT_ALLOWED',
  CLINIC_OWNER_SUBSCRIPTION_NOT_FOUND = 'CLINIC_OWNER_SUBSCRIPTION_NOT_FOUND',
  CLINIC_OWNER_SUBSCRIPTION_UPDATE_FAILED = 'CLINIC_OWNER_SUBSCRIPTION_UPDATE_FAILED',
  CLINIC_PROFESSIONAL_NOT_FOUND = 'CLINIC_PROFESSIONAL_NOT_FOUND',
  CLINIC_PROFESSIONAL_SUBSCRIPTION_TYPE_CHANGE_NOT_ALLOWED = 'CLINIC_PROFESSIONAL_TYPE_CHANGE_NOT_ALLOWED',
  CLINIC_PROFESSIONAL_SUBSCRIPTION_INVALID_EMPLOYEES_LIMITS = 'CLINIC_PROFESSIONAL_SUBSCRIPTION_INVALID_EMPLOYEES_LIMITS',
  INCORRECT_USERNAME_OR_PASSWORD = 'INCORRECT_USERNAME_OR_PASSWORD',
  HEALTH_METRIC_NOT_FOUND = 'HEALTH_METRIC_NOT_FOUND',
  HR_ADMIN_EXISTS_BY_PHONE = 'HR_ADMIN_EXISTS_BY_PHONE',
  HR_ADMIN_NOT_FOUND = 'HR_ADMIN_NOT_FOUND',
  HR_COMPANY_NOT_FOUND = 'HR_COMPANY_NOT_FOUND',
  HR_DEPARTMENT_ALREADY_EXISTS = 'HR_DEPARTMENT_ALREADY_EXISTS',
  HR_DEPARTMENT_COMPANY_HAS_NO_STRIPE_CUSTOMER_ID = 'HR_DEPARTMENT_COMPANY_HAS_NO_STRIPE_CUSTOMER_ID',
  HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS = 'HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS',
  HR_DEPARTMENT_EMPLOYEE_HAVE_PATIENT_CONNECTED = 'HR_DEPARTMENT_EMPLOYEE_HAVE_PATIENT_CONNECTED',
  HR_DEPARTMENT_EMPLOYEE_NOT_FOUND = 'HR_DEPARTMENT_EMPLOYEE_NOT_FOUND',
  HR_DEPARTMENT_EMPLOYEE_CREDIT_NOT_FOUND = 'HR_DEPARTMENT_EMPLOYEE_CREDIT_NOT_FOUND',
  HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED = 'HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED',
  HR_DEPARTMENT_HR_NOT_FOUND = 'HR_DEPARTMENT_HR_NOT_FOUND',
  HR_DEPARTMENT_NOT_FOUND = 'HR_DEPARTMENT_NOT_FOUND',
  HR_LOCATION_HAS_EMPLOYEES_CONNECTED = 'HR_LOCATION_HAS_EMPLOYEES_CONNECTED',
  HR_LOCATION_NAME_ALREADY_EXISTS = 'HR_LOCATION_NAME_ALREADY_EXISTS',
  HR_LOCATION_NOT_FOUND = 'HR_LOCATION_NOT_FOUND',
  HR_USER_EXISTS_BY_PHONE = 'HR_USER_EXISTS_BY_PHONE',
  MULTIPLE_CLINIC_OWNER_SUBSCRIPTIONS_NOT_ALLOWED = 'MULTIPLE_CLINIC_OWNER_SUBSCRIPTIONS_NOT_ALLOWED',
  PATIENT_NOT_FOUND = 'PATIENT_NOT_FOUND',
  STRIPE_INVALID_REQUEST_ERROR = 'STRIPE_INVALID_REQUEST_ERROR',
  STRIPE_CARD_ERROR = 'STRIPE_CARD_ERROR',
  USER_DOES_NOT_HAVE_ACCESS_TO_ENTITY = 'USER_DOES_NOT_HAVE_ACCESS_TO_ENTITY',
  UNIT_OF_MEASUREMENT_ALREADY_EXISTS = 'UNIT_OF_MEASUREMENT_ALREADY_EXISTS',
  UNIT_OF_MEASUREMENT_ALREADY_IN_USE = 'UNIT_OF_MEASUREMENT_ALREADY_IN_USE',
  UNIT_OF_MEASUREMENT_NOT_FOUND = 'UNIT_OF_MEASUREMENT_NOT_FOUND',
  USER_IS_NOT_ALLOWED_TO_DELETE_HIMSELF = 'USER_IS_NOT_ALLOWED_TO_DELETE_HIMSELF',
  USER_IS_NOT_HR_ADMIN = 'USER_IS_NOT_HR_ADMIN',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_NOT_ACTIVE_OR_DELETED = 'USER_NOT_ACTIVE_OR_DELETED',
  USER_WITH_EMAIL_ALREADY_EXISTS = 'USER_WITH_EMAIL_ALREADY_EXISTS',
  USER_DOES_NOT_HAVE_ACCESS_TO_APP = 'USER_DOES_NOT_HAVE_ACCESS_TO_APP',
  NOT_SUPPORTED_CLINIC_OWNER_SUBSCRIPTION_TYPE = 'NOT_SUPPORTED_CLINIC_OWNER_SUBSCRIPTION_TYPE',
  CLINIC_OWNER_ALREADY_HAVE_ACTIVE_SUBSCRIPTION = 'CLINIC_OWNER_ALREADY_HAVE_ACTIVE_SUBSCRIPTION',
  PROMO_CODE_NOT_FOUND_OR_INACTIVE = 'PROMO_CODE_NOT_FOUND_OR_INACTIVE',
  PROMO_CODE_IS_NOT_VALID = 'PROMO_CODE_IS_NOT_VALID',
  CLINIC_OWNER_SUBSCRIPTION_STRIPE_PRODUCT_NOT_FOUND = 'CLINIC_OWNER_SUBSCRIPTION_STRIPE_PRODUCT_NOT_FOUND',
  CLINIC_OWNER_SUBSCRIPTION_STRIPE_PRICE_NOT_FOUND = 'CLINIC_OWNER_SUBSCRIPTION_STRIPE_PRICE_NOT_FOUND',
  PAYMENT_METHOD_REQUIRED = 'PAYMENT_METHOD_REQUIRED',
}

export const errorCodesList = [
  ERROR_CODE.APPOINTMENT_REQUEST_ALREADY_EXISTS,
  ERROR_CODE.APPOINTMENT_REQUEST_NOT_FOUND,
  ERROR_CODE.CLINIC_OWNER_SUBSCRIPTION_CHANGE_TYPE_NOT_ALLOWED,
  ERROR_CODE.CLINIC_OWNER_SUBSCRIPTION_CHANGE_NUMBER_OF_PROFESSIONAL_NOT_ALLOWED,
  ERROR_CODE.CLINIC_OWNER_SUBSCRIPTION_NOT_FOUND,
  ERROR_CODE.CLINIC_OWNER_SUBSCRIPTION_UPDATE_FAILED,
  ERROR_CODE.CLINIC_PROFESSIONAL_NOT_FOUND,
  ERROR_CODE.CLINIC_PROFESSIONAL_SUBSCRIPTION_TYPE_CHANGE_NOT_ALLOWED,
  ERROR_CODE.CLINIC_PROFESSIONAL_SUBSCRIPTION_INVALID_EMPLOYEES_LIMITS,
  ERROR_CODE.INCORRECT_USERNAME_OR_PASSWORD,
  ERROR_CODE.HEALTH_METRIC_NOT_FOUND,
  ERROR_CODE.HR_ADMIN_EXISTS_BY_PHONE,
  ERROR_CODE.HR_ADMIN_NOT_FOUND,
  ERROR_CODE.HR_COMPANY_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_ALREADY_EXISTS,
  ERROR_CODE.HR_DEPARTMENT_COMPANY_HAS_NO_STRIPE_CUSTOMER_ID,
  ERROR_CODE.HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS,
  ERROR_CODE.HR_DEPARTMENT_EMPLOYEE_HAVE_PATIENT_CONNECTED,
  ERROR_CODE.HR_DEPARTMENT_EMPLOYEE_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_EMPLOYEE_CREDIT_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED,
  ERROR_CODE.HR_DEPARTMENT_HR_NOT_FOUND,
  ERROR_CODE.HR_DEPARTMENT_NOT_FOUND,
  ERROR_CODE.HR_LOCATION_HAS_EMPLOYEES_CONNECTED,
  ERROR_CODE.HR_LOCATION_NAME_ALREADY_EXISTS,
  ERROR_CODE.HR_LOCATION_NOT_FOUND,
  ERROR_CODE.HR_USER_EXISTS_BY_PHONE,
  ERROR_CODE.MULTIPLE_CLINIC_OWNER_SUBSCRIPTIONS_NOT_ALLOWED,
  ERROR_CODE.PATIENT_NOT_FOUND,
  ERROR_CODE.STRIPE_INVALID_REQUEST_ERROR,
  ERROR_CODE.STRIPE_CARD_ERROR,
  ERROR_CODE.USER_DOES_NOT_HAVE_ACCESS_TO_ENTITY,
  ERROR_CODE.UNIT_OF_MEASUREMENT_ALREADY_EXISTS,
  ERROR_CODE.UNIT_OF_MEASUREMENT_ALREADY_IN_USE,
  ERROR_CODE.UNIT_OF_MEASUREMENT_NOT_FOUND,
  ERROR_CODE.USER_IS_NOT_ALLOWED_TO_DELETE_HIMSELF,
  ERROR_CODE.USER_IS_NOT_HR_ADMIN,
  ERROR_CODE.USER_NOT_FOUND,
  ERROR_CODE.USER_NOT_ACTIVE_OR_DELETED,
  ERROR_CODE.USER_WITH_EMAIL_ALREADY_EXISTS,
  ERROR_CODE.USER_DOES_NOT_HAVE_ACCESS_TO_APP,
  ERROR_CODE.NOT_SUPPORTED_CLINIC_OWNER_SUBSCRIPTION_TYPE,
  ERROR_CODE.CLINIC_OWNER_ALREADY_HAVE_ACTIVE_SUBSCRIPTION,
  ERROR_CODE.PROMO_CODE_NOT_FOUND_OR_INACTIVE,
  ERROR_CODE.PROMO_CODE_IS_NOT_VALID,
  ERROR_CODE.CLINIC_OWNER_SUBSCRIPTION_STRIPE_PRODUCT_NOT_FOUND,
  ERROR_CODE.CLINIC_OWNER_SUBSCRIPTION_STRIPE_PRICE_NOT_FOUND,
  ERROR_CODE.PAYMENT_METHOD_REQUIRED,
];
