import React, { FC, memo, useMemo, useState } from 'react';

import {
  EmailIcon,
  SearchIcon,
  CloseIcon,
  EyeOpenIcon,
  EyeClosedIcon,
  AttentionIcon,
  InfoIcon,
  LocationOnIcon,
} from 'application/assets';

import { InputProps } from './models';
import { InputWrapper, StyledInput, LabelWrapper } from './styled';

/**
 * A functional component that renders an input field with optional label, error message, hint, and icons.
 *
 * @param id - The unique identifier for the input field.
 * @param placeholder - The placeholder text to display inside the input field.
 * @param label - The label text to display above the input field.
 * @param disabled - A boolean value indicating whether the input field is disabled or not.
 * @param errorMessage - An error message to display below the input field if there is an issue with the input value.
 * @param hint - A hint or additional information to display below the input field.
 * @param type - The type of input field (e.g. text, email, password, etc.).
 * @param register - A function to register the input field with a form library like react-hook-form.
 * @param isFocused - A boolean value indicating whether the input field should be focused when it is rendered.
 * @param onChange - An event handler for the input field's change event.
 * @param onClear - An optional event handler that will be called when the user clicks the "clear" button next to the input field.
 * @param value - The current value of the input field, if not using form registration.
 * @param autoComplete - A string value indicating whether the browser should autocomplete the input field's value.
 * @param showRequiredIcon - A boolean value indicating whether a required icon should be displayed next to the label.
 * @param min - An optional minimum value for the input field (for numeric inputs).
 * @param step - An optional step value for the input field (for numeric inputs).
 * @param pattern - An optional regular expression pattern that the input value must match.
 * @param onKeyDown - An optional event handler for the input field's keydown event.
 * @param hideErrorMessage - A boolean value indicating whether the error message should be hidden even if there is an issue with the input value.
 */
const Input: FC<InputProps> = ({
  id,
  placeholder,
  label,
  disabled,
  errorMessage,
  hint,
  type,
  register,
  isFocused,
  onChange,
  onClear,
  value,
  autoComplete,
  showRequiredIcon,
  min,
  step,
  pattern,
  onKeyDown,
  hideErrorMessage = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType = useMemo(() => {
    const currentType = showPassword ? 'text' : type;
    return type === 'location' ? 'text' : currentType;
  }, [type, showPassword]);

  return (
    <StyledInput isError={!!errorMessage} disabled={disabled} type={type}>
      {label && (
        <LabelWrapper>
          <label htmlFor={id}>{label}</label>
          {showRequiredIcon && <InfoIcon />}
        </LabelWrapper>
      )}
      <InputWrapper disabled={disabled} type={type}>
        {type === 'email' && <EmailIcon />}
        {type === 'search' && <SearchIcon />}
        {type === 'location' && <LocationOnIcon />}
        {register ? (
          <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={inputType}
            {...register(id)}
            autoFocus={isFocused}
            autoComplete={autoComplete}
            min={min}
            step={step}
            pattern={pattern}
            onKeyDown={onKeyDown}
            onWheel={(e) => e.currentTarget.blur()}
          />
        ) : (
          <input
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            type={inputType}
            value={value || ''}
            onChange={onChange}
            autoFocus={isFocused}
            autoComplete={autoComplete}
            min={min}
            step={step}
            pattern={pattern}
            onKeyDown={onKeyDown}
            onWheel={(e) => e.currentTarget.blur()}
          />
        )}
        {type === 'email' && errorMessage && (
          <span>
            <AttentionIcon />
          </span>
        )}
        {type === 'search' && value && (
          <CloseIcon onClick={() => onClear && onClear()} />
        )}
        {type === 'password' && (
          <button
            type="button"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? (
              <EyeClosedIcon width={18} height={18} />
            ) : (
              <EyeOpenIcon width={18} height={18} />
            )}
          </button>
        )}
      </InputWrapper>
      {(hint || (errorMessage && !hideErrorMessage)) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </StyledInput>
  );
};

export default memo(Input);
