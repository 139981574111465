import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getResponseError } from 'application/helpers/responseHelper';

import { ResponseDto } from 'integration/api/models';

import { errorCodesList } from './constants';

/**
 * Handles the error by checking if the error code is included in the list of error codes.
 * @param action - The payload action containing an AxiosError with a ResponseDto object.
 * @returns A boolean indicating whether the error was handled or not.
 */
export const handleError = (action: PayloadAction<AxiosError<ResponseDto>>) => {
  // Retrieve the error code from the response
  const errorCode = getResponseError(action);

  // Check if the error code is included in the list of error codes
  const isErrorHandled = errorCodesList.some((code) => code === errorCode);

  // Return true if the error was not handled, false otherwise
  return !isErrorHandled;
};
