import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { HrDepartmentDashboardRequestDto } from '@docbay/schemas';

import { DashboardFiltersDepartmentsParams } from 'application/constants/dashboard';

import { DashboardAPI } from 'integration/api/dashboard';

import { RequestEnum } from '../request/model';

/**
 * Redux thunk to fetch dashboard employees
 *
 * @param params - the parameters to filter employees by
 */
const getDashboardEmployees = createAsyncThunk(
  RequestEnum.getDashboardEmployees,
  async (params: HrDepartmentDashboardRequestDto, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardEmployees(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk to fetch dashboard clinics filters
 *
 * @param search - the search query to filter clinics by
 */
const getDashboardFiltersClinics = createAsyncThunk(
  RequestEnum.getDashboardFiltersClinics,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersClinics(search);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk to fetch dashboard professionals filters
 *
 * @param search - the search query to filter professionals by
 */
const getDashboardFiltersProfessionals = createAsyncThunk(
  RequestEnum.getDashboardFiltersProfessionals,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersProfessionals(
        search,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk to fetch dashboard departments filters
 *
 * @param params - the parameters to filter departments by
 */
const getDashboardFiltersDepartments = createAsyncThunk(
  RequestEnum.getDashboardFiltersDepartments,
  async (params: DashboardFiltersDepartmentsParams, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersDepartments(
        params,
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk to fetch dashboard locations filters
 *
 * @param search - the search query to filter locations by
 */
const getDashboardFiltersLocations = createAsyncThunk(
  RequestEnum.getDashboardFiltersLocations,
  async (search: string, { rejectWithValue }) => {
    try {
      const { data } = await DashboardAPI.getDashboardFiltersLocations(search);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const DashboardActions = {
  getDashboardEmployees,
  getDashboardFiltersClinics,
  getDashboardFiltersProfessionals,
  getDashboardFiltersDepartments,
  getDashboardFiltersLocations,
};
