import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CreditCardIcon, MedicalServicesIcon } from 'application/assets';
import { PrimaryButton } from 'application/components';
import { PathNames } from 'application/routes/constants';

import { Wrapper, BenefitStyled } from './styles';

const BenefitPlansList: FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <BenefitStyled>
        <div>
          <CreditCardIcon />
          <h1>{t('benefit_plans.credits.title')}</h1>
          <p>{t('benefit_plans.credits.description')}</p>
        </div>
        <Link to={PathNames.employeesManagement}>
          <PrimaryButton type="button">
            {t('benefit_plans.credits.action')}
          </PrimaryButton>
        </Link>
      </BenefitStyled>
      <BenefitStyled>
        <div>
          <MedicalServicesIcon />
          <h1>{t('benefit_plans.subscription.title')}</h1>
          <p>{t('benefit_plans.subscription.description')}</p>
        </div>
        <Link to={PathNames.doctorSubscription}>
          <PrimaryButton type="button">
            {t('benefit_plans.subscription.action')}
          </PrimaryButton>
        </Link>
      </BenefitStyled>
    </Wrapper>
  );
};

export default BenefitPlansList;
