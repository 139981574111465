import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { AppDispatch, RootState } from 'application/store';

/**
 * @description - custom hook for dispatching actions
 * @returns {AppDispatch} - the dispatch function from react-redux
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * @description - custom hook for selecting data from state
 * @returns {TypedUseSelectorHook<RootState>} - a selector function with type safety
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
