import { ProfessionalActions } from 'application/store/reducers/Professional/actions';
import { useAppDispatch, useAppSelector } from './redux';

/**
 * A custom hook that returns the payment methods for a subscription.
 *
 * @returns An object containing two properties: `getPaymentMethods` and `paymentMethods`.
 */
export const useSubscriptionPaymentMethods = () => {
  /**
   * The dispatch function returned by useAppDispatch().
   */
  const dispatch = useAppDispatch();

  /**
   * The payment methods for a subscription, as selected from the Redux store.
   */
  const { paymentMethods } = useAppSelector((store) => store.professional);

  /**
   * A function that fetches the payment methods for a given Stripe account ID and updates
   * the Redux store with the new data.
   *
   * @param stripeAccountId - The ID of the Stripe account for which to fetch payment methods.
   */
  const getPaymentMethods = async (stripeAccountId: string) => {
    await dispatch(
      ProfessionalActions.fetchSubscriptionPaymentMethods(stripeAccountId),
    );
  };

  /**
   * An object containing two properties: `getPaymentMethods` and `paymentMethods`.
   *
   * @returns An object containing the `getPaymentMethods` function and the `paymentMethods` array.
   */
  return {
    getPaymentMethods,
    paymentMethods,
  };
};
