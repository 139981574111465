import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import {
  fetchAllLocations,
  getLocationByCoordinate,
  getLocations,
} from 'integration/api/mapBox';

import { RequestEnum } from '../request/model';

/**
 * Redux thunk for fetching locations based on address and country.
 * @param params - An object containing the address and country.
 * @returns The data fetched from the API or an error message if the request fails.
 */
const fetchLocations = createAsyncThunk(
  RequestEnum.fetchLocation,
  async (params: { address: string; country: string }, { rejectWithValue }) => {
    try {
      const { data } = await fetchAllLocations(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk for getting coordinates based on location ID.
 * @param id - The ID of the location.
 * @returns The coordinates or an error message if the request fails.
 */
const getCoordinates = createAsyncThunk(
  RequestEnum.getCoordinates,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await getLocations(id);
      return data.features[0].properties.coordinates;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk for getting region information based on coordinates.
 * @param params - An object containing the longitude and latitude.
 * @returns The region information or null if no data is found, or an error message if the request fails.
 */
const getRegionByCoordinates = createAsyncThunk(
  RequestEnum.getLocationByCoordinate,
  async (
    { longitude, latitude }: { longitude: number; latitude: number },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await getLocationByCoordinate(longitude, latitude);
      if (!data.features.length) return null;
      return {
        name: data.features[0].place_name,
        id: data.features[0].properties.mapbox_id,
      };
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const MapBoxActions = {
  fetchLocations,
  getCoordinates,
  getRegionByCoordinates,
};
