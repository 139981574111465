import { StorageFields } from 'application/constants/localStorage';
import { PathNames } from 'application/routes/constants';
import { store } from 'application/store';
import { clearStore } from 'application/store/reducers/Users/UsersSlice';

// Broadcast channel for logout events
const logoutChannel = new BroadcastChannel('logout');
// Broadcast channel for login events
const loginChannel = new BroadcastChannel('login');

export const logout = () => {
  // Post a message to the logout channel
  logoutChannel.postMessage('Logout');
  // Redirect the user to the login page
  window.location.href = window.location.origin + PathNames.login;
  // Clear the store
  store?.dispatch(clearStore());
};

export const login = () => {
  // Post a message to the login channel
  loginChannel.postMessage('Login');
  // Redirect the user to the home page
  window.location.href = window.location.origin + `${PathNames.home}`;
};

export const authService = {
  saveAccessToken(jwt: string) {
    // Save the access token in local storage
    localStorage.setItem(StorageFields.fbToken, jwt);
  },

  getAccessToken() {
    // Retrieve the access token from local storage
    return localStorage.getItem(StorageFields.fbToken);
  },

  checkAuth() {
    // Check if the user is authenticated by checking for an access token
    return !!localStorage.getItem(StorageFields.fbToken);
  },

  removeToken() {
    // Remove the access token from local storage
    localStorage.removeItem(StorageFields.fbToken);
  },

  eraseSessionData() {
    // Erase session data by removing the access token
    this.removeToken();
  },

  saveEmail(email: string) {
    // Save the user's email in local storage
    localStorage.setItem(StorageFields.email, email);
  },

  getEmail() {
    // Retrieve the user's email from local storage
    return localStorage.getItem(StorageFields.email);
  },

  saveCode(code: number) {
    // Save the email verification code in local storage
    localStorage.setItem(StorageFields.verificationCode, String(code));
  },

  getEmailVerificationCode() {
    // Retrieve the email verification code from local storage
    return localStorage.getItem(StorageFields.verificationCode);
  },

  saveTimer(timer: string) {
    // Save the timer value in local storage
    localStorage.setItem(StorageFields.timer, timer);
  },

  getTimer() {
    // Retrieve the timer value from local storage
    return localStorage.getItem(StorageFields.timer);
  },

  removeTimer() {
    // Remove the timer value from local storage
    localStorage.removeItem(StorageFields.timer);
  },

  cleanStorage() {
    // Clear both local and session storage
    localStorage.clear();
    sessionStorage.clear();
  },
};
