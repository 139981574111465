import React, { FC } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { CloseIcon, SuccessIcon } from 'application/assets';

import { ModalProps } from './models';
import { Wrapper } from './styles';
import { SecondaryButton } from '../index';
import { useTranslation } from 'react-i18next';

/**
 * Functional component representing a modal dialog.
 *
 * @param opened - A boolean indicating whether the modal is currently open or not.
 * @param onClose - A function to be called when the close button is clicked.
 * @param firstText - The text to display before any bold text, if applicable.
 * @param boldText - The text to display in bold, if applicable.
 * @param actionText - The text to display after the bold text, if applicable.
 */
const Modal: FC<ModalProps> = ({
  opened,
  onClose,
  firstText,
  boldText,
  actionText,
}) => {
  const { t } = useTranslation();

  return (
    <ModalMui open={opened}>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccessIcon />
          <p>
            {firstText} {boldText && <span>{boldText}</span>} {actionText}
          </p>
        </div>
        <SecondaryButton type={'button'} onClick={onClose}>
          {t('global.ok')}
        </SecondaryButton>
      </Wrapper>
    </ModalMui>
  );
};

export default Modal;
