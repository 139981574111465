import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEnum } from 'application/store/reducers/request/model';
import {
  HrDepartmentEmployeeCreationRequestDto,
  HrDepartmentEmployeeCreditsPurchaseConfirmDto,
  HrDepartmentEmployeeUpdateDto,
  PurchaseEmployeeCreditsDto,
} from '@docbay/schemas';
import { EmployeesManagementAPI } from 'integration/api/employeesManagement';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  departmentIds?: string[];
  search?: string;
}

/**
 * Redux thunk for creating an employee.
 *
 * @param params - The data to create an employee with.
 * @returns A promise that resolves with the created employee data.
 */
export const createEmployee = createAsyncThunk(
  RequestEnum.createEmployee,
  async (
    params: HrDepartmentEmployeeCreationRequestDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await EmployeesManagementAPI.createEmployee(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for fetching employees.
 *
 * @param params - The parameters to fetch employees with.
 * @returns A promise that resolves with the fetched employee data.
 */
export const fetchEmployees = createAsyncThunk(
  RequestEnum.fetchEmployees,
  async (params: Props, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.fetchEmployees(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for editing an employee.
 *
 * @param params - The data to edit the employee with, along with the employee's ID.
 * @returns A promise that resolves with the edited employee data.
 */
export const editEmployee = createAsyncThunk(
  RequestEnum.editEmployee,
  async (
    params: { data: HrDepartmentEmployeeUpdateDto; employeeId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await EmployeesManagementAPI.editEmployee(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for deleting an employee.
 *
 * @param employeeId - The ID of the employee to delete.
 * @returns A promise that resolves with the deleted employee data.
 */
export const deleteEmployee = createAsyncThunk(
  RequestEnum.deleteEmployee,
  async (employeeId: string, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.deleteEmployee(employeeId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for fetching an employee by ID.
 *
 * @param employeeId - The ID of the employee to fetch.
 * @returns A promise that resolves with the fetched employee data.
 */
export const fetchEmployeeById = createAsyncThunk(
  RequestEnum.fetchEmployeeById,
  async (employeeId: string, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.fetchEmployeeById(
        employeeId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for adding credits to an employee.
 *
 * @param params - The data to add credits with.
 * @returns A promise that resolves with the updated employee credits data.
 */
export const addCredits = createAsyncThunk(
  RequestEnum.addCredits,
  async (params: PurchaseEmployeeCreditsDto, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.addCredits(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for fetching the total credits of a company.
 *
 * @returns A promise that resolves with the fetched company total credits data.
 */
export const getCompanyTotalCredits = createAsyncThunk(
  RequestEnum.getCompanyTotalCredits,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await EmployeesManagementAPI.getCompanyTotalCredits();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for refunding credits from an employee.
 *
 * @param params - The data to refund credits with.
 * @returns A promise that resolves with the updated employee credits data.
 */
export const addCreditsConfirm = createAsyncThunk(
  RequestEnum.addCreditsConfirm,
  async (
    params: HrDepartmentEmployeeCreditsPurchaseConfirmDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await EmployeesManagementAPI.addCreditsConfirm(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
