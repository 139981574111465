import React, {
  FC,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  MouseEvent,
  useEffect,
} from 'react';
import {
  ExpandMoreIcon,
  CloseIcon,
  WarningTriangleIcon,
  ConfirmDeleteListIcon,
  CheckCircleIcon,
  LoaderIcon,
} from 'application/assets';
import useOnClickOutside from 'application/hooks/useOnClickOutside';
import Input from '../Input';
import { DropdownProps } from './models';
import {
  DropdownStyled,
  EmptyInput,
  OptionItem,
  OptionsList,
  OptionItemWrapper,
  DeleteSection,
  Message,
  ButtonWrapper,
  ErrorList,
  LoaderSection,
} from './styles';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { useDebounce } from 'application/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../index';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { resetDeletedLocation } from 'application/store/reducers/Locations/locationsSlice';
import { ErrorCodes } from 'application/constants/error';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { LocationsActions } from '../../store/reducers/Locations/actions';

/**
 * Functional component for a dropdown menu that allows users to select, create, and delete location.
 *
 * @param id - A unique identifier for the dropdown element.
 * @param label - The label for the dropdown element.
 * @param placeholder - The placeholder text for the input field in the dropdown.
 * @param searchPlaceholder - The placeholder text for the search input field in the dropdown.
 * @param value - The current value of the dropdown, representing the selected location's ID.
 * @param onChange - A function that is called when a new location is selected.
 * @param hint - An optional hint message to display below the dropdown.
 * @param disabled - A boolean indicating whether the dropdown is disabled.
 * @param errorMessage - An optional error message to display below the dropdown.
 * @param withSearch - A boolean indicating whether to include a search input field in the dropdown.
 * @param LeftIcon - An optional icon to display on the left side of the dropdown.
 */
const LocationDropdown: FC<DropdownProps> = ({
  id,
  label,
  placeholder,
  searchPlaceholder,
  value,
  onChange,
  hint,
  disabled,
  errorMessage,
  withSearch,
  LeftIcon,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const searchValue = useDebounce(searchItem, 500);
  const [locationForDelete, setLocationForDelete] = useState('');
  const [cantDelete, setCantDelete] = useState(false);
  const refSection = useRef(null);

  const { locations, deleted } = useAppSelector((state) => state.locations);

  const {
    addLocation: addLocationAction,
    deleteLocation: deleteLocationAction,
  } = useAppSelector(requestSelector);

  const isLoading = addLocationAction?.loading;
  const isDeleteLoading = deleteLocationAction?.loading;

  // Fetch locations from the server
  useEffect(() => {
    dispatch(LocationsActions.getLocations({}));
  }, []);

  // Handle clicks outside of the dropdown component
  useOnClickOutside(ref, () => setIsActive(false));

  // Memoize the option list based on search value and available locations
  const optionList = useMemo(() => {
    if (!searchItem) return locations;

    return locations?.filter((item) =>
      String(item?.name!)?.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, locations]);

  // Get the name of the currently selected location
  const getValue = () => {
    const currentOption = optionList.find(
      (item) => String(item.id) === String(value),
    );
    return currentOption?.name;
  };

  useEffect(() => {
    // Listener to scroll to a specific point in the dropdown when active
    const listener = () => {
      if (refSection?.current && isActive) {
        setTimeout(() => {
          const wrapper = document.querySelector('.children-wrapper');

          wrapper?.scrollTo({
            top: 1000,
            behavior: 'smooth',
          });
        }, 200);
      }
    };

    // Adding listener on click
    document.addEventListener('click', listener);

    // Removing listener on cleanup
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [refSection, isActive]);

  // Handle key presses in the search input field to add a new location
  const handleAddLocation = async (event: KeyboardEvent<HTMLElement>) => {
    // Check if the option list is empty, the Enter key was pressed, and the search item has content
    if (!optionList.length && event.code === 'Enter' && searchItem.length) {
      event.preventDefault();

      const response = await dispatch(
        LocationsActions.addLocation({ name: searchItem }),
      );

      isResponseFulfilled(response, () => {
        setSearchItem((event.target as HTMLInputElement).value);
        // Fetch location from the server again to update the list with the new location
        dispatch(LocationsActions.getLocations({}));
      });
    }
  };

  const handleRemoveLocation = (e: MouseEvent<SVGSVGElement>, id: string) => {
    e.stopPropagation();

    setLocationForDelete(id);
  };

  const onSubmitDelete = async (isForceDelete: boolean) => {
    const response = await dispatch(
      LocationsActions.deleteLocation({
        locationId: locationForDelete,
        isForceDelete,
      }),
    );

    const isEmployeeConnected =
      response.payload?.response?.data?.errorCode ===
      ErrorCodes.HR_LOCATION_HAS_EMPLOYEES_CONNECTED;

    isResponseFulfilled(
      response,
      () => {},
      () => {
        if (isEmployeeConnected) {
          setCantDelete(true);
        }
      },
    );
  };

  const handleCancelDelete = () => {
    setCantDelete(false);
    setLocationForDelete('');
    dispatch(resetDeletedLocation());
  };

  const handleCloseDeleteSection = () => {
    setCantDelete(false);
    setLocationForDelete('');
    dispatch(resetDeletedLocation());
    dispatch(LocationsActions.getLocations({}));
  };

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={disabled}
      ref={ref}
      isError={!!errorMessage}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <section
        ref={refSection}
        onMouseDown={() => !disabled && setIsActive(!isActive)}
      >
        {LeftIcon ? (
          <div>
            <LeftIcon />
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </div>
        ) : (
          <>
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </>
        )}
        <ExpandMoreIcon />
      </section>
      {isActive && (
        <OptionsList className="optionList">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={searchPlaceholder}
              onClear={() => setSearchItem('')}
              onKeyDown={handleAddLocation}
            />
          )}
          {!optionList.length ? (
            <>
              {isLoading || isDeleteLoading ? (
                <LoaderSection>
                  <LoaderIcon />
                </LoaderSection>
              ) : (
                <EmptyInput>
                  {t('location_dropdown.create_location_text')}
                </EmptyInput>
              )}
            </>
          ) : (
            optionList.map((item) => (
              <OptionItemWrapper
                forDelete={item.id === locationForDelete}
                key={item.name}
              >
                <OptionItem
                  onClick={() => {
                    onChange(item);
                    setIsActive(false);
                  }}
                  selected={value === String(item.id)}
                >
                  <span>{item.name}</span>

                  <CloseIcon
                    onClick={(e: MouseEvent<SVGSVGElement>) =>
                      handleRemoveLocation(e, item.id)
                    }
                  />
                </OptionItem>
                {deleted && item.id === locationForDelete && (
                  <DeleteSection>
                    <Message>
                      <CheckCircleIcon />
                      {t('location_dropdown.location_deleted')}
                    </Message>
                    <ButtonWrapper>
                      <SecondaryButton
                        type={'button'}
                        onClick={handleCloseDeleteSection}
                      >
                        {t('global.got_it')}
                      </SecondaryButton>
                    </ButtonWrapper>
                  </DeleteSection>
                )}

                {isDeleteLoading && item.id === locationForDelete ? (
                  <LoaderSection>
                    <LoaderIcon />
                  </LoaderSection>
                ) : (
                  !deleted &&
                  item.id === locationForDelete &&
                  (cantDelete ? (
                    <DeleteSection>
                      <Message>
                        {t('location_dropdown.cant_be_deleted_text')}
                      </Message>
                      <ErrorList>
                        <div>
                          <ConfirmDeleteListIcon />
                          {t('location_dropdown.cant_be_deleted_text_item_1')}
                        </div>
                        <div>
                          <ConfirmDeleteListIcon />
                          {t('location_dropdown.cant_be_deleted_text_item_2')}
                        </div>
                      </ErrorList>
                      <ButtonWrapper>
                        <SecondaryButton
                          type={'button'}
                          onClick={handleCancelDelete}
                        >
                          {t('global.cancel')}
                        </SecondaryButton>
                        <PrimaryButton
                          onClick={() => onSubmitDelete(true)}
                          styleType={'error'}
                          type={'button'}
                        >
                          {t('global.confirm')}
                        </PrimaryButton>
                      </ButtonWrapper>
                    </DeleteSection>
                  ) : (
                    <DeleteSection>
                      <Message>
                        <WarningTriangleIcon />
                        {t('location_dropdown.delete_location_text')}
                      </Message>
                      <ButtonWrapper>
                        <SecondaryButton
                          type={'button'}
                          onClick={() => setLocationForDelete('')}
                        >
                          {t('global.cancel')}
                        </SecondaryButton>
                        <PrimaryButton
                          onClick={() => onSubmitDelete(false)}
                          styleType={'error'}
                          type={'button'}
                        >
                          {t('global.confirm')}
                        </PrimaryButton>
                      </ButtonWrapper>
                    </DeleteSection>
                  ))
                )}
              </OptionItemWrapper>
            ))
          )}
        </OptionsList>
      )}

      {(hint || errorMessage) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </DropdownStyled>
  );
};

export default LocationDropdown;
