import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';

import { CountryActions } from 'application/store/reducers/Country/actions';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import {
  getCountryCode,
  setCountryCode,
} from 'application/helpers/sessionStorage/auth';

import Flag from '../Flag';

import {
  Country,
  CountryWrapper,
  MenuItemStyled,
  SelectStyled,
  Title,
} from './styles';

const defaultCountry = process.env.NEXT_PUBLIC_DOCBAY_DEFAULT_COUNTRY;

interface Props {
  showCountryName?: boolean;
  keyName: string;
}

/**
 * Functional component to switch between different countries.
 *
 * @param {Props} props - The properties for the component
 * @param {boolean} [props.showCountryName=false] - Whether or not to show the country name
 * @param {string} props.keyName - A unique key name for the component
 */
const CountrySwitcher: FC<Props> = ({ showCountryName, keyName }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { countries, currentUserCountry } = useAppSelector(
    (state) => state.countries,
  );

  const [currentCountry, setCurrentCountry] = useState('');
  const [countryPick, setLanguagePick] = useState('');

  // Function to set user country code
  const setUserCountryCode = (code: string) => {
    // Find the current country based on the provided code
    const currentCountryR = countries.find(
      (item) => item.code.toLowerCase() === code.toLowerCase(),
    );

    // If a match is found, update state variables and session storage
    if (currentCountryR) {
      setCurrentCountry(currentCountryR?.code || defaultCountry!);
      setLanguagePick(currentCountryR?.name || '');
      setCountryCode(currentCountryR?.code.toUpperCase() || defaultCountry!);
    }
  };

  // Effect hook to initialize country code on component mount
  useEffect(() => {
    const localeLang = navigator.language.slice(0, 2); // Get browser language
    const countryCode = getCountryCode(); // Get stored country code from session storage
    const chosenCountry = countryCode || localeLang || ''; // Use stored or browser language as default
    setUserCountryCode(chosenCountry);
  }, [countries]);

  // Effect hook to update country code when current user's country changes
  useEffect(() => {
    setUserCountryCode(currentUserCountry);
  }, [currentUserCountry]);

  // Effect hook to fetch supported countries from API if not already loaded
  useEffect(() => {
    !countries.length && dispatch(CountryActions.getSupportedCountries());
  }, [countries]);

  // Function to handle change in selected country
  const handleChange = async (event: SelectChangeEvent<unknown>) => {
    const countryCode = event.target.value as string;

    dispatch(
      CountryActions.setUserCountry({
        countryIso: countryCode.toUpperCase(),
      }),
    );
  };

  // Memoized component section for displaying current country flag and name
  const currentCountrySection = useMemo(() => {
    return (
      <Country>
        <Flag code={currentCountry!.toLowerCase()} />
        {showCountryName && countryPick}
      </Country>
    );
  }, [currentCountry, currentUserCountry, keyName, countryPick]);

  return (
    <CountryWrapper key={keyName}>
      {showCountryName && (
        <Title>
          <p>{t('footer.country')}</p>
        </Title>
      )}

      <SelectStyled
        value={currentCountry}
        onChange={handleChange}
        variant="standard"
        disableUnderline
        displayEmpty
        renderValue={() => currentCountrySection}
      >
        {countries?.map((item) => {
          return (
            <MenuItemStyled
              value={item.code}
              key={item.code}
              selected={currentCountry === item.name}
            >
              <Flag code={item.code.toLowerCase()} />
              {item.name}
            </MenuItemStyled>
          );
        })}
      </SelectStyled>
    </CountryWrapper>
  );
};

export default CountrySwitcher;
