import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpBar } from 'application/components';

import { clearCreditPaymentData } from 'application/helpers/sessionStorage/assignEmployees';

import {
  CreditCompanyInfo,
  SelectedEmployees,
  StripePayment,
} from './components';
import { Wrapper, Container, PaymentInfo } from './styles';
import { useAppDispatch } from 'application/hooks/redux';
import {
  setCreditPaymentSuccessfully,
  setCreditPaymentFailed,
  setEmployeeForCreditAction,
} from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'application/routes/constants';

const CreditPayment: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPaymentSuccess = () => {
    clearCreditPaymentData();
    dispatch(setCreditPaymentSuccessfully(true));
    navigate(PathNames.employeesManagement);
    dispatch(setEmployeeForCreditAction(null));
  };

  const onPaymentFailed = () => {
    clearCreditPaymentData();
    dispatch(setCreditPaymentFailed(true));
    navigate(PathNames.employeesManagement);
    dispatch(setEmployeeForCreditAction(null));
  };

  return (
    <Wrapper>
      <HelpBar text={t('global.back')} />
      <Container>
        <PaymentInfo>
          <CreditCompanyInfo />
          <SelectedEmployees />
          <StripePayment
            onPaymentSuccess={onPaymentSuccess}
            onPaymentFailed={onPaymentFailed}
          />
        </PaymentInfo>
      </Container>
    </Wrapper>
  );
};

export default CreditPayment;
