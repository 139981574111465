export enum ErrorCodes {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED = 'HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED',
  HR_USER_EXISTS_BY_PHONE = 'HR_USER_EXISTS_BY_PHONE',
  HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS = 'HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS',
  HR_LOCATION_HAS_EMPLOYEES_CONNECTED = 'HR_LOCATION_HAS_EMPLOYEES_CONNECTED',
}

export interface ErrorDto {
  errorCode: string;
  message: string;
  statusCode: number;
}
