import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentElement } from '@stripe/react-stripe-js';
import { Layout } from '@stripe/stripe-js';

import { AddIcon, CloseIcon } from 'application/assets';
import { ErrorModal, PrimaryButton } from 'application/components';

import { Loader } from 'common';

import { useStripeForSubscriptions } from 'features/subscription-payment/hooks/useStripeForSubscriptions';

import StripePaymentMethods from '../StripePaymentMethods';

import { StripePaymentFormProps } from './models';
import {
  Wrapper,
  AddPaymentButton,
  ButtonWrapper,
  FormStyled,
  PaymentSection,
  TitleStyled,
  TextSection,
} from './styles';

const StripePaymentForm: FC<StripePaymentFormProps> = ({
  price,
  clientSecret,
  paymentMethods,
  onPaymentSuccess,
  onPaymentFailed,
}) => {
  const { t } = useTranslation();

  const {
    stripe,
    retrieveStripePaymentIntent,
    confirmStripePayment,
    isLoading,
  } = useStripeForSubscriptions();

  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');

  useEffect(() => {
    if (!stripe) {
      return;
    }
    retrieveStripePaymentIntent(clientSecret);
  }, [stripe]);

  const handleChangePaymentMethod = (id: string) => {
    setPaymentMethodId(id);
  };

  const handleSubmit = async () => {
    const result = await confirmStripePayment(clientSecret, paymentMethodId);
    if (!result?.error) {
      onPaymentSuccess();
    } else {
      onPaymentFailed(result?.error?.code || 'error');
    }
  };

  const togglePaymentForm = () => {
    setShowPaymentForm((prev) => !prev);
    setPaymentMethodId('');
  };

  const paymentElementOptions = {
    layout: 'tabs' as Layout,
  };
  const submitButtonDisabled = !paymentMethodId && !showPaymentForm;
  const displayPaymentForm = !paymentMethodId && showPaymentForm;

  const resetPaymentError = () => {
    setPaymentError('');
  };

  const getCurrentError = () => {
    switch (paymentError) {
      case 'blocked':
        return t('errors.stripe_blocked');
      case 'card_declined':
      case 'incorrect_cvc':
      case 'processing_error':
      case 'incorrect_number':
        return t('errors.card_declined');
      case 'expired_card':
        return t('errors.expired_card');
      default:
        return t('errors.other');
    }
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      {!!paymentMethods?.length && (
        <StripePaymentMethods
          paymentMethods={paymentMethods}
          handleChangePaymentMethod={handleChangePaymentMethod}
          paymentId={paymentMethodId}
        />
      )}
      {!displayPaymentForm && (
        <AddPaymentButton type="button" onClick={() => togglePaymentForm()}>
          <AddIcon />
          {t('subscription_payment.add_payment_method')}
        </AddPaymentButton>
      )}
      {showPaymentForm && (
        <FormStyled id="payment-form">
          <PaymentSection>
            <TitleStyled>
              <h1>{t('subscription_payment.add_payment_method')}</h1>{' '}
              <button type="button" onClick={togglePaymentForm}>
                {t('global.close')}
                <CloseIcon />
              </button>
            </TitleStyled>

            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
          </PaymentSection>
        </FormStyled>
      )}
      <TextSection>
        <p>{t('subscription_payment.footer_text_1')}</p>
        <p>{t('subscription_payment.footer_text_2')}</p>
      </TextSection>
      <ButtonWrapper>
        <PrimaryButton
          type="button"
          onClick={handleSubmit}
          disabled={submitButtonDisabled}
        >
          {t('subscription_payment.confirm_payment')} €
          {Number(price)?.toFixed(2) || '0.00'}
        </PrimaryButton>
      </ButtonWrapper>
      <ErrorModal
        isOpen={!!paymentError}
        text={getCurrentError()}
        buttonText={t('global.got_it')}
        onClose={resetPaymentError}
      />
    </Wrapper>
  );
};

export default StripePaymentForm;
