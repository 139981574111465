/**
 * Compares two arrays and determines if there are any changes between them.
 *
 * @param firstArray - The first array to compare.
 * @param secondArray - The second array to compare.
 * @param checkOrder - A flag to determine whether the order of elements matters in the comparison.
 */
const compareArrays = (
  firstArray: any[],
  secondArray: any[],
  checkOrder: boolean,
) => {
  if (checkOrder) {
    const valueFirstObj = JSON.stringify(firstArray);
    const valueSecondObj = JSON.stringify(secondArray);
    // If the order of elements matters, we can simply compare the two arrays as strings
    if (valueFirstObj !== valueSecondObj) {
      return true;
    }
  }

  // Filter out any elements in the first array that are also present in the second array
  const filteredArray = firstArray.filter((item) => {
    const valueNotChanged = secondArray?.some(
      (data: any) => JSON.stringify(data) === JSON.stringify(item),
    );
    return valueNotChanged;
  });

  // If the length of the filtered array is not equal to the original first array,
  // then there must be some changes between the two arrays
  if (firstArray.length !== filteredArray.length) {
    return true;
  }

  return false;
};

/**
 * Compares two objects and determines if there are any changes between them.
 *
 * @param firstObj - The first object to compare.
 * @param secondObj - The second object to compare.
 * @param checkOrder - A flag to determine whether the order of elements matters in the comparison.
 */
const deepComparison: any = (
  firstObj: any,
  secondObj: any,
  checkOrder: boolean,
) => {
  // If either object is null or undefined, they are considered different
  if (!firstObj || !secondObj) return true;

  // If both objects are arrays, we can use the compareArrays function to determine if there are any changes
  if (Array.isArray(firstObj)) {
    return compareArrays(firstObj, secondObj, checkOrder);
  }

  // Otherwise, we need to recursively compare each property of the two objects
  return Object.keys(firstObj).some((property) => {
    // If a property is an object itself, we can use deepComparison to determine if there are any changes
    if (typeof firstObj[property] === 'object' && firstObj[property] !== null) {
      return deepComparison(
        firstObj[property],
        secondObj[property],
        checkOrder,
      );
    } else {
      // If a property is not an object, we can simply compare its value as a string
      if (firstObj && !secondObj) return true;
      const valueFirstObj = JSON.stringify(firstObj[property]);
      const valueSecondObj = JSON.stringify(secondObj[property]);

      // If the values of the two properties are different, then there must be some changes between the two objects
      if (valueFirstObj !== valueSecondObj) {
        return true;
      }
    }

    return false;
  });
};

/**
 * Compares two objects and determines if there are any changes between them.
 *
 * @param firstObj - The first object to compare.
 * @param secondObj - The second object to compare.
 * @param checkOrder - A flag to determine whether the order of elements matters in the comparison. (Default: false)
 */
const compareObjectsChanges = (
  firstObj: any,
  secondObj: any,
  checkOrder = false,
) => {
  // Use deepComparison to determine if there are any changes between the two objects
  const isChanged = deepComparison(firstObj, secondObj, checkOrder);
  return isChanged;
};

export default compareObjectsChanges;
