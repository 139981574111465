import { HrDepartmentLocationCreationRequestDto } from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';
import { GetParams, LocationUpdatePrams } from 'application/types/locations';

import { LocationsAPI } from 'integration/api/locations';

/**
 * Redux thunk to fetch locations.
 *
 * @param params - Object containing query parameters for fetching locations.
 * @param rejectWithValue - Function to reject the async thunk with a value.
 */
const getLocations = createAsyncThunk(
  RequestEnum.getLocations,
  async (params: GetParams, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.getLocations(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to fetch a location by ID.
 *
 * @param locationId - Unique identifier of the location.
 * @param rejectWithValue - Function to reject the async thunk with a value.
 */
const getLocationById = createAsyncThunk(
  RequestEnum.getLocationById,
  async (locationId: string, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.getLocationById(locationId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to add a new location.
 *
 * @param params - Object containing details of the new location.
 * @param rejectWithValue - Function to reject the async thunk with a value.
 */
const addLocation = createAsyncThunk(
  RequestEnum.addLocation,
  async (
    params: HrDepartmentLocationCreationRequestDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await LocationsAPI.addLocation(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to update an existing location.
 *
 * @param params - Object containing details of the updated location.
 * @param rejectWithValue - Function to reject the async thunk with a value.
 */
const updateLocation = createAsyncThunk(
  RequestEnum.updateLocation,
  async (params: LocationUpdatePrams, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.updateLocation(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to delete a location.
 *
 * @param locationId - Unique identifier of the location to be deleted.
 * @param rejectWithValue - Function to reject the async thunk with a value.
 */
const deleteLocation = createAsyncThunk(
  RequestEnum.deleteLocation,
  async (
    params: { locationId: string; isForceDelete: boolean },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await LocationsAPI.deleteLocation(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const LocationsActions = {
  getLocations,
  getLocationById,
  addLocation,
  updateLocation,
  deleteLocation,
};
