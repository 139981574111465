import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Wrapper, EmployeeItem, AdditionalButton, ClearButton } from './styles';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'application/assets';
import { setAssignToPackage } from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { ExpandMoreIcon, ExpandLessIcon } from 'application/assets';

const AssignedEmployees = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isShowShowMore, setIsShowShowMore] = useState(false);

  const { assignToPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const handlesShowShowMoreChange = () => {
    const clientHeight = ref?.current?.clientHeight || 0;

    if (clientHeight > 68) {
      setIsShowShowMore(true);
    } else {
      setIsShowShowMore(false);
    }
  };

  useEffect(() => {
    if (isCollapsed && isShowShowMore) return;

    if (ref.current) {
      handlesShowShowMoreChange();
    }
  }, [ref, isCollapsed]);

  useEffect(() => {
    if (isShowShowMore) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(false);
    }
  }, [isShowShowMore]);

  useEffect(() => {
    if (ref.current) {
      setIsCollapsed(false);

      handlesShowShowMoreChange();
    }
  }, [assignToPackage, ref]);

  const assignedEmployees = useMemo(() => {
    return assignToPackage.length === 1
      ? t('global.employee')
      : t('global.employees');
  }, [t, assignToPackage]);

  const handleRemove = (id: string) => {
    const filteredEmployees = assignToPackage.filter((item) => item.id !== id);

    dispatch(setAssignToPackage(filteredEmployees));
  };

  const handleClearEmployees = () => {
    dispatch(setAssignToPackage([]));
  };

  if (!assignToPackage.length) {
    return <></>;
  }

  return (
    <Wrapper ref={ref} isCollapsed={isCollapsed}>
      <div>{`${assignToPackage.length} ${assignedEmployees}:`}</div>
      <>
        {assignToPackage.map((item) => (
          <EmployeeItem key={item.id}>
            {item.name}
            <CloseIcon onClick={() => handleRemove(item.id)} />
          </EmployeeItem>
        ))}
      </>
      <ClearButton onClick={handleClearEmployees}>
        {t('global.clear_all')}
      </ClearButton>
      {isShowShowMore && isCollapsed && (
        <AdditionalButton onClick={() => setIsCollapsed(false)}>
          {t('global.see_more')} <ExpandMoreIcon />
        </AdditionalButton>
      )}
      {isShowShowMore && !isCollapsed && (
        <ClearButton onClick={() => setIsCollapsed(true)}>
          {t('global.see_less')} <ExpandLessIcon />
        </ClearButton>
      )}
    </Wrapper>
  );
};

export default AssignedEmployees;
