// Get language from localStorage
export const getLanguageFromLocalStorage = () => {
  // Retrieve 'i18nextLng' item from localStorage
  const language = localStorage.getItem('i18nextLng');

  // Check if language is English or starts with 'en-'
  if (language === 'en' || language?.startsWith('en-')) {
    return 'en';
  }

  // Check if language is Portuguese or starts with 'pt-'
  if (language === 'pt' || language?.startsWith('pt-')) {
    return 'pt';
  }

  // Check if language is French or starts with 'fr-'
  if (language === 'fr' || language?.startsWith('fr-')) {
    return 'fr';
  }

  // Return default language as Portuguese
  return 'pt';
};
