import { ProfessionalCompanySubscriptionPaymentInitializationDto } from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { ProfessionalAPI } from 'integration/api/professional';

/**
 * Redux thunk to initialize subscription payment
 * @param params - Payload containing subscription information
 * @param rejectWithValue - Function used to return rejected value with error information
 */
const subscriptionPayment = createAsyncThunk(
  RequestEnum.subscriptionPayment,
  async (
    params: ProfessionalCompanySubscriptionPaymentInitializationDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await ProfessionalAPI.subscriptionPayment(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to fetch professional subscription by ID
 * @param subscriptionId - Payload containing the subscription ID
 * @param rejectWithValue - Function used to return rejected value with error information
 */
const fetchProfessionalSubscriptionById = createAsyncThunk(
  RequestEnum.fetchProfessionalSubscriptionById,
  async (subscriptionId: string, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalAPI.fetchProfessionalSubscriptionById(
        subscriptionId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to fetch subscription payment methods
 * @param stripeAccountId - Payload containing the Stripe account ID
 * @param rejectWithValue - Function used to return rejected value with error information
 */
export const fetchSubscriptionPaymentMethods = createAsyncThunk(
  RequestEnum.fetchSubscriptionPaymentMethods,
  async (stripeAccountId: string, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await ProfessionalAPI.getPaymentMethodsForStripeAccount(
        stripeAccountId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to fetch company payment methods
 * @param _ - No payload required for this request
 * @param rejectWithValue - Function used to return rejected value with error information
 */
export const fetchCompanyPaymentMethods = createAsyncThunk(
  RequestEnum.fetchCompanyPaymentMethods,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ProfessionalAPI.getCompanyPaymentMethods();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const ProfessionalActions = {
  subscriptionPayment,
  fetchProfessionalSubscriptionById,
  fetchSubscriptionPaymentMethods,
  fetchCompanyPaymentMethods,
};
