import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { emailValidation } from 'application/constants/email';

/**
 * A custom hook that returns a Yup object schema for validating login form inputs.
 *
 * @returns A Yup object schema with validation rules for the `email` and `password` fields.
 */
export const useLoginSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const emailError = t('errors.email_must_be_email') || '';

  /**
   * Returns a Yup object schema for validating login form inputs.
   *
   * @returns A Yup object schema with validation rules for the `email` and `password` fields.
   */
  return yup.object({
    email: yup
      .string()
      .matches(emailValidation, emailError)
      .required(requiredError),
    password: yup.string().required(requiredError),
  });
};
