import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  min-width: 664px;
  max-width: 664px;
  max-height: 90vh;
  overflow-y: auto;
  flex-direction: column;
  border-radius: 8px;
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${commonColors.grey500};
    }
  }

  > div {
    padding: 32px;
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${commonColors.grey200};

  h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: ${commonColors.grey700};
      }
    }
  }
`;

export const ChildrenStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${commonColors.grey200};
  height: calc(100% - 205px);
  overflow-y: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  button {
    width: 150px;
  }
`;
