import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Languages } from 'application/constants/languages';
import { getLanguageFromLocalStorage } from 'application/helpers/language';

/**
 * Custom hook for handling language changes and retrieval.
 *
 * @returns An object containing the current language and a function to change it.
 */
export const useLanguage = () => {
  const { i18n } = useTranslation();

  /**
   * The currently selected language, retrieved from local storage or defaulting to Portuguese.
   */
  const currentLanguage = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    return (language.toUpperCase() || Languages.PT) as Languages;
  }, [i18n.language]);

  /**
   * A function that changes the current language and updates i18n.
   *
   * @param language - The new language to set.
   */
  const changeLanguage = async (language: Languages) => {
    i18n.changeLanguage(language.toLowerCase());
  };

  return { currentLanguage, changeLanguage };
};
