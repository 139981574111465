import React, { FC } from 'react';
import { Modal } from '@mui/material';

import { CloseIcon, UnsuccessIcon } from 'application/assets';

import SecondaryButton from '../SecondaryButton';

import { ErrorModalProps } from './modules';
import { StyledModal } from './styles';

/**
 * Functional component that displays an error modal with a close button and optional description.
 *
 * @param isOpen - A boolean value indicating whether the modal is open or not.
 * @param onClose - A function that will be called when the user clicks the close button.
 * @param text - The main text to display in the modal.
 * @param description - An optional string containing additional details about the error.
 * @param buttonText - The text to display on the secondary button, which also closes the modal.
 */
const ErrorModal: FC<ErrorModalProps> = ({
  isOpen,
  onClose,
  text,
  description,
  buttonText,
}) => {
  return (
    <Modal open={isOpen}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <UnsuccessIcon />
          <p>{text}</p>
          {description && <div>{description}</div>}
          <SecondaryButton type="button" onClick={onClose}>
            {buttonText}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default ErrorModal;
