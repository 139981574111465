import { StorageFields } from 'application/constants';

export const geolocationService = {
  /**
   * Saves the given latitude and longitude to local storage.
   *
   * @param latitude - The latitude value to save.
   * @param longitude - The longitude value to save.
   */
  saveGeolocation(latitude: number, longitude: number) {
    localStorage.setItem(StorageFields.latitude, JSON.stringify(latitude));
    localStorage.setItem(StorageFields.longitude, JSON.stringify(longitude));
  },

  /**
   * Retrieves the latitude and longitude from local storage.
   *
   * @returns An object containing the latitude and longitude values,
   *          or an object with empty strings if the values are not found.
   */
  getGeolocation() {
    const latitudeStorage = localStorage.getItem(StorageFields.latitude);
    const longitudeStorage = localStorage.getItem(StorageFields.longitude);

    if (!latitudeStorage || !longitudeStorage) {
      return { latitude: '', longitude: '' };
    }

    const latitude = JSON.parse(latitudeStorage || '');
    const longitude = JSON.parse(longitudeStorage || '');
    return { latitude, longitude };
  },
};
