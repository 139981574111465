import React, { FC } from 'react';

import { CheckboxProps } from './models';
import { StyledCheckbox } from './styles';

/**
 * Functional component that renders a checkbox input.
 * @param label - The label text for the checkbox.
 * @param id - The unique identifier for the checkbox.
 * @param checked - The state of the checkbox (checked or not).
 * @param onChange - A callback function to handle changes to the checkbox's state.
 * @param disabled - Whether the checkbox should be disabled or not.
 */
const Checkbox: FC<CheckboxProps> = ({
  label,
  id,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <StyledCheckbox>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={!!disabled}
      />
      <label htmlFor={id}>{label}</label>
    </StyledCheckbox>
  );
};

export default Checkbox;
