import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HelpBar } from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { ProfessionalActions } from 'application/store/reducers/Professional/actions';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { PathNames } from 'application/routes/constants';
import { clearPaymentData } from 'application/helpers/sessionStorage/assignEmployees';
import {
  setAssignToPackage,
  setChosenPackage,
} from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';

import { Loader } from 'common';

import {
  SubscriptionInfo,
  SelectedEmployees,
  StripePayment,
  PaymentSuccessScreen,
} from './components';
import { Wrapper, Container, PaymentInfo } from './styles';

const SubscriptionPayment: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { assignToPackage, chosenPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );
  const {
    fetchProfessionalSubscriptionById,
    subscriptionPayment,
    fetchSubscriptionPaymentMethods,
  } = useAppSelector(requestSelector);

  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const isLoading =
    fetchProfessionalSubscriptionById.loading ||
    subscriptionPayment.loading ||
    fetchSubscriptionPaymentMethods.loading;

  const getPaymentData = async () => {
    const employeeIds = assignToPackage.map((item) => item.id);
    dispatch(
      ProfessionalActions.subscriptionPayment({
        professionalSubscriptionId: String(chosenPackage?.id),
        employeeIds,
      }),
    );
    dispatch(
      ProfessionalActions.fetchProfessionalSubscriptionById(
        String(chosenPackage?.id),
      ),
    );
  };

  useEffect(() => {
    if (assignToPackage.length && chosenPackage) {
      getPaymentData();
    }
  }, [assignToPackage, chosenPackage]);

  const onPaymentSuccess = () => {
    clearPaymentData();
    dispatch(setAssignToPackage([]));
    dispatch(setChosenPackage(null));
    setShowSuccessScreen(true);
  };

  const goToSubscriptions = () => {
    navigate(PathNames.doctorSubscription);
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <HelpBar text={t('global.back_to_search')} />
      <Container>
        {showSuccessScreen ? (
          <PaymentSuccessScreen onSubmit={goToSubscriptions} />
        ) : (
          <PaymentInfo>
            <SubscriptionInfo />
            <SelectedEmployees />
            <StripePayment onPaymentSuccess={onPaymentSuccess} />
          </PaymentInfo>
        )}
      </Container>
    </Wrapper>
  );
};

export default SubscriptionPayment;
