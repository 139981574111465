import {
  UserLoginResDto,
  UserLoginDto,
  UserRefreshTokenDto,
  UserSetPasswordDto,
  UserRefreshTokenResDto,
  UserVerifyCodeDto,
  UserChangePhoneDto,
  UserRequestChangePhoneDto,
  UserChangeEmailDto,
  HrDepartmentAdminResponse,
  HrDepartmentHrResponse,
  UserChangePasswordDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';

import { ForgotPasswordDto } from 'common/auth/signIn/models';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
import axiosNonTokenInstance from './axiosNonTokenInstance';

/**
 * UsersAPI provides methods for interacting with the users API.
 */
export const UsersAPI = {
  /**
   * Logs in a user.
   * @param data - The login data, including the username and password.
   * @returns A promise that resolves to the login response DTO.
   */
  usersLogin: (data: UserLoginDto) =>
    axiosNonTokenInstance.post<UserLoginResDto>('/users/login', data),

  /**
   * Refreshes a user's access token using their refresh token.
   * @param data - The refresh token data, including the refresh token.
   * @returns A promise that resolves to the refresh token response DTO.
   */
  usersRefreshToken: (data: UserRefreshTokenDto) =>
    axiosNonTokenInstance.post<UserRefreshTokenResDto>(
      '/users/refresh-token',
      data,
    ),

  /**
   * Initiates a password reset for a user.
   * @param data - The forgot password data, including the email and captcha token.
   * @returns A promise that resolves to the response from the server.
   */
  usersForgotPassword: (data: ForgotPasswordDto) => {
    const { captchaToken, ...restData } = data;
    return axiosNonTokenInstance.post('/users/forgot-password', restData, {
      headers: {
        recaptcha: captchaToken,
      },
    });
  },

  /**
   * Verifies a code for a user.
   * @param data - The verify code data, including the code and other relevant information.
   * @returns A promise that resolves to the response from the server.
   */
  usersVerifyCode: (data: UserVerifyCodeDto) => {
    const language = getLanguageFromLocalStorage();
    return axiosNonTokenInstance.post('/users/verify-code', {
      ...data,
      language,
    });
  },

  /**
   * Sets a new password for a user.
   * @param data - The set password data, including the new password and other relevant information.
   * @returns A promise that resolves to the response from the server.
   */
  usersCreateNewPassword: (data: UserSetPasswordDto) =>
    axiosNonTokenInstance.post('/users/password', data),

  /**
   * Signs out a user.
   * @returns A promise that resolves to the response from the server.
   */
  signOut: () => axiosInstance.post('/users/logout'),

  /**
   * Sets the language for a user's profile.
   * @param languageIso - The ISO code for the language to set.
   * @returns A promise that resolves to the response from the server.
   */
  setUserLanguage: (languageIso: string) =>
    axiosInstance.post('/users/change-language', { languageIso }),

  /**
   * Fetches a user's profile information.
   * @returns A promise that resolves to the user's profile data.
   */
  fetchUserProfile: () => {
    return axiosInstance.get('/hr-department-user/profile');
  },

  /**
   * Verifies an old email for a user.
   * @param params - The parameters required, including the email and language.
   * @returns A promise that resolves to the response from the server.
   */
  userVerifyOldEmail: (params: { email: string; language: string }) => {
    return axiosInstance.post('/users/email/verification-code/request', params);
  },

  /**
   * Sets a new email for a user.
   * @param params - The parameters required, including the new email and other relevant information.
   * @returns A promise that resolves to the response from the server.
   */
  userSetNewEmail: (params: UserChangeEmailDto) => {
    return axiosInstance.patch('/users/email', params);
  },

  /**
   * Requests a phone verification code for a user.
   * @param data - The parameters required, including the phone number and other relevant information.
   * @returns A promise that resolves to the response from the server.
   */
  userPhoneVerificationCode: (data: UserRequestChangePhoneDto) => {
    return axiosInstance.post('/users/phone/verification-code/request', data);
  },

  /**
   * Sets a new phone number for a user.
   * @param params - The parameters required, including the new phone number and other relevant information.
   * @returns A promise that resolves to the response from the server.
   */
  userSetNewPhone: (params: UserChangePhoneDto) => {
    return axiosInstance.patch('/users/phone', params);
  },

  /**
   * Fetches an admin profile by ID.
   * @param id - The ID of the admin to fetch.
   * @returns A promise that resolves to the admin's profile data.
   */
  getAdminProfileById: (id: string) => {
    return axiosInstance.get<HrDepartmentAdminResponse>(
      `/hr-department/admins/${id}`,
    );
  },

  /**
   * Fetches an HR profile by ID.
   * @param id - The ID of the HR to fetch.
   * @returns A promise that resolves to the HR's profile data.
   */
  getHrProfileById: (id: string) => {
    return axiosInstance.get<HrDepartmentHrResponse>(
      `/hr-department/hrs/${id}`,
    );
  },

  /**
   * Changes a user's password.
   * @param data - The parameters required, including the new password and other relevant information.
   * @returns A promise that resolves to the response from the server.
   */
  userChangePassword: (data: UserChangePasswordDto) => {
    return axiosInstance.post('/users/change-password', data);
  },
};
