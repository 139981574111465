import React, { FC } from 'react';
import { Wrapper } from './styles';

interface Props {
  children: React.ReactNode;
}
/**
 * Functional component that wraps its children with a styled wrapper.
 *
 * @param props - The properties for the PageSwitcher component.
 * @returns A JSX element that contains the wrapped children.
 */
const PageSwitcher: FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default PageSwitcher;
