import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { ExpandMoreIcon, HomeIcon } from 'application/assets';

import Checkbox from '../Checkbox';

import { DropdownClinicsProps, Option } from './models';
import {
  ClinicImage,
  DropdownStyled,
  OptionCheckBox,
  OptionsList,
  Wrapper,
} from './styles';

/**
 * Functional component that renders a dropdown menu with options for selecting clinics.
 *
 * @param value - The current selected value(s) of the dropdown. Can be either a string or an array of strings, depending on whether multi-selection is enabled.
 * @param label - A label to display above the dropdown.
 * @param placeholder - Placeholder text to display when no option is selected.
 * @param onChange - Callback function that gets called whenever the user selects an option.
 * @param disabled - If true, the dropdown will be disabled and not interactive.
 * @param isMulti - If true, multiple options can be selected at once. Otherwise, only a single option can be selected at a time.
 * @param isError - If true, the dropdown will display an error message below it.
 * @param errorMessage - The error message to display if there is an error with the dropdown.
 * @param options - An array of available options for the dropdown.
 */
const DropdownClinics: FC<DropdownClinicsProps> = ({
  value,
  label,
  placeholder,
  onChange,
  disabled,
  isMulti,
  isError,
  errorMessage,
  options,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);

  const [isActive, setIsActive] = useState(false);

  // This function is called whenever the user clicks outside of the dropdown
  useOnClickOutside(ref, () => setIsActive(false));

  // This useMemo hook creates a memoized version of optionList, which is an array of objects containing information about each clinic
  const optionList = useMemo(() => {
    const filteredClinics = options.map((clinic) => ({
      label: clinic.name!,
      value: clinic.id!,
      image: clinic.mainPhoto?.thumbnailUrl,
      address: clinic.address,
    }));
    return filteredClinics;
  }, [options]);

  // This function is called whenever the user selects an option from the dropdown
  const handleChange = (selectedItem: string) => {
    // If multi-selection is not enabled, simply update the selected value and close the dropdown
    if (!isMulti) {
      onChange(selectedItem);
      setIsActive(false);
    }

    // Otherwise, check whether the selected item is already in the list of selected values
    if (Array.isArray(value) && isMulti) {
      const valueAlreadyAdded = value.some((item) => item === selectedItem);
      // If it is, remove it from the list
      if (valueAlreadyAdded) {
        const filteredValue = value.filter((item) => {
          return item !== selectedItem;
        });

        onChange(filteredValue);
      } else {
        // Otherwise, add it to the list
        onChange([...value, selectedItem]);
      }
    }
    return;
  };

  // This function returns a boolean indicating whether a given option is currently selected in the dropdown
  const IsValueChecked = (item: Option) => {
    if (!isMulti) return String(value) === String(item.value);
    //checking if checkbox checked
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i === item.value);
  };

  // This function returns the current selected value(s) of the dropdown as a string, or the placeholder text if no option has been selected
  const getValue = () => {
    if (isMulti) return `${value.length} ${t('global.selected')}`;
    const currentOption = optionList.find(
      (item) => String(item.value) === String(value),
    );
    return currentOption?.label;
  };

  return (
    <Wrapper isError={isError}>
      {label && <label>{label}</label>}
      <DropdownStyled
        isError={isError}
        isActive={isActive}
        disabled={disabled}
        ref={ref}
      >
        <section onMouseDown={() => !disabled && setIsActive((prev) => !prev)}>
          {value?.length ? (
            <h2>{getValue()}</h2>
          ) : (
            <span>{placeholder || t('all_clinics')}</span>
          )}
          <ExpandMoreIcon />
        </section>
        {isActive && (
          <OptionsList className="clinics-list">
            {isActive &&
              optionList.map((item: Option) => (
                <OptionCheckBox
                  htmlFor={String(item.value)}
                  key={item.value}
                  selected={IsValueChecked(item!)}
                  isMulti={isMulti}
                  isHidden={item.isHidden}
                >
                  <div>
                    <ClinicImage>
                      {item.image ? (
                        <img src={item.image} alt={item.label} />
                      ) : (
                        <HomeIcon />
                      )}
                    </ClinicImage>
                    <p>{item.label}</p>
                  </div>
                  <Checkbox
                    id={String(item.value)}
                    checked={IsValueChecked(item)}
                    onChange={() => handleChange(String(item.value))}
                  />
                </OptionCheckBox>
              ))}
          </OptionsList>
        )}
      </DropdownStyled>
      {errorMessage && <span>{errorMessage}</span>}
    </Wrapper>
  );
};

export default DropdownClinics;
