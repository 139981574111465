import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const StyledTr = styled.tr`
  height: 72px;
  background: ${commonColors.white};

  &:hover {
    background: ${commonColors.grey50};
  }

  > td {
    vertical-align: middle;
    text-align: start;
    padding: 12px 24px;
    color: ${commonColors.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &:last-child {
      width: 150px;
    }

    &.location-name {
      max-width: 300px;
      min-width: 300px;
      width: 300px;

      p {
        word-break: break-all;
      }
    }
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    padding: 10px;

    &:first-of-type {
      > svg {
        width: 24px;
        height: 24px;
        path {
          fill: ${commonColors.blue700};
        }
      }
    }

    &:last-of-type {
      > svg {
        width: 24px;
        height: 24px;
        path {
          fill: ${commonColors.grey600};
        }
      }
    }
    &:disabled {
      cursor: not-allowed;
      > svg {
        g {
          path {
            fill: ${commonColors.grey400};
          }
        }
      }
    }
  }
`;
