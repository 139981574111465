import { Specialization } from '@docbay/schemas';
import axiosInstance from 'integration/api/axiosInstance';

/**
 * API methods related to specializations
 */
export const SpecializationsAPI = {
  /**
   * Fetches a list of all available specializations
   * @returns a promise that resolves with an array of Specialization objects
   */
  fetchSpecializations: () =>
    axiosInstance.get<Specialization[]>('/specializations'),
};
