import { useTranslation } from 'react-i18next';

import { useGeolocation } from './useGeolocation';

interface Props {
  latitude?: string;
  longitude?: string;
  distanceInMeters?: number;
}

/**
 * Custom hook that calculates the distance to a clinic using the provided
 * latitude and longitude values. Returns the distance in kilometers away.
 * If a specific distance in meters is provided, it will use that value instead.
 *
 * @param {Props} props - Object containing the latitude,
 * longitude, and optional distance in meters.
 * @returns {string} - Distance to clinic in kilometers away, or an empty string
 * if no distance information is available.
 */
export const useClinicDistance = ({
  latitude,
  longitude,
  distanceInMeters,
}: Props) => {
  // React hook to get the distance calculation function from useGeolocation hook
  const { getDistance } = useGeolocation();

  const { t } = useTranslation();

  // Get the distance in meters using the provided latitude and longitude values
  const amount = getDistance({ latitude, longitude });

  // If a specific distance in meters is provided, return it formatted as kilometers away
  if (distanceInMeters) {
    return `${Math.round(distanceInMeters / 1000)} ${t('global.km')} ${t(
      'global.away',
    )}`;
  }

  // Otherwise, if a calculated distance amount is available, return it formatted as kilometers away
  if (amount !== null) {
    return `${Math.round(amount)} ${t('global.km')} ${t('global.away')}`;
  }

  // If no distance information is available, return an empty string
  return '';
};
