import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { SpecializationsAPI } from 'integration/api/specializations';
import { RequestEnum } from '../request/model';

/**
 * Redux thunk to fetch specializations.
 *
 * @param args - The arguments passed to the thunk. In this case, it's an empty object.
 * @param thunkApi - An object containing various utilities for managing the side effects of the thunk.
 * @returns A promise that resolves with the data returned by the SpecializationsAPI.fetchSpecializations() call,
 *          or rejects with the error message if the API call fails.
 */
const fetchSpecializations = createAsyncThunk(
  RequestEnum.fetchSpecializations,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await SpecializationsAPI.fetchSpecializations();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const SpecializationsActions = { fetchSpecializations };
