/**
 * Function that validates a password based on certain criteria.
 *
 * @param value - The password to be validated.
 * @returns An object containing boolean values representing whether the password meets each validation criterion.
 */
export const createPasswordValidation = (value: string) => {
  const validLength = /.{10,}/.test(value);
  const includeUppercase = /[A-Z]/.test(value);
  const includeLowercase = /[a-z]/.test(value);

  return { validLength, includeUppercase, includeLowercase };
};
