import React, { FC } from 'react';

import { DividerProps } from '@mui/material';
import { DividerStyled } from './styles';

/**
 * A functional component that represents a styled divider.
 *
 * @param color - The color of the divider. This prop is passed down to the styled
 *                divider component and overrides any default colors.
 */
const Divider: FC<DividerProps> = ({ color }) => {
  return <DividerStyled color={color} />;
};

export default Divider;
