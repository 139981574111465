import {
  HrDepartmentDashboardRequestDto,
  HrDepartmentDashboardListResponseDto,
  ClinicBriefResponseDto,
  ProfessionalBriefResponseDto,
  HrDepartmentResponseDto,
  HrDepartmentLocationResponseDto,
  CommonEntitiesListResponse,
} from '@docbay/schemas';

import { DashboardFiltersDepartmentsParams } from 'application/constants/dashboard';

import axiosInstance from './axiosInstance';

/**
 * API methods for the dashboard
 */
export const DashboardAPI = {
  /**
   * Get the list of employees in a specific department
   * @param data - The request data containing information about the department and any filters to apply
   * @returns A promise that resolves with the response data containing the list of employees
   */
  getDashboardEmployees: (data: HrDepartmentDashboardRequestDto) => {
    return axiosInstance.post<HrDepartmentDashboardListResponseDto>(
      '/hr-departments/dashboard/employees',
      data,
    );
  },

  /**
   * Get a list of clinics that can be used as filters in the dashboard
   * @param search - Optional search term to filter the results
   * @returns A promise that resolves with the response data containing the list of clinics
   */
  getDashboardFiltersClinics: (search?: string) => {
    return axiosInstance.get<ClinicBriefResponseDto[]>(
      '/hr-departments/dashboard/filters/clinics',
      {
        params: {
          ...(search?.length ? { search } : {}),
        },
      },
    );
  },

  /**
   * Get a list of professionals that can be used as filters in the dashboard
   * @param search - Optional search term to filter the results
   * @returns A promise that resolves with the response data containing the list of professionals
   */
  getDashboardFiltersProfessionals: (search?: string) => {
    return axiosInstance.get<ProfessionalBriefResponseDto[]>(
      '/hr-departments/dashboard/filters/professionals',
      {
        params: {
          ...(search?.length ? { search } : {}),
        },
      },
    );
  },

  /**
   * Get a list of departments that can be used as filters in the dashboard
   * @param params - The request parameters containing information about any filters to apply
   * @returns A promise that resolves with the response data containing the list of departments
   */
  getDashboardFiltersDepartments: (
    params: DashboardFiltersDepartmentsParams,
  ) => {
    const { search, ...restParams } = params;
    return axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentResponseDto>
    >('/hr-departments/dashboard/filters/departments', {
      params: {
        ...restParams,
        ...(search?.length ? { search } : {}),
      },
    });
  },

  /**
   * Get a list of locations that can be used as filters in the dashboard
   * @param search - Optional search term to filter the results
   * @returns A promise that resolves with the response data containing the list of locations
   */
  getDashboardFiltersLocations: (search?: string) => {
    return axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentLocationResponseDto>
    >('/hr-departments/dashboard/filters/locations', {
      params: {
        ...(search?.length ? { search } : {}),
      },
    });
  },
};
