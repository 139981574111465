import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'application/assets/close.svg';
import { ReactComponent as SuccessIcon } from 'application/assets/success-icon.svg';
import { StyledModal } from './styles';
import { Modal } from '@mui/material';
import { SuccessModalProps } from './modules';
import SecondaryButton from '../SecondaryButton';

/**
 * A modal component that displays a success message after the user has successfully changed their password.
 *
 * @param {SuccessModalProps} props - The props for this component.
 * @returns {JSX.Element} - This returns a JSX element representing the SuccessChangePasswordModal component.
 */
const SuccessChangePasswordModal: FC<SuccessModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open={true}>
      <StyledModal>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccessIcon />
          <p>
            {t('personal_information.password_success_changed_modal.title')}
          </p>
          <SecondaryButton onClick={onClose}>
            {t('personal_information.password_success_changed_modal.ok')}
          </SecondaryButton>
        </div>
      </StyledModal>
    </Modal>
  );
};

export default SuccessChangePasswordModal;
