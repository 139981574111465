import React, { FC } from 'react';
import { StyledMain } from './styles';
import { LayoutComponentProps } from './models';
import { AsideMenu } from 'features';

/**
 * Functional component for rendering the main layout of the application.
 *
 * @param children - The child elements to be rendered within the main content area.
 */
const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => (
  <StyledMain>
    <AsideMenu />
    <div>{children}</div>
  </StyledMain>
);
export default LayoutComponent;
