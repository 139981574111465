import {
  CommonEntitiesListResponse,
  HrDepartmentResponseDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  search?: string;
}

/**
 * Department API
 */
export const DepartmentAPI = {
  /**
   * Get departments by company
   * @param {number} [page] - Page number. Default value is 1.
   * @param {number} [limit] - Limit of results per page. Default value is 10.
   * @param {string} [languageIso] - Language ISO code. Used to filter departments based on the language of their names.
   * @param {string} [search] - Search string. Used to search for departments by name or other properties.
   */
  getDepartmentsByCompany: (params?: Props | undefined) =>
    axiosInstance.get<CommonEntitiesListResponse<HrDepartmentResponseDto>>(
      '/departments',
      { params },
    ),

  /**
   * Create department
   * @param {string} name - Name of the department to create.
   */
  createDepartment: (name: string) =>
    axiosInstance.post<HrDepartmentResponseDto>('/departments', { name }),

  /**
   * Edit department
   * @param {Object} params - Parameters for editing the department.
   * @param {string} params.name - New name of the department.
   * @param {string} [params.departmentId] - ID of the department to edit. Required if no other unique identifier is available.
   */
  editDepartment: (params: { name: string; departmentId?: string }) =>
    axiosInstance.patch<HrDepartmentResponseDto>(
      `/departments/${params.departmentId}`,
      { name: params.name },
    ),

  /**
   * Delete department
   * @param {Object} params - Parameters for deleting the department.
   * @param {string} params.departmentForDelete - ID of the department to delete. Required to identify which department to delete.
   * @param {boolean} [params.isForceDelete] - Whether or not to force delete the department. Default value is false.
   */
  deleteDepartment: (params: {
    departmentForDelete: string;
    isForceDelete?: boolean;
  }) =>
    axiosInstance.delete(`/departments/${params.departmentForDelete}`, {
      params: { forceDelete: params.isForceDelete },
    }),
};
