import React, { FC, useEffect, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { useCompanyPaymentMethods } from 'application/hooks/useCompanyPaymentMethods';

import { useStripeLoader } from 'features/subscription-payment/hooks/useStripeLoader';

import StripePaymentForm from '../StripePaymentForm';

import { StripePaymentProps, StripeTheme } from './models';
import { Wrapper } from './styles';
import { PrimaryButton } from 'application/components';
import { ButtonWrapper } from '../StripePaymentForm/styles';
import { useTranslation } from 'react-i18next';
import { addCreditsConfirm } from 'application/store/reducers/EmployeesManagement/actions';
import { isResponseFulfilled } from 'application/helpers/responseHelper';

const StripePayment: FC<StripePaymentProps> = ({
  onPaymentSuccess,
  onPaymentFailed,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { creditPaymentData } = useAppSelector(
    (state) => state.employeesManagement,
  );
  const { getPaymentMethods, paymentMethods } = useCompanyPaymentMethods();
  const { getStripePromise } = useStripeLoader();

  const appearance = {
    theme: 'stripe' as StripeTheme,
  };
  const options = {
    clientSecret: creditPaymentData?.clientSecret || '',
    appearance,
  };

  const stripePromise = useMemo(() => {
    return getStripePromise();
  }, []);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const currentPrice = creditPaymentData?.amountToCharge
    ? creditPaymentData?.amountToCharge / 100
    : 0;

  const handleSubmit = async () => {
    const response = await dispatch(
      addCreditsConfirm({
        uuid: creditPaymentData?.pendingPurchaseUuid!,
      }),
    );

    isResponseFulfilled(
      response,
      () => {
        onPaymentSuccess();
      },
      () => {
        onPaymentFailed(response?.payload.error?.code || 'error');
      },
    );
  };

  const editedBalance = (
    Number(creditPaymentData?.companyBalanceToUse) / 100
  ).toFixed(2);
  const creditBalance = editedBalance || '0.00';

  return (
    <Wrapper>
      {creditPaymentData?.clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <StripePaymentForm
            price={String(currentPrice)}
            clientSecret={creditPaymentData?.clientSecret}
            paymentMethods={paymentMethods}
            onPaymentSuccess={onPaymentSuccess}
            onPaymentFailed={onPaymentFailed}
          />
        </Elements>
      ) : (
        <ButtonWrapper>
          <PrimaryButton type="button" onClick={handleSubmit}>
            {t('subscription_payment.confirm_payment')} €{creditBalance}
          </PrimaryButton>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default StripePayment;
