import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './models';
import {
  createEmployee,
  deleteEmployee,
  editEmployee,
  fetchEmployeeById,
  fetchEmployees,
  addCredits,
  getCompanyTotalCredits,
} from './actions';
import {
  getAssignEmployees,
  getCreditPaymentData,
  getCurrentPackage,
  setAssignEmployees,
  setCurrentPackage,
  setStorageCreditPaymentData,
} from 'application/helpers/sessionStorage/assignEmployees';

const assignedEmployees = getAssignEmployees();
const currentPackage = getCurrentPackage();
const creditPaymentDataStorage = getCreditPaymentData();

const initialState: InitialState = {
  employees: [],
  employeeForAction: null,
  employeeForCreditAction: null,
  employeeForRefundAction: null,
  created: false,
  deleted: false,
  edited: false,
  error: '',
  total: 0,
  page: 1,
  totalPages: 0,
  assignToPackage: assignedEmployees,
  chosenPackage: currentPackage,
  employeeProfile: null,
  creditPaymentData: creditPaymentDataStorage,
  creditPaymentSuccessfully: false,
  creditPaymentFailed: false,
  employeeIdsForCredit: [],
  companyTotalCredits: 0,
};

const employeesManagementSlice = createSlice({
  name: 'employeesManagementSlice',
  initialState,
  reducers: {
    resetEmployeeForAction: (state) => {
      state.employeeForAction = null;
      state.employeeForCreditAction = null;
      state.created = false;
      state.edited = false;
      state.deleted = false;
    },
    setEmployeeForAction: (state, action) => {
      state.employeeForAction = action.payload;
    },
    setEmployeeForCreditAction: (state, action) => {
      state.employeeForCreditAction = action.payload;
    },
    setEmployeeForRefundAction: (state, action) => {
      state.employeeForRefundAction = action.payload;
    },
    // Sets the assign to package and saves it in session storage
    setAssignToPackage: (state, action) => {
      setAssignEmployees(action.payload);
      state.assignToPackage = action.payload;
    },
    // Sets the chosen package and saves it in session storage
    setChosenPackage: (state, action) => {
      setCurrentPackage(action.payload);
      state.chosenPackage = action.payload;
    },
    setCreditPaymentSuccessfully: (state, action) => {
      state.creditPaymentSuccessfully = action.payload;
    },
    setCreditPaymentFailed: (state, action) => {
      state.creditPaymentFailed = action.payload;
    },
    resetCreditData: (state) => {
      state.creditPaymentData = null;
    },
    setEmployeeIdsForCredit: (state, action) => {
      state.employeeIdsForCredit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      state.created = true;
      state.employeeForAction = action.payload;
    });
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.employees = action.payload.entities;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.total = action.payload.total!;
    });
    builder.addCase(editEmployee.fulfilled, (state, action) => {
      state.edited = true;
      state.employeeForAction = action.payload;
    });
    builder.addCase(deleteEmployee.fulfilled, (state) => {
      state.deleted = true;
    });
    builder.addCase(fetchEmployeeById.fulfilled, (state, action) => {
      state.employeeProfile = action.payload;
    });
    builder.addCase(addCredits.fulfilled, (state, action) => {
      setStorageCreditPaymentData(action.payload);
      state.creditPaymentData = action.payload;
    });
    builder.addCase(getCompanyTotalCredits.fulfilled, (state, action) => {
      state.companyTotalCredits = action.payload.totalCredits;
    });
  },
});

export const {
  resetEmployeeForAction,
  setEmployeeForAction,
  setAssignToPackage,
  setChosenPackage,
  setCreditPaymentSuccessfully,
  setCreditPaymentFailed,
  resetCreditData,
  setEmployeeIdsForCredit,
  setEmployeeForCreditAction,
  setEmployeeForRefundAction,
} = employeesManagementSlice.actions;

export default employeesManagementSlice.reducer;
