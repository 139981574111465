import {
  ProfessionalCompanySubscriptionPaymentInitializationDto,
  ProfessionalSubscriptionPaymentResponseDto,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

/**
 * API methods for professional subscriptions and payment.
 */
export const ProfessionalAPI = {
  /**
   * Initialize a payment for a company subscription.
   *
   * @param data - The initialization data for the payment.
   * @returns A promise that resolves to the response DTO containing information about the payment.
   */
  subscriptionPayment: (
    data: ProfessionalCompanySubscriptionPaymentInitializationDto,
  ) => {
    return axiosInstance.post<ProfessionalSubscriptionPaymentResponseDto>(
      '/professionals/company-subscriptions/payment',
      data,
    );
  },

  /**
   * Fetch a professional subscription by its ID.
   *
   * @param subscriptionId - The ID of the subscription to fetch.
   * @returns A promise that resolves to the response DTO containing information about the subscription.
   */
  fetchProfessionalSubscriptionById: (subscriptionId: string) => {
    return axiosInstance.get(`/professionals/subscriptions/${subscriptionId}`);
  },

  /**
   * Get payment methods for a Stripe account.
   *
   * @param stripeAccountId - The ID of the Stripe account to get payment methods for.
   * @returns A promise that resolves to the response DTO containing information about the payment methods.
   */
  getPaymentMethodsForStripeAccount(stripeAccountId: string) {
    return axiosInstance.get(
      `/hr-departments/payment-methods/from-stripe-account/${stripeAccountId}`,
    );
  },

  /**
   * Get company payment methods.
   *
   * @returns A promise that resolves to the response DTO containing information about the company payment methods.
   */
  getCompanyPaymentMethods() {
    return axiosInstance.get('/hr-department/company/payment-methods');
  },
};
