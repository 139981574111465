import React, { FC } from 'react';

import { ConfirmModal } from 'application/components';
import { CancelIcon } from 'application/assets';

import { DeleteConfirmModalProps } from './models';
import { ModalStyled, OptionsList } from './styles';

/**
 * Functional component that renders a modal for confirming deletion.
 * @param opened - A boolean indicating whether the modal is open or closed.
 * @param onClose - A function to be called when the modal is closed.
 * @param onSubmit - A function to be called when the user submits the form.
 * @param title - The title of the modal.
 * @param subTitle - The subtitle of the modal.
 * @param options - An array of strings representing the options to be displayed in the modal.
 * @param isLoading - A boolean indicating whether the form is currently loading.
 */
const DeleteConfirmModal: FC<DeleteConfirmModalProps> = ({
  opened,
  onClose,
  onSubmit,
  title,
  subTitle,
  options,
  isLoading,
}) => {
  return (
    <ConfirmModal
      opened={opened}
      onClose={onClose}
      onSubmit={onSubmit}
      styleType="error"
      isLoading={isLoading}
    >
      <ModalStyled>
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
        <OptionsList>
          {options.map((item, index) => (
            <div key={`delete-option-${index}`}>
              <CancelIcon />
              <p>{item}</p>
            </div>
          ))}
        </OptionsList>
      </ModalStyled>
    </ConfirmModal>
  );
};

export default DeleteConfirmModal;
