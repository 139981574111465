import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { SubscriptionsAPI } from 'integration/api/subscriptions';

/**
 * Redux thunk for fetching subscriptions.
 * @param page - The current page number to fetch.
 * @param thunkApi - The API object containing functions for interacting with the Redux store.
 * @returns A promise that resolves with the fetched data if successful, or rejects with an error if unsuccessful.
 */
const getSubscriptions = createAsyncThunk(
  RequestEnum.getSubscriptions,
  async (page: number, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionsAPI.getSubscriptions(page);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for fetching a subscription by ID.
 * @param companyPurchasedSubscriptionId - The ID of the subscription to fetch.
 * @param thunkApi - The API object containing functions for interacting with the Redux store.
 * @returns A promise that resolves with the fetched data if successful, or rejects with an error if unsuccessful.
 */
const getSubscriptionById = createAsyncThunk(
  RequestEnum.getSubscriptionById,
  async (companyPurchasedSubscriptionId: string, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionsAPI.getSubscriptionById(
        companyPurchasedSubscriptionId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk for deleting a subscription.
 * @param companyPurchasedSubscriptionId - The ID of the subscription to delete.
 * @param thunkApi - The API object containing functions for interacting with the Redux store.
 * @returns A promise that resolves with the fetched data if successful, or rejects with an error if unsuccessful.
 */
const deleteSubscription = createAsyncThunk(
  RequestEnum.deleteSubscription,
  async (companyPurchasedSubscriptionId: string, { rejectWithValue }) => {
    try {
      const { data } = await SubscriptionsAPI.deleteSubscription(
        companyPurchasedSubscriptionId,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const SubscriptionsActions = {
  getSubscriptions,
  getSubscriptionById,
  deleteSubscription,
};
