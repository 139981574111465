import { ClinicAddressResponseDto } from '@docbay/schemas';

/**
 * Formats a clinic address into a string representation.
 *
 * @param roomText - The text to use for the room number (e.g., "Room").
 * @param floorText - The text to use for the floor number (e.g., "Floor").
 * @param address - The clinic address response DTO.
 * @returns A formatted string representation of the address, or an empty string if no address is provided.
 */
export const formatAddress = (
  roomText: string,
  floorText: string,
  address?: ClinicAddressResponseDto,
) => {
  // Return an empty string if no address is provided
  if (!address) {
    return '';
  }

  // Extract the necessary fields from the address object
  const country = address.country;
  const city = address.city;
  const zipCode = address.zipCode;
  const streetAddress = address.streetAddress;
  const floor = address.floor
    ? `${address.floor} ${floorText.toString().toLowerCase()}`
    : '';
  const room = address.room
    ? `${address.room} ${roomText.toString().toLowerCase()}`
    : '';

  // Filter out any empty fields and join the remaining fields with commas
  const addressFiltered = [
    streetAddress,
    room,
    floor,
    zipCode,
    city,
    country,
  ].filter((item) => !!item);

  return addressFiltered.join(', ');
};
