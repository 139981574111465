import { ProfessionalActions } from 'application/store/reducers/Professional/actions';
import { useAppDispatch, useAppSelector } from './redux';

/**
 * Custom hook for handling company payment methods.
 *
 * @returns An object containing the following properties:
 *   - getPaymentMethods: A function that dispatches an action to fetch
 *     the company's payment methods.
 *   - paymentMethods: The current state of the company's payment methods.
 */
export const useCompanyPaymentMethods = () => {
  const dispatch = useAppDispatch();
  const { paymentMethods } = useAppSelector((store) => store.professional);

  const getPaymentMethods = async () => {
    await dispatch(ProfessionalActions.fetchCompanyPaymentMethods());
  };

  return {
    getPaymentMethods,
    paymentMethods,
  };
};
