import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { useDebounce } from 'application/hooks/useDebounce';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { MapBoxSuggest } from 'application/types/mapBox';
import { MapBoxActions } from 'application/store/reducers/MapBox/actions';
import { getCountryCode } from 'application/helpers/sessionStorage/auth';
import { NavigationIcon } from 'application/assets';
import {
  resetSuggestions,
  setLocation as setCoordinate,
} from 'application/store/reducers/MapBox/MapBoxSlice';

import { Loader } from 'common';

import Input from '../Input';
import LocationErrorModal from '../LocationIdentifyModal';

import { MapBoxProps } from './models';
import { LocationItem, MapBoxStyled } from './styles';

/**
 * Functional component for MapBoxAutocompleteInput.
 *
 * @param id - The ID of the input field.
 * @param value - The current value of the input field.
 * @param setLocation - A function to update the location state.
 * @param label - The label text for the input field.
 * @param Icon - An optional icon component to display in the input field.
 * @param errorMessage - An error message to display if there is an issue with the input.
 * @param hideErrorMessage - Whether to hide the error message.
 */
const MapBoxAutocompleteInput: FC<MapBoxProps> = ({
  id,
  value,
  setLocation,
  label,
  Icon,
  errorMessage,
  hideErrorMessage = false,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { suggestions, isLoading, region } = useAppSelector(
    (state) => state.mapBox,
  );
  const { currentUserCountry } = useAppSelector((state) => state.countries);

  const [searchItem, setSearchItem] = useState((value && value?.name) || '');
  const [showList, setShowList] = useState(false);
  const [isLoadingNavigator, setIsLoadingNavigator] = useState(false);
  const [showLocationErrorModal, setShowLocationErrorModal] = useState(false);
  const searchQuery = useDebounce(searchItem, 500);

  // Close the suggestions list when clicking outside of the input field
  useOnClickOutside(ref, () => {
    setShowList(false);
  });

  // Fetch location suggestions based on the search query and current country code
  useEffect(() => {
    const country = getCountryCode();
    if (searchQuery) {
      dispatch(MapBoxActions.fetchLocations({ address: searchQuery, country }));
    }
  }, [searchQuery]);

  // Reset suggestions and clear search input when the current user's country changes
  useEffect(() => {
    dispatch(resetSuggestions());
    setSearchItem('');
  }, [currentUserCountry]);

  // Update search input based on the value prop
  useEffect(() => {
    if (value && value?.name) {
      setSearchItem(value.name);
    } else {
      setSearchItem('');
    }
  }, [value]);

  // Set location when a region is selected and close suggestions list
  useEffect(() => {
    if (region) {
      setShowList(false);
      setLocation(
        {
          maki: '',
          name: region.name,
          address: '',
          language: '',
          mapbox_id: region.id,
          feature_type: '',
          full_address: '',
          name_preferred: '',
          place_formatted: '',
        } as MapBoxSuggest,
        region.id,
      );
    }
  }, [region]);

  // Handle getting the user's current location
  const getMyRegion = async (item: GeolocationPosition) => {
    const latitude = item.coords.latitude;
    const longitude = item.coords.longitude;
    dispatch(setCoordinate({ longitude, latitude }));
    setIsLoadingNavigator(false);
    await dispatch(
      MapBoxActions.getRegionByCoordinates({ longitude, latitude }),
    );
  };

  // Handle errors when getting the user's current location
  const handleErrorPosition = () => {
    setIsLoadingNavigator(false);
  };

  // Get the user's current location and handle permissions
  const getLocation = async () => {
    if (navigator.geolocation) {
      setIsLoadingNavigator(true);
      await navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            setIsLoadingNavigator(false);
            setShowLocationErrorModal(true);
          } else {
            navigator.geolocation.getCurrentPosition(
              getMyRegion,
              handleErrorPosition,
            );
          }
        })
        .catch((e) => {
          setIsLoadingNavigator(false);
          console.error(e);
        });
    } else {
      setIsLoadingNavigator(false);
      setShowLocationErrorModal(true);
    }
  };

  // Set the location and update search input when a suggestion is selected
  const handleSetLocation = (location: MapBoxSuggest, id: string) => {
    setSearchItem(`${location?.name}, ${location?.place_formatted}`);
    setLocation(location, id);
    dispatch(MapBoxActions.getCoordinates(id));
    setShowList(false);
  };

  // Update search input and location state when the input changes
  const onChange = (value: string) => {
    setShowList(true);
    setSearchItem(value);
    if (!value) {
      setLocation(null, '');
    }
  };

  return (
    <>
      {(isLoading || isLoadingNavigator) && <Loader />}
      {showLocationErrorModal && (
        <LocationErrorModal
          opened={showLocationErrorModal}
          onClose={() => setShowLocationErrorModal(false)}
        />
      )}
      <MapBoxStyled
        ref={ref}
        onClick={() => !showList && setShowList(true)}
        className="mapbox-suggestions"
      >
        <Input
          id={id}
          type="location"
          value={searchItem || ''}
          onChange={(e) => onChange(e.target.value)}
          placeholder={label}
          errorMessage={hideErrorMessage ? '' : errorMessage}
          autoComplete="off"
        />

        {showList ? (
          <section className="mapbox-suggestions-list">
            <button type="button" onClick={getLocation}>
              <NavigationIcon />
              {t('global.my_location')}
            </button>
            {!!suggestions.length && (
              <div>
                {suggestions?.map((item) => (
                  <LocationItem
                    key={item.mapbox_id}
                    onClick={() => {
                      handleSetLocation(item, item.mapbox_id);
                    }}
                  >
                    {Icon ? <Icon /> : null}
                    <div>
                      <h2>{item.name}</h2>
                      <p>{item.place_formatted}</p>
                    </div>
                  </LocationItem>
                ))}
              </div>
            )}
          </section>
        ) : (
          ''
        )}
      </MapBoxStyled>
    </>
  );
};

export default memo(MapBoxAutocompleteInput);
