import React, { FC } from 'react';

import { RadioButtonProps } from './models';
import { RadioButtonStyled } from './styles';

/**
 * Functional component representing a radio button.
 *
 * @param id - The unique identifier for the radio button input element.
 * @param label - The text to display as the label for the radio button.
 * @param checked - A boolean value indicating whether the radio button is currently selected or not.
 * @param onChange - A function that will be called whenever the radio button's state changes, with an event object containing information about the change.
 * @param subLabel - The text to display as a sub-label for the radio button, displayed in parentheses after the main label.
 *
 * @returns A JSX element representing the radio button component, styled using the RadioButtonStyled component from './styles'.
 */
const RadioButton: FC<RadioButtonProps> = ({
  id,
  label,
  checked,
  onChange,
  subLabel,
}) => {
  return (
    <RadioButtonStyled>
      <input id={id} type="radio" checked={checked} onChange={onChange} />
      <label htmlFor={id}>
        {label}
        <span> {subLabel}</span>
      </label>
    </RadioButtonStyled>
  );
};

export default RadioButton;
