import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoIcon } from 'application/assets';

import { LoaderStyled } from './styles';

interface LoaderProps {
  text?: string;
}

/**
 * Functional component for displaying a loading spinner and message.
 *
 * @param text - An optional string to display below the "global.loader" translation key.
 * @returns A React element containing the loading spinner and message.
 */
export const Loader: FC<LoaderProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <LoaderStyled>
      <div>
        <LogoIcon />
      </div>
      <h1>
        {t('global.loader')}
        <br />
        {text}
      </h1>
    </LoaderStyled>
  );
};
