import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangePasswordProps } from './modules';
import { Wrapper } from './styles';
import CreatePassword from '../CreatePassword';
import Input from '../Input';

/**
 * A component for changing a user's password.
 *
 * @param props - The properties for the component.
 * @returns The rendered component.
 */
const ChangePassword: FC<ChangePasswordProps> = ({
  watch,
  getValues,
  register,
  oldPasswordErrorMessage,
  passwordErrorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Input
        id="oldPassword"
        type={'password'}
        label={t('personal_information.old_password') || ''}
        placeholder={t('personal_information.old_password') || ''}
        register={register}
        errorMessage={oldPasswordErrorMessage}
      />
      <CreatePassword
        label={t('personal_information.new_password') || ''}
        placeholder={t('personal_information.new_password') || ''}
        watch={watch}
        getValues={getValues}
        register={register}
        errors={passwordErrorMessage}
      />
    </Wrapper>
  );
};

export default ChangePassword;
