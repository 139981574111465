import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Input,
  ManageTableModal,
  DatePicker,
  InputPhone,
  DepartmentDropdown,
} from 'application/components';
import { useTranslation } from 'react-i18next';
import { useEmployeeModal } from 'features/employee-management/hooks/useEmployeeModal';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import {
  createEmployee,
  editEmployee,
} from 'application/store/reducers/EmployeesManagement/actions';
import dayjs from 'dayjs';
import { Props } from './models';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { setEmployeeForAction } from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { Loader } from 'common';
import { ErrorCodes } from 'application/constants/error';
import { resetDepartmentForAction } from 'application/store/reducers/Departments/DepartmentsSlice';
import compareObjectsChanges from 'application/helpers/compareObjectsChanges';
import LocationDropdown from 'application/components/LocationDropdown';

const AddEmployeeModal: FC<Props> = ({ opened, onClose, isEdit }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');

  const {
    fetchEmployees: fetchEmployeesAction,
    deleteEmployee: deleteEmployeeAction,
    createEmployee: createEmployeeAction,
    editEmployee: editEmployeeAction,
  } = useAppSelector(requestSelector);

  const isLoading =
    fetchEmployeesAction?.loading ||
    deleteEmployeeAction?.loading ||
    createEmployeeAction.loading ||
    editEmployeeAction.loading;

  const { employeeForAction } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const {
    register,
    errors,
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues,
    setError,
  } = useEmployeeModal();

  useEffect(() => {
    if (employeeForAction && isEdit) {
      setValue('firstName', employeeForAction.firstName);
      setValue('lastName', employeeForAction.lastName);
      setValue('phone', employeeForAction.phone.replace('+', ''));
      setValue('dateOfBirth', employeeForAction.dateOfBirth);
      setValue('insuranceNumber', employeeForAction.insuranceNumber || '');
      setValue('departmentId', employeeForAction.department?.id || '');
      setValue('jobPosition', employeeForAction.jobPosition);
      setValue('locationId', employeeForAction.location?.id || '');

      setSelectedDepartment(employeeForAction.department?.id || '');
      setSelectedLocation(employeeForAction.location?.id || '');
    }

    return () => {
      reset();
    };
  }, [employeeForAction, isEdit, setValue]);

  const handleChangeDateOfBirth = (value: string) => {
    setValue('dateOfBirth', value, {
      shouldValidate: !!errors.dateOfBirth,
    });
  };

  const handleCloseModal = () => {
    reset();
    setSelectedDepartment('');
    setSelectedLocation('');
    dispatch(setEmployeeForAction(null));
    dispatch(resetDepartmentForAction());
    onClose();
  };

  const onSubmit = async () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      departmentId,
      jobPosition,
      phone,
      insuranceNumber,
      locationId,
    } = getValues();

    const data = {
      firstName,
      lastName,
      jobPosition,
      phone: `+${phone}`,
      insuranceNumber: insuranceNumber ? insuranceNumber : '',
      dateOfBirth: dayjs(dateOfBirth).toISOString(),
      ...(departmentId ? { departmentId } : {}),
      ...(locationId ? { locationId } : {}),
    };

    if (isEdit) {
      const editResponse = await dispatch(
        editEmployee({ data, employeeId: employeeForAction?.id! }),
      );

      isResponseFulfilled(
        editResponse,
        () => {
          reset();
          setSelectedDepartment('');
          setSelectedLocation('');
        },
        () => {
          const res = editResponse?.payload as any;
          if (
            res?.response?.data?.errorCode ===
            ErrorCodes.HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS
          ) {
            setError('phone', {
              message: t('errors.cannot_register_user_phone_error'),
            });
          }
        },
      );
    } else {
      const response = await dispatch(createEmployee(data));

      isResponseFulfilled(
        response,
        () => {
          reset();
          setSelectedDepartment('');
          setSelectedLocation('');
        },
        () => {
          const res = response?.payload as any;
          if (
            res?.response?.data?.errorCode ===
            ErrorCodes.HR_DEPARTMENT_EMPLOYEE_ALREADY_EXISTS
          ) {
            setError('phone', {
              message: t('errors.cannot_register_user_phone_error'),
            });
          }
        },
      );
    }
  };

  const title = isEdit
    ? t('staff.add_employee_modal.edit_title')
    : t('staff.add_employee_modal.title');

  const hasChanges = useMemo(() => {
    return compareObjectsChanges(getValues(), {
      firstName: employeeForAction?.firstName,
      lastName: employeeForAction?.lastName,
      dateOfBirth: employeeForAction?.dateOfBirth,
      departmentId: employeeForAction?.department?.id,
      jobPosition: employeeForAction?.jobPosition,
      phone: employeeForAction?.phone.replace('+', ''),
      insuranceNumber: employeeForAction?.insuranceNumber || '',
      locationId: employeeForAction?.location?.id || '',
    });
  }, [employeeForAction, watch()]);

  const disabledPhoneInput = isEdit && employeeForAction?.isPatientConfirmed;

  return (
    <ManageTableModal
      opened={opened}
      title={title}
      onClose={handleCloseModal}
      disabledSubmit={!!Object.keys(errors).length || !hasChanges}
      onSubmit={handleSubmit(onSubmit)}
    >
      {isLoading && <Loader />}
      <Input
        id={'firstName'}
        placeholder={t('staff.add_employee_modal.first_name_placeholder')}
        label={t('staff.add_employee_modal.first_name_label')}
        register={register}
        errorMessage={errors?.firstName?.message}
        type={'text'}
      />
      <Input
        id={'lastName'}
        placeholder={t('staff.add_employee_modal.last_name_placeholder')}
        label={t('staff.add_employee_modal.last_name_label')}
        register={register}
        errorMessage={errors?.lastName?.message}
        type={'text'}
      />
      <DatePicker
        id={'dateOfBirth'}
        label={t('staff.add_employee_modal.date_of_birth_label') || ''}
        value={watch('dateOfBirth') || ''}
        onChange={handleChangeDateOfBirth}
        maxDate={Date.now()}
        minDate={0}
        format={'DD/MM/YYYY'}
        errorMessage={errors?.dateOfBirth?.message}
      />
      <Input
        id={'insuranceNumber'}
        placeholder={t('staff.add_employee_modal.insurance_number_placeholder')}
        label={t('staff.add_employee_modal.insurance_number')}
        register={register}
        errorMessage={errors?.insuranceNumber?.message}
        type={'number'}
      />
      <InputPhone
        id="phone"
        label={t('global.phone') || ''}
        register={register}
        errorMessage={errors.phone?.message}
        value={watch('phone')}
        isValid={true}
        disabled={disabledPhoneInput}
      />
      <DepartmentDropdown
        id={'departmentId'}
        value={selectedDepartment}
        placeholder={t('department_dropdown.placeholder')}
        onChange={(department) => {
          setValue('departmentId', department.id as string, {
            shouldValidate: true,
          });
          setSelectedDepartment(department.id as string);
        }}
        errorMessage={errors?.departmentId?.message}
        label={t('department_dropdown.label')}
        withSearch
      />
      <Input
        id={'jobPosition'}
        placeholder={t('staff.add_employee_modal.position_placeholder')}
        label={t('staff.add_employee_modal.position_label')}
        register={register}
        errorMessage={errors?.jobPosition?.message}
        type={'text'}
      />
      <LocationDropdown
        id={'location'}
        label={t('staff.add_employee_modal.location')}
        placeholder={t('staff.add_employee_modal.location_placeholder')}
        value={selectedLocation}
        onChange={(locationId) => {
          setValue('locationId', locationId.id as string, {
            shouldValidate: true,
          });
          setSelectedLocation(locationId.id as string);
        }}
        withSearch
      />
    </ManageTableModal>
  );
};

export default AddEmployeeModal;
