import {
  CpSubscriptionResponseDto,
  HrDepartmentEmployeeCreditsPurchaseResponseDto,
} from '@docbay/schemas';

export enum AssignEmployees {
  employees = 'employees',
  currentPackage = 'currentPackage',
  creditPaymentData = 'creditPaymentData',
  subscriptionDoctorId = 'subscriptionDoctorId',
}

interface AssignEmployeeProps {
  id: string;
  name: string;
}

/**
 * Sets the list of assigned employees to sessionStorage.
 * @param arr - An array of employee objects with `id` and `name` properties.
 */
export const setAssignEmployees = (arr: AssignEmployeeProps[]) => {
  sessionStorage.setItem(AssignEmployees.employees, JSON.stringify(arr));
};

/**
 * Sets the current package information to sessionStorage.
 * @param currentPackage - The current subscription package response DTO.
 */
export const setCurrentPackage = (
  currentPackage: CpSubscriptionResponseDto,
) => {
  sessionStorage.setItem(
    AssignEmployees.currentPackage,
    JSON.stringify(currentPackage),
  );
};

/**
 * Retrieves the list of assigned employees from sessionStorage.
 * @returns An array of employee objects, or an empty array if no data is found.
 */
export const getAssignEmployees = () => {
  const employees = sessionStorage.getItem(AssignEmployees.employees);

  return employees ? JSON.parse(employees) : [];
};

/**
 * Retrieves the current package information from sessionStorage.
 * @returns The current subscription package response DTO, or null if no data is found.
 */
export const getCurrentPackage = () => {
  const currentPackage = sessionStorage.getItem(AssignEmployees.currentPackage);

  return currentPackage ? JSON.parse(currentPackage) : null;
};

/**
 * Clears the payment data from sessionStorage by removing the relevant keys.
 */
export const clearPaymentData = () => {
  sessionStorage.removeItem(AssignEmployees.currentPackage);
  sessionStorage.removeItem(AssignEmployees.employees);
  sessionStorage.removeItem(AssignEmployees.subscriptionDoctorId);
};

/**
 * Retrieves the credit payment data from sessionStorage.
 * @returns The credit payment response DTO, or null if no data is found.
 */
export const getCreditPaymentData = () => {
  const creditPaymentData = sessionStorage.getItem(
    AssignEmployees.creditPaymentData,
  );

  return creditPaymentData ? JSON.parse(creditPaymentData) : null;
};

/**
 * Sets the credit payment data to sessionStorage.
 * @param creditPaymentData - The employee credits purchase response DTO.
 */
export const setStorageCreditPaymentData = (
  creditPaymentData: HrDepartmentEmployeeCreditsPurchaseResponseDto,
) => {
  sessionStorage.setItem(
    AssignEmployees.creditPaymentData,
    JSON.stringify(creditPaymentData),
  );
};

/**
 * Clears the credit payment data from sessionStorage by removing the relevant key.
 */
export const clearCreditPaymentData = () => {
  sessionStorage.removeItem(AssignEmployees.creditPaymentData);
};

/**
 * Sets the subscription doctor ID to sessionStorage.
 * @param value - The subscription doctor ID as a string.
 */
export const setSubscriptionDoctorId = (value: string) => {
  sessionStorage.setItem(
    AssignEmployees.subscriptionDoctorId,
    JSON.stringify(value),
  );
};

/**
 * Retrieves the subscription doctor ID from sessionStorage.
 * @returns The subscription doctor ID as a string, or an empty string if no data is found.
 */
export const getSubscriptionDoctorId = () => {
  const subscriptionDoctorId = sessionStorage.getItem(
    AssignEmployees.subscriptionDoctorId,
  );

  return subscriptionDoctorId ? JSON.parse(subscriptionDoctorId) : '';
};
