import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { CountriesAPI } from 'integration/api/countries';

import { RequestEnum } from '../request/model';

/**
 * Redux thunk for fetching supported countries.
 * @returns Data containing the supported countries or an error message if the request fails.
 */
const getSupportedCountries = createAsyncThunk(
  RequestEnum.getSupportedCountries,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.getSupportedCountries();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Redux thunk for setting the user's country.
 * @param params - Object containing the ISO code of the country to set.
 * @returns Data containing the result of setting the user's country or an error message if the request fails.
 */
const setUserCountry = createAsyncThunk(
  RequestEnum.setUserCountry,
  async (params: { countryIso: string }, { rejectWithValue }) => {
    try {
      const { data } = await CountriesAPI.setUserCountry(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const CountryActions = { getSupportedCountries, setUserCountry };
