import { createSlice } from '@reduxjs/toolkit';

import { InitialState } from './models';
import { HrDepartmentSearchActions } from './actions';

const initialState: InitialState = {
  quickSearch: null,
  professionals: [],
  page: 1,
  total: 0,
  totalPages: 0,
  filters: null,
  limit: 10,
  profile: null,
};

const hrDepartmentSearchSlice = createSlice({
  name: 'hrDepartmentSearchSlice',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetQuickSearch: (state) => {
      state.quickSearch = null;
    },
    resetSearchData: (state) => {
      state.quickSearch = null;
      state.professionals = [];
      state.page = 1;
      state.total = 0;
      state.totalPages = 0;
      state.filters = null;
    },
    resetProfessionalProfile: (state) => {
      state.profile = null;
    },
  },
  extraReducers(builder) {
    //quick search
    builder.addCase(
      HrDepartmentSearchActions.quickSearch.fulfilled,
      (state, action) => {
        state.quickSearch = action.payload;
      },
    );
    //fetch hr department search
    builder.addCase(
      HrDepartmentSearchActions.fetchHrDepartmentSearch.fulfilled,
      (state, action) => {
        state.page = action.payload.page || 1;
        state.total = action.payload.total || 0;
        state.totalPages = action.payload.totalPages || 0;
        state.professionals = action.payload.entities;
      },
    );
    //get hr department search professional
    builder.addCase(
      HrDepartmentSearchActions.getHrDepartmentSearchProfessional.fulfilled,
      (state, action) => {
        state.profile = action.payload;
      },
    );
  },
});

export const {
  resetQuickSearch,
  setFilters,
  resetSearchData,
  resetProfessionalProfile,
} = hrDepartmentSearchSlice.actions;

export default hrDepartmentSearchSlice.reducer;
