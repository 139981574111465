import {
  CommonEntitiesListResponse,
  HrDepartmentLocationCreationRequestDto,
  HrDepartmentLocationResponseDto,
} from '@docbay/schemas';

import { GetParams, LocationUpdatePrams } from 'application/types/locations';

import axiosInstance from './axiosInstance';

export const LocationsAPI = {
  /**
   * Fetches a list of locations.
   * @param params - The parameters to filter and paginate the results.
   */
  getLocations: (params: GetParams) => {
    const { search, ...restParams } = params;
    return axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentLocationResponseDto>
    >('/locations', {
      params: {
        ...restParams,
        ...(search?.length ? { search } : {}),
      },
    });
  },

  /**
   * Fetches a single location by its ID.
   * @param locationId - The ID of the location to fetch.
   */
  getLocationById: (locationId: string) => {
    return axiosInstance.get<HrDepartmentLocationResponseDto>(
      `/locations/${locationId}`,
    );
  },

  /**
   * Adds a new location.
   * @param data - The data for the new location.
   */
  addLocation: (data: HrDepartmentLocationCreationRequestDto) => {
    return axiosInstance.post<HrDepartmentLocationResponseDto>(
      '/locations',
      data,
    );
  },

  /**
   * Updates an existing location.
   * @param data - The data to update the location with.
   */
  updateLocation: (data: LocationUpdatePrams) => {
    const { locationId, ...restData } = data;
    return axiosInstance.patch<HrDepartmentLocationResponseDto>(
      `/locations/${locationId}`,
      restData,
    );
  },

  /**
   * Deletes an existing location.
   * @param locationId - The ID of the location to delete.
   */
  deleteLocation: (params: { locationId: string; isForceDelete: boolean }) => {
    return axiosInstance.delete(`/locations/${params.locationId}`, {
      params: { forceDelete: params.isForceDelete },
    });
  },
};
