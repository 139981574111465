import {
  CommonEntitiesListResponse,
  HrDepartmentAdminCreateRequest,
  HrDepartmentAdminUpdateRequest,
  HrDepartmentHrCreateRequest,
  HrDepartmentHrResponse,
  HrDepartmentHrUpdateRequest,
  HrDepartmentUserResponse,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  hrUserTypes?: string;
  search?: string;
}

/**
 * API methods for managing HR users and admins.
 */
export const HrsManagementAPI = {
  /**
   * Fetches a list of HR users.
   *
   * @param params - The parameters to filter the users by.
   * @returns A response containing the list of HR users.
   */
  fetchHrUsers: (params: Props) =>
    axiosInstance.get<CommonEntitiesListResponse<HrDepartmentUserResponse>>(
      '/hr-department/users',
      { params },
    ),

  /**
   * Creates a new HR user.
   *
   * @param data - The data to create the HR user with.
   * @returns A response containing the created HR user.
   */
  createHr: (data: HrDepartmentHrCreateRequest) =>
    axiosInstance.post('/hr-department/hrs/sign-up', data),

  /**
   * Creates a new HR admin.
   *
   * @param data - The data to create the HR admin with.
   * @returns A response containing the created HR admin.
   */
  createAdmin: (data: HrDepartmentAdminCreateRequest) =>
    axiosInstance.post('/hr-department/admins/sign-up', data),

  /**
   * Fetches a list of HR admins.
   *
   * @param params - The parameters to filter the admins by.
   * @returns A response containing the list of HR admins.
   */
  fetchHrs: (params: Props) =>
    axiosInstance.get<CommonEntitiesListResponse<HrDepartmentHrResponse>>(
      '/hr-department/hrs',
      { params },
    ),

  /**
   * Edits an existing HR user.
   *
   * @param params - The parameters to edit the HR user with, including the HR ID.
   * @returns A response containing the edited HR user.
   */
  editHr: (params: { data: HrDepartmentHrUpdateRequest; hrId: string }) =>
    axiosInstance.patch(`/hr-department/hrs/${params.hrId}`, params.data),

  /**
   * Edits an existing HR admin.
   *
   * @param params - The parameters to edit the HR admin with, including the HR admin ID.
   * @returns A response containing the edited HR admin.
   */
  editAdmin: (params: {
    data: HrDepartmentAdminUpdateRequest;
    hrAdminId: string;
  }) =>
    axiosInstance.patch(
      `/hr-department/admins/${params.hrAdminId}`,
      params.data,
    ),

  /**
   * Deletes an existing HR user.
   *
   * @param hrId - The ID of the HR user to delete.
   * @returns A response containing the deleted HR user.
   */
  deleteHr: (hrId: string) =>
    axiosInstance.delete(`/hr-department/hrs/${hrId}`),

  /**
   * Deletes an existing HR admin.
   *
   * @param hrAdminId - The ID of the HR admin to delete.
   * @returns A response containing the deleted HR admin.
   */
  deleteAdmin: (hrAdminId: string) =>
    axiosInstance.delete(`/hr-department/admins/${hrAdminId}`),
};
