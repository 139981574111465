import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum LocalizeNameKey {
  name_EN = 'name_EN',
  name_PT = 'name_PT',
  name_FR = 'name_FR',
}
/**
 * A custom hook that returns the appropriate localization key based on the current language.
 *
 * @returns An object containing a single property `localizeNameKey`, which is the appropriate
 * localization key for the current language.
 */
export const useLocalizeKey = () => {
  // Get the i18next instance from the useTranslation hook.
  const { i18n } = useTranslation();

  // Use useMemo to memoize the result of determining the correct localization key, so that it will only be
  // recalculated when the language changes.
  const localizeNameKey = useMemo(() => {
    return i18n.language.toLowerCase() === 'en'
      ? LocalizeNameKey.name_EN
      : i18n.language.toLowerCase() === 'fr'
      ? LocalizeNameKey.name_FR
      : LocalizeNameKey.name_PT;
  }, [i18n.language]);

  // Return an object containing the localizeNameKey property.
  return {
    localizeNameKey,
  };
};
