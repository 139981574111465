import {
  PaginatedProfessionalHrDepartmentSearchCriteriaDto,
  QuickSearchQueryDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { HrDepartmentSearchAPI } from 'integration/api/hrDepartmentSearch';

/**
 * Fetches HR department search results based on the provided search criteria.
 * @param params - The paginated professional HR department search criteria.
 * @returns A promise that resolves to the fetched data, or rejects with an error if the request fails.
 */
const fetchHrDepartmentSearch = createAsyncThunk(
  RequestEnum.fetchHrDepartmentSearch,
  async (
    params: PaginatedProfessionalHrDepartmentSearchCriteriaDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await HrDepartmentSearchAPI.fetchHrDepartmentSearch(
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Gets the HR department search professional details based on the provided professional ID.
 * @param professionalId - The ID of the professional whose details are to be fetched.
 * @returns A promise that resolves to the fetched data, or rejects with an error if the request fails.
 */
const getHrDepartmentSearchProfessional = createAsyncThunk(
  RequestEnum.getHrDepartmentSearchProfessional,
  async (professionalId: string, { rejectWithValue }) => {
    try {
      const { data } =
        await HrDepartmentSearchAPI.getHrDepartmentSearchProfessional(
          professionalId,
        );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Performs a quick search based on the provided query parameters.
 * @param params - The quick search query parameters.
 * @returns A promise that resolves to the fetched data, or rejects with an error if the request fails.
 */
const quickSearch = createAsyncThunk(
  RequestEnum.quickSearch,
  async (params: QuickSearchQueryDto, { rejectWithValue }) => {
    try {
      const { data } = await HrDepartmentSearchAPI.quickSearch(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const HrDepartmentSearchActions = {
  fetchHrDepartmentSearch,
  getHrDepartmentSearchProfessional,
  quickSearch,
};
