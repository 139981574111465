import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { commonColors } from 'application/theme';
import { ClinicIcon, TimeIcon, WalletIcon } from 'application/assets';

import {
  Wrapper,
  Divider,
  InfoSection,
  ContentItem,
  IconStyled,
} from './styles';
import { useAppSelector } from 'application/hooks/redux';
import { TimePeriods } from 'application/constants/timePeriods';

const CreditCompanyInfo: FC = () => {
  const { t } = useTranslation();

  const { creditPaymentData } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const frequency = useMemo(() => {
    switch (creditPaymentData?.distributionFrequency?.toLowerCase()) {
      case String(TimePeriods.yearly).toLowerCase():
        return t('global.yearly');
      case String(TimePeriods.quarterly).toLowerCase():
        return t('global.quarterly');
      case String(TimePeriods.monthly).toLowerCase():
        return t('global.monthly');
      default:
        return t('global.weekly');
    }
  }, [creditPaymentData, t]);

  const pricePerEmployee = creditPaymentData?.amountPerEmployee
    ? creditPaymentData?.amountPerEmployee / 100
    : 0;

  return (
    <Wrapper>
      <h1>{t('staff.credit_payment_title')}</h1>
      <Divider color={commonColors.grey300} />
      <InfoSection>
        <ContentItem>
          <IconStyled>
            <ClinicIcon />
          </IconStyled>
          <div>
            <h2>{t('staff.company_name')}</h2>
            <h1>{creditPaymentData?.company?.name}</h1>
          </div>
        </ContentItem>
        <ContentItem>
          <IconStyled>
            <WalletIcon />
          </IconStyled>
          <div>
            <h2>{t('staff.credits_amount_employee')}</h2>
            <h1>€{pricePerEmployee}</h1>
          </div>
        </ContentItem>
        <Divider />
        <ContentItem>
          <IconStyled>
            <TimeIcon />
          </IconStyled>
          <div>
            <h2>{t('staff.credit_distribution_frequency')}</h2>
            <h1>{frequency}</h1>
            <p>{t('staff.credit_refund')}</p>
          </div>
        </ContentItem>
      </InfoSection>
    </Wrapper>
  );
};

export default CreditCompanyInfo;
