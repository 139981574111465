import React, { FC, useEffect, useState } from 'react';
import { ClinicProfessionalResponseDto } from '@docbay/schemas';

import { useAppSelector } from 'application/hooks/redux';

import LocationsOfPractice from '../LocationsOfPractice';
import ClinicInsurances from '../ClinicInsurances';
import SubscriptionsList from '../SubscriptionsList';

import { Wrapper } from './styles';

const ClinicInfo: FC = () => {
  const { profile } = useAppSelector((state) => state.hrDepartmentSearch);

  const [selectedClinic, setSelectedClinic] =
    useState<ClinicProfessionalResponseDto | null>(null);

  const insurances = selectedClinic?.clinic?.insurances;
  const subscriptions = selectedClinic?.subscriptions;

  useEffect(() => {
    if (profile?.clinicsRelations?.length) {
      setSelectedClinic(profile.clinicsRelations[0]);
    }
  }, [profile?.clinicsRelations]);

  return (
    <Wrapper>
      {!!profile?.clinicsRelations?.length && (
        <>
          <LocationsOfPractice
            clinics={profile.clinicsRelations}
            selectedClinic={selectedClinic}
            setSelectedClinic={setSelectedClinic}
          />
          {!!insurances?.length && <ClinicInsurances insurances={insurances} />}
          {!!subscriptions?.length && (
            <SubscriptionsList
              clinicName={selectedClinic?.clinic?.name || ''}
              subscriptions={subscriptions}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ClinicInfo;
