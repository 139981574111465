import React, { FC } from 'react';
import { Modal as ModalMui } from '@mui/material';

import { CloseIcon } from 'application/assets';

import { Loader } from 'common';

import { ModalProps } from './models';
import { Wrapper } from './styles';

/**
 * Functional component that renders a modal with the given props.
 *
 * @param opened - A boolean value indicating whether the modal should be shown or not.
 * @param onClose - A function to call when the user clicks on the close icon or outside of the modal.
 * @param children - The content to render inside the modal.
 * @param isLoading - A boolean value indicating whether a loader should be shown in the modal.
 * @param width - The width of the modal wrapper.
 * @param padding - The padding of the modal wrapper.
 * @param height - The height of the modal wrapper.
 */
const Modal: FC<ModalProps> = ({
  opened,
  onClose,
  children,
  isLoading,
  width,
  padding,
  height,
}) => {
  return (
    <ModalMui open={opened}>
      <>
        {isLoading && <Loader />}
        <Wrapper width={width} padding={padding} height={height}>
          <CloseIcon onClick={onClose} className="modal-close-icon" />
          <div>{children}</div>
        </Wrapper>
      </>
    </ModalMui>
  );
};

export default Modal;
