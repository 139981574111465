import { PhotoResponseDto, UserLoginResDto } from '@docbay/schemas';
import i18next from 'i18next';

import { SupportedCountries } from 'application/constants/languages';

import { parseJwt } from '../parseJwt';
import { StorageFields } from 'application/constants';

export enum AuthLocalStorage {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  accessTokenExpiresAt = 'accessTokenExpiresAt',
  profiles = 'profiles',
  userTimezone = 'userTimezone',
  userCountryIso = 'userCountryIso',
  userLanguage = 'i18nextLng',
}

export interface Profile {
  token: string;
  refreshToken: string;
  email: string;
  firstName: string;
  lastName: string;
  photo: PhotoResponseDto;
  id: string;
  userTypes?: string;
  cognitoUuid?: string;
  userCountryIso?: string;
  features?: string[];
}

/**
 * Sets the authentication session storage for a user.
 *
 * @param value - The login response data containing the access token, refresh token, and other information.
 */
export const setUsersAuthSessionStorage = (value: UserLoginResDto) => {
  localStorage.setItem(
    AuthLocalStorage.accessToken,
    JSON.stringify(value.token),
  );
  localStorage.setItem(
    AuthLocalStorage.refreshToken,
    JSON.stringify(value.refreshToken),
  );

  localStorage.setItem(AuthLocalStorage.accessTokenExpiresAt, value.expiresAt);

  const token = parseJwt(value.token);

  localStorage.setItem(StorageFields.userRole, token['custom:userTypes']);
  localStorage.setItem(StorageFields.userId, value.user?.id as string);

  // If the timezone is available in the token, store it in session storage.
  if (token && token['custom:timezone']) {
    localStorage.setItem(
      AuthLocalStorage.userTimezone,
      token['custom:timezone'],
    );
  }

  // If the user has a private doctor, store their UUID in session storage.
  if (token && token['custom:privateDoctor']) {
    localStorage.setItem(
      StorageFields.privateDoctor,
      token['custom:privateDoctor'],
    );
  }

  // If the user has pro permissions, store them in session storage.
  if (token && token['custom:proPermissions']?.length) {
    localStorage.setItem(
      StorageFields.userProPermissions,
      JSON.stringify(token['custom:proPermissions']),
    );
  }

  // If the user's country is available in the token, store it in session storage. Otherwise, default to Portugal.
  if (token && token['custom:countryIso']) {
    localStorage.setItem(
      StorageFields.userCountryIso,
      token['custom:userCountryIso'] || SupportedCountries.Portugal,
    );
  }

  // If the user's language is available in the token, change the i18next language and store it in session storage. Otherwise, default to Portugal.
  if (token && token['custom:languageIso']) {
    i18next.changeLanguage(token['custom:languageIso'].toLowerCase());
    localStorage.setItem(
      AuthLocalStorage.userLanguage,
      token['custom:languageIso'] || SupportedCountries.Portugal,
    );
  }
};

/**
 * Sets the refresh token and related information in session storage.
 *
 * @param value - The object containing the access token and its expiration time.
 */
export const setUsersRefreshTokenSessionStorage = (value: {
  token: string;
  expiresAt: string;
}) => {
  localStorage.setItem(
    AuthLocalStorage.accessToken,
    JSON.stringify(value.token),
  );

  localStorage.setItem(AuthLocalStorage.accessTokenExpiresAt, value.expiresAt);

  const parsedToken = parseJwt(value.token);

  // If the user has pro permissions, store them in session storage.
  if (parsedToken && parsedToken['custom:proPermissions']?.length) {
    localStorage.setItem(
      StorageFields.userProPermissions,
      JSON.stringify(parsedToken['custom:proPermissions']),
    );
  }

  // If the user's country is available in the token, store it in session storage. Otherwise, default to Portugal.
  if (parsedToken && parsedToken['custom:countryIso']) {
    localStorage.setItem(
      StorageFields.userCountryIso,
      parsedToken['custom:userCountryIso'] || SupportedCountries.Portugal,
    );
  }

  // If the timezone is available in the token, store it in session storage.
  if (parsedToken && parsedToken['custom:timezone']) {
    localStorage.setItem(
      AuthLocalStorage.userTimezone,
      parsedToken['custom:timezone'],
    );
  }
};

/**
 * Retrieves the profiles stored in session storage.
 *
 * @returns The array of profiles or null if no profiles are found.
 */
export const getProfilesFromSessionStorage = (): Profile[] | null => {
  const profiles = localStorage.getItem(AuthLocalStorage.profiles);

  return profiles ? (JSON.parse(profiles) as Profile[]) : null;
};

/**
 * Sets the profiles in session storage.
 *
 * @param profiles - The array of profile objects to be stored.
 */
export const setProfilesInSessionStorage = (profiles: Profile[]) => {
  const parsedProfile = profiles.map((item) => {
    const jwtData = parseJwt(item.token);
    return {
      ...item,
      userTypes: jwtData ? jwtData['custom:userTypes'] : '',
      cognitoUuid: jwtData ? jwtData.sub : '',
    };
  });
  localStorage.setItem(
    AuthLocalStorage.profiles,
    JSON.stringify(parsedProfile),
  );

  // Store the user role of the first profile in session storage.
  localStorage.setItem(StorageFields.userRole, parsedProfile[0].userTypes);
};

/**
 * Retrieves the access token stored in session storage.
 *
 * @returns The access token or an empty string if no token is found.
 */
export const getToken = () => {
  const token = localStorage.getItem(AuthLocalStorage.accessToken);
  return token ? JSON.parse(token) : '';
};

/**
 * Sets the user permissions in session storage.
 *
 * @param permissions - The array of permission strings to be stored.
 */
export const setProfilePermissions = (permissions: string[]) => {
  localStorage.setItem(
    StorageFields.userPermissions,
    JSON.stringify(permissions),
  );
};

/**
 * Retrieves the user permissions stored in session storage.
 *
 * @returns The array of permissions or an empty array if no permissions are found.
 */
export const getProfilePermissions = () => {
  const permissions = localStorage.getItem(StorageFields.userPermissions);
  return !!permissions && permissions !== 'undefined'
    ? JSON.parse(permissions)
    : [];
};

/**
 * Retrieves the country code stored in session storage.
 *
 * @returns The uppercase country code or 'PT' if no code is found.
 */
export const getCountryCode = () => {
  const storedCountry = localStorage.getItem(StorageFields.country) || '';
  return storedCountry?.toUpperCase() || 'PT';
};

/**
 * Sets the country code in session storage.
 *
 * @param code - The country code to be stored.
 */
export const setCountryCode = (code: string) => {
  return localStorage.setItem(StorageFields.country, code);
};

/**
 * Retrieves the user ID stored in session storage.
 *
 * @returns The user ID or null if no ID is found.
 */
export const getUserId = () => localStorage.getItem(StorageFields.userId);

/**
 * Retrieves the user role stored in session storage.
 *
 * @returns The user role or null if no role is found.
 */
export const getUserRole = () => localStorage.getItem(StorageFields.userRole);
