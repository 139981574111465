import React, { FC, useMemo } from 'react';
import { ProfileDataStyled, ProfessionalInfo } from './styles';
import { useTranslation } from 'react-i18next';
import { UserRoles } from 'application/constants/userRoles';
import {
  getProfilesFromSessionStorage,
  getUserId,
} from 'application/helpers/sessionStorage/auth';
import { PathNames } from 'application/routes/constants';
import { Link } from 'react-router-dom';

/**
 * Functional component that renders the profile data.
 */
const ProfileData: FC = () => {
  const { t, i18n } = useTranslation();
  const userId = getUserId();

  /**
   * Memoized value of the current user's profile information.
   */
  const currentProfile = useMemo(() => {
    const profiles = getProfilesFromSessionStorage();
    // Get the first profile from the session storage if available
    const currentProfile = profiles?.length ? profiles[0] : null;

    return {
      id: currentProfile?.id,
      firstName: currentProfile?.firstName,
      lastName: currentProfile?.lastName,
      userTypes: currentProfile?.userTypes,
    };
  }, []);

  /**
   * Initials of the current user's first name and last name.
   */
  const currentProfileInitials = currentProfile?.firstName
    ? `${currentProfile?.firstName!.slice(
        0,
        1,
      )}${currentProfile?.lastName!.slice(0, 1)}`
    : '';

  /**
   * Memoized value of the formatted user roles based on the current profile's user types.
   */
  const formattedUserRoles = useMemo(() => {
    return currentProfile?.userTypes?.includes(UserRoles.HrDepartmentAdmin)
      ? t('user_types.admin')
      : t('user_types.hr');
  }, [t, i18n.language, currentProfile]);

  return (
    <ProfileDataStyled>
      <div className="accordion-root">
        <div className="accordion-info">
          <div className="image">
            <>{currentProfileInitials}</>
          </div>
          <ProfessionalInfo>
            <p>{`${currentProfile?.firstName} ${currentProfile?.lastName}`}</p>
            <span>{formattedUserRoles}</span>
            <Link to={`${PathNames.profile}/${userId}`}>
              {t('aside_menu.edit_my_profile')}
            </Link>
          </ProfessionalInfo>
        </div>
      </div>
    </ProfileDataStyled>
  );
};
export default ProfileData;
