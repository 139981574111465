import {
  CommonEntitiesListResponse,
  CountryResponseDto,
} from '@docbay/schemas';

import axiosInstance from 'integration/api/axiosInstance';

export const CountriesAPI = {
  // Get all countries from the API
  getAllCountries: () => {
    return axiosInstance.get('countries');
  },

  // Get a list of supported countries from the API
  getSupportedCountries: () => {
    return axiosInstance.get<CommonEntitiesListResponse<CountryResponseDto>>(
      'countries/supported',
    );
  },

  /**
   * Set the user's country in the API.
   * @param params - An object containing the country ISO code to set as the user's country.
   * @param params.countryIso - A string representing the ISO code of the country to set as the user's country.
   */
  setUserCountry: (params: { countryIso: string }) => {
    return axiosInstance.post('users/change-country', {
      countryIso: params.countryIso,
    });
  },
};
