import React, { FC } from 'react';

import { LanguageDropdown } from 'application/components';
import { useLanguage } from 'application/hooks/useLanguage';

import { LogoIcon } from 'common/auth/assets';

import { LayoutComponentProps } from './models';
import { Wrapper, Header, Footer, Container } from './styles';

/**
 * LayoutComponent is a functional component that renders the layout for all pages in the application.
 *
 * @param children - The child components to be rendered inside the layout.
 */
const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
  const { currentLanguage, changeLanguage } = useLanguage();

  return (
    <Wrapper>
      <Header>
        <LogoIcon />
        <LanguageDropdown value={currentLanguage} onChange={changeLanguage} />
      </Header>
      <Container>{children}</Container>
      <Footer>
        <p>© 2024 DocBay</p>
      </Footer>
    </Wrapper>
  );
};

export default LayoutComponent;
