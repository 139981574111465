import React, { FC } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { CloseIcon, SuccessIcon } from 'application/assets';

import { ModalProps } from './models';
import { Wrapper } from './styles';
import { SecondaryButton } from '../index';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'application/hooks/redux';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { Loader } from 'common';

/**
 * Functional component representing a payment success modal.
 *
 * @property {boolean} opened - The flag indicating whether the modal is open or not.
 * @property {() => void} onClose - The callback function to close the modal.
 * @property {string} actionText - The text for the action button inside the modal.
 * @property {string} title - The title of the payment success message.
 */
const PaymentSuccessModal: FC<ModalProps> = ({
  opened,
  onClose,
  actionText,
  title,
}) => {
  const { t } = useTranslation();
  const { addCredits: addCreditsAction } = useAppSelector(requestSelector);

  const isLoading = addCreditsAction?.loading;

  return (
    <ModalMui open={opened}>
      <>
        {isLoading && <Loader />}
        <Wrapper>
          <CloseIcon onClick={onClose} />
          <div>
            <SuccessIcon />
            <p>
              <span>{title}</span>
            </p>
            <p>{actionText}</p>
          </div>
          <SecondaryButton type={'button'} onClick={onClose}>
            {t('global.ok')}
          </SecondaryButton>
        </Wrapper>
      </>
    </ModalMui>
  );
};

export default PaymentSuccessModal;
