import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';

import { CheckCircleIcon } from 'application/assets';

import Input from '../Input';

import { usePasswordRules } from './hooks';
import { createPasswordValidation } from './helpers';
import { RuleStyled, RulesSectionStyled } from './styles';

interface CreatePasswordProps {
  watch: UseFormWatch<any>;
  getValues: UseFormGetValues<any>;
  register: UseFormRegister<any>;
  errors?: string;
  label?: string;
  placeholder?: string;
}

/**
 * Functional component to create password input field.
 *
 * @param {UseFormWatch} watch - The form state watcher from react-hook-form.
 * @param {UseFormGetValues} getValues - The function to get the values of the form fields from react-hook-form.
 * @param {UseFormRegister} register - The function to register a field in the form from react-hook-form.
 * @param {string} errors - Optional error message for the input field.
 * @param {string} label - Optional label for the input field. If not provided, it defaults to the translated text 'create_password.create_password'.
 * @param {string} placeholder - Optional placeholder for the input field. If not provided, it defaults to the translated text 'create_password.create_password'.
 */
const CreatePassword: FC<CreatePasswordProps> = ({
  watch,
  getValues,
  register,
  errors,
  label,
  placeholder,
}) => {
  const { t } = useTranslation();
  const passwordRules = usePasswordRules();
  const [passwordValidationField, setPasswordValidationField] = useState({
    validLength: false,
    includeUppercase: false,
    includeLowercase: false,
  });

  // useEffect hook to monitor changes in the password field and update the validation state accordingly
  useEffect(() => {
    // Call createPasswordValidation function with the current value of the password field to get the validation rules
    const passwordField = createPasswordValidation(watch('password'));
    setPasswordValidationField(passwordField);
  }, [watch('password')]);

  return (
    <div>
      <Input
        id="password"
        label={label || t('create_password.create_password')!}
        type="password"
        placeholder={placeholder || t('create_password.create_password')!}
        errorMessage={
          (errors && t('create_password.password_may_contain')) || ''
        }
        register={register}
      />
      <RulesSectionStyled>
        {!errors && (
          <RuleStyled
            key={t('create_password.password_may_contain')}
            isValid={!!errors}
          >
            <span>{t('create_password.password_may_contain')}</span>
          </RuleStyled>
        )}
        {passwordRules.map((item) => (
          <RuleStyled key={item.id} isValid={passwordValidationField[item.id]}>
            <CheckCircleIcon />
            <span>{item.name}</span>
          </RuleStyled>
        ))}
      </RulesSectionStyled>
    </div>
  );
};

export default CreatePassword;
