import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from 'application/components';
import { commonColors } from 'application/theme';
import { useAppSelector } from 'application/hooks/redux';

import { Wrapper, TotalSection, Amount } from './styles';

const SelectedEmployees: FC = () => {
  const { t } = useTranslation();
  const { creditPaymentData } = useAppSelector(
    (state) => state.employeesManagement,
  );

  const currentPrice = (price: number) => {
    return price ? price / 100 : 0;
  };

  return (
    <Wrapper>
      <h1>{t('global.selected_employees')}</h1>
      <TotalSection>
        <Amount>
          <p>{t('staff.company_credits_balance')}:</p>
          <p>€{currentPrice(creditPaymentData?.currentCompanyBalance || 0)}</p>
        </Amount>
        <Amount>
          <p>{t('global.price_per_employee')}:</p>
          <p>€{currentPrice(creditPaymentData?.amountPerEmployee || 0)}</p>
        </Amount>
        <Amount>
          <p>{t('global.selected_employees')}:</p>
          <p>{creditPaymentData?.selectedEmployeesCount}</p>
        </Amount>
        <Divider color={commonColors.grey200} />
        <Amount fontWeight={700}>
          <p>{t('global.subtotal')}:</p>
          <p>€{currentPrice(creditPaymentData?.totalAmount || 0)}</p>
        </Amount>
        <Amount fontWeight={700}>
          <p>
            {t('global.vat')} ({creditPaymentData?.vatPercentage}%):
          </p>
          <p>€{currentPrice(creditPaymentData?.vatAmount || 0)}</p>
        </Amount>
        <Amount fontWeight={700} fontSize={16}>
          <p>
            {t('global.total')} {t('global.price')}:
          </p>
          <p>€{currentPrice(creditPaymentData?.totalAmount || 0)}</p>
        </Amount>
        <Divider color={commonColors.grey200} />
        <Amount>
          <p>{t('staff.payment_info')}:</p>
          <p>€{currentPrice(creditPaymentData?.amountToCharge || 0)}</p>
        </Amount>
      </TotalSection>
    </Wrapper>
  );
};

export default SelectedEmployees;
