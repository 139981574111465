import {
  CommonEntitiesListResponse,
  HrDepartmentCompanyCreditsResponseDto,
  HrDepartmentEmployeeCreationRequestDto,
  HrDepartmentEmployeeCreditsPurchaseConfirmDto,
  HrDepartmentEmployeeResponseDto,
  HrDepartmentEmployeeUpdateDto,
  PurchaseEmployeeCreditsDto,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  departmentIds?: string[];
  search?: string;
}

/**
 * Employees Management API
 */
export const EmployeesManagementAPI = {
  /**
   * Create a new employee
   * @param data - Employee creation request data
   * @returns A promise that resolves to the created employee response DTO
   */
  createEmployee: (data: HrDepartmentEmployeeCreationRequestDto) =>
    axiosInstance.post<HrDepartmentEmployeeResponseDto>('/employees', data),

  /**
   * Fetch a list of employees
   * @param params - Pagination and search parameters
   * @returns A promise that resolves to the common entities list response DTO containing employee response DTOs
   */
  fetchEmployees: (params: Props) =>
    axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentEmployeeResponseDto>
    >('/employees', { params }),

  /**
   * Edit an existing employee
   * @param params - Employee update data and the ID of the employee to edit
   * @returns A promise that resolves to the updated employee response DTO
   */
  editEmployee: (params: {
    data: HrDepartmentEmployeeUpdateDto;
    employeeId: string;
  }) =>
    axiosInstance.patch<HrDepartmentEmployeeResponseDto>(
      `/employees/${params.employeeId}`,
      params.data,
    ),

  /**
   * Delete an employee
   * @param employeeId - The ID of the employee to delete
   * @returns A promise that resolves when the employee is successfully deleted
   */
  deleteEmployee: (employeeId: string) =>
    axiosInstance.delete(`/employees/${employeeId}`),

  /**
   * Fetch a single employee by ID
   * @param employeeId - The ID of the employee to fetch
   * @returns A promise that resolves to the fetched employee response DTO
   */
  fetchEmployeeById: (employeeId: string) =>
    axiosInstance.get<HrDepartmentEmployeeResponseDto>(
      `/employees/${employeeId}`,
    ),

  /**
   * Add credits to an employee's account
   * @param data - Purchase employee credits request data
   * @returns A promise that resolves when the credits are successfully added
   */
  addCredits: (data: PurchaseEmployeeCreditsDto) => {
    return axiosInstance.post('/employees/credits/purchase', data);
  },

  /**
   * Fetch the total number of credits for the company's HR department
   * @returns A promise that resolves to the company credits response DTO
   */
  getCompanyTotalCredits: () => {
    return axiosInstance.get<HrDepartmentCompanyCreditsResponseDto>(
      '/hr-departments/companies/credits',
    );
  },

  /**
   * Refund credits from an employee's account
   * @param data - Employee credits refund request data
   * @returns A promise that resolves when the credits are successfully refunded
   */
  addCreditsConfirm: (data: HrDepartmentEmployeeCreditsPurchaseConfirmDto) => {
    return axiosInstance.post('/employees/credits/purchase/confirm', data);
  },
};
