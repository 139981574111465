import { AxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { ResponseDto } from 'integration/api/models';

/**
 * Checks if a given response object represents a fulfilled action.
 * @param response The response object to check.
 * @param callBack An optional function to be called if the action is fulfilled.
 * @param callBackError An optional function to be called if the action is not fulfilled.
 */
export const isResponseFulfilled = (
  response: { type: string },
  callBack?: () => void | Promise<void>,
  callBackError?: () => void | Promise<void>,
) => {
  if (response.type.endsWith('/fulfilled')) {
    callBack && callBack();
  } else {
    callBackError && callBackError();
  }
};

/**
 * Retrieves the error code from a given PayloadAction representing an AxiosError.
 * @param action The PayloadAction to retrieve the error code from.
 * @returns The error code as a string, or undefined if it cannot be found.
 */
export const getResponseError = (
  action: PayloadAction<AxiosError<ResponseDto>>,
) => {
  const error = action.payload;
  const errorCode = error?.response && error?.response.data.errorCode;

  return errorCode;
};
