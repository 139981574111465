import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getResponseError } from 'application/helpers/responseHelper';

import { RequestEnum, RequestState } from './model';

const initialState = Object.fromEntries(
  Object.values(RequestEnum).map((key) => [
    key,
    {
      loading: false,
      error: '',
    },
  ]),
) as RequestState;

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    resetErrors: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    // Add matcher for actions that end with '/pending'
    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state, action) => {
        // Extract the request name from the action type
        const requestName = action.type.split('/')[0] as RequestEnum;
        // If the state has a property for this request name, update it
        if (state[requestName]) {
          state[requestName].error = '';
          state[requestName].loading = true;
        }
      },
    );
    // Add matcher for actions that end with '/rejected'
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action: PayloadAction<any>) => {
        // Extract the request name from the action type
        const requestName = action.type.split('/')[0] as RequestEnum;
        // If the state has a property for this request name, update it
        if (state[requestName]) {
          state[requestName].error = getResponseError(action) || '';
          state[requestName].loading = false;
        }
      },
    );
    // Add matcher for actions that end with '/fulfilled'
    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state, action) => {
        // Extract the request name from the action type
        const requestName = action.type.split('/')[0] as RequestEnum;
        // If the state has a property for this request name, reset it to its initial value
        if (state[requestName]) {
          state[requestName] = initialState[requestName];
        }
      },
    );
  },
});

export const { resetErrors } = requestSlice.actions;
export default requestSlice.reducer;
