export const parseJwt = (token: string) => {
  // Split the token and get the payload part
  const base64Url = token.split('.')[1];

  // Replace characters to make it a valid Base64 encoded string
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  // Decode the payload from Base64 and convert it to JSON
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  // Parse the JSON payload into an object
  const parsedToken = JSON.parse(jsonPayload);

  // Check if "custom:proPermissions" field exists and parse it from a string to an array/object
  if (parsedToken['custom:proPermissions']) {
    parsedToken['custom:proPermissions'] = JSON.parse(
      parsedToken['custom:proPermissions'],
    );
  }

  // Return the parsed token object
  return parsedToken;
};
