import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import PhoneInput, { CountryData } from 'react-phone-input-2';

import useOnClickOutside from 'application/hooks/useOnClickOutside';

import { StyledInput } from './styled';
import 'react-phone-input-2/lib/material.css';
import { useTranslation } from 'react-i18next';

interface InputPhoneProps {
  id: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  hint?: string;
  register: UseFormRegister<any>;
  value?: number | string;
  isValid: boolean;
  allowChangeDialCode?: boolean;
  hideCountryCode?: boolean;
  onChange?: (e: string, dialCode: string) => void;
}

/**
 * A functional component representing a phone input field.
 *
 * @property {string} id - The unique identifier for the input field.
 * @property {string} [placeholder] - Placeholder text to display in the input field.
 * @property {string} [label] - Label text to display above the input field.
 * @property {boolean} [disabled=false] - Indicates whether the input field is disabled.
 * @property {string} [errorMessage] - Error message to display below the input field.
 * @property {string} [hint] - Hint message to display below the input field.
 * @property {UseFormRegister<any>} register - A function from the react-hook-form library to register the input field with form validation.
 * @property {number|string} [value] - The current value of the input field.
 * @property {boolean} isValid - Indicates whether the current value is valid.
 * @property {boolean} [allowChangeDialCode=false] - Indicates whether the user can change the dial code.
 * @property {boolean} [hideCountryCode=false] - Indicates whether to hide the country code in the input field.
 * @property {(e: string, dialCode: string) => void} [onChange] - An optional function to call when the value of the input field changes.
 */
const InputPhone: FC<InputPhoneProps> = ({
  id,
  placeholder,
  label,
  disabled,
  errorMessage,
  hint,
  register,
  value,
  isValid,
  allowChangeDialCode = false,
  hideCountryCode = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Function to handle clicks outside the input field
    const listener = () => {
      if (ref?.current) {
        // Check if the flag dropdown is open
        const flagSection = document.querySelector('.flag-dropdown .open');

        // If the flag section is open, scroll to a specific position smoothly
        !!flagSection &&
          setTimeout(() => {
            const wrapper = document.querySelector('.children-wrapper');

            wrapper?.scrollTo({
              top: 1000,
              behavior: 'smooth',
            });
          }, 200);
      }
    };

    // Add click event listener to the document
    document.addEventListener('click', listener);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref]);

  // Call useOnClickOutside hook to handle clicks outside the input field and set isActive to false
  useOnClickOutside(ref, () => setIsActive(false));
  return (
    <StyledInput
      isActive={isActive}
      isError={!!errorMessage || (!!value && !isValid)}
      disabled={disabled}
    >
      <label>{label}</label>
      <section ref={ref} onMouseDown={() => setIsActive(true)}>
        <PhoneInput
          placeholder={placeholder}
          specialLabel={''}
          country={'pt'}
          {...register(id)}
          value={String(value)}
          onChange={(e, data) => {
            if (hideCountryCode) {
              const dataCode = data as CountryData;

              onChange && onChange(e, dataCode?.dialCode || '');
            } else {
              register &&
                register(id).onChange({
                  target: { value: e, name: id },
                  type: 'change',
                });
            }
          }}
          enableSearch={true}
          searchPlaceholder={t('global.search_by_countries')}
          countryCodeEditable={!!allowChangeDialCode}
          disabled={disabled}
        />
      </section>
      <span>{errorMessage ? errorMessage : hint}</span>
    </StyledInput>
  );
};

export default memo(InputPhone);
