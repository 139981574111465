import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { ExpandMoreIcon, PersonIcon } from 'application/assets';
import { getLanguageFromLocalStorage } from 'application/helpers/language';

import Checkbox from '../Checkbox';
import Input from '../Input';

import { DropdownProps, Option } from './models';
import {
  ClinicImage,
  DropdownStyled,
  OptionCheckBox,
  OptionsList,
  SpecializationsList,
} from './styles';

/**
 * Functional component representing a dropdown menu for selecting professionals.
 *
 * @param id - The unique identifier of the dropdown menu
 * @param placeholder - Text displayed when no value is selected
 * @param searchPlaceholder - Placeholder text for the search input field (optional)
 * @param value - Array of currently selected options
 * @param onChange - Callback function to handle changes in the selected options
 * @param disabled - Boolean indicating whether the dropdown menu is disabled (optional)
 * @param options - Array of available options to select from
 * @param withSearch - Boolean indicating whether to display a search input field (optional)
 */
const DropdownProfessionals: FC<DropdownProps> = ({
  id,
  placeholder,
  searchPlaceholder,
  value,
  onChange,
  disabled,
  options,
  withSearch,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const language = getLanguageFromLocalStorage();
  const localizeKey = `name_${language.toUpperCase()}` as 'name_EN' | 'name_PT';

  //Hook to handle clicks outside the dropdown menu.
  useOnClickOutside(ref, () => setIsActive(false));

  // Memoized array of options that match the search input field value (if provided).
  const optionList = useMemo(() => {
    if (!searchItem) return options;
    return options.filter((item) =>
      item.label.toLocaleLowerCase().includes(searchItem.toLocaleLowerCase()),
    );
  }, [options, searchItem]);

  // Callback function to handle changes in the selected options.
  const handleChange = (selectedItem: Option) => {
    // Check if the value is already added to the selected options array
    const valueAlreadyAdded = value.some(
      (item) => item.value === selectedItem.value,
    );
    // Update the selected options array based on whether the value is already added or not
    if (valueAlreadyAdded) {
      const filteredValue = value.filter(
        (item) => item.value !== selectedItem.value,
      );
      onChange(filteredValue);
    } else {
      onChange([...value, selectedItem]);
    }
    return;
  };

  // Function to check whether a given option is currently selected.
  const IsValueChecked = (item: Option) => {
    // Check if the selected options array is an array and contains the given option's value
    const isValueArray = Array.isArray(value);
    return isValueArray && value.some((i) => i.value === item.value);
  };

  return (
    <DropdownStyled
      id={id}
      isActive={isActive}
      ref={ref}
      onMouseDown={() => setIsActive(true)}
    >
      <section>
        {!value.length ? (
          <span>{placeholder}</span>
        ) : (
          <h2>{`${value.length} ${t('global.selected')}`}</h2>
        )}
        <ExpandMoreIcon />
      </section>
      {/* Display the list of options if the dropdown menu is active */}
      {isActive && (
        <OptionsList>
          {isActive ? (
            <div>
              {withSearch && (
                <Input
                  id={'search'}
                  type="search"
                  value={searchItem}
                  onChange={(e) => setSearchItem(e.target?.value || '')}
                  placeholder={searchPlaceholder}
                  onClear={() => setSearchItem('')}
                />
              )}
              {optionList.map((item) => (
                <OptionCheckBox
                  htmlFor={String(item.value)}
                  key={item.value}
                  selected={IsValueChecked(item!)}
                >
                  <div>
                    <ClinicImage>
                      {item.image ? (
                        <img src={item.image} alt={item.label} />
                      ) : (
                        <PersonIcon />
                      )}
                    </ClinicImage>
                    <div>
                      <p>{item.label}</p>
                      <SpecializationsList>
                        {item.specializations?.map((specialization, index) => {
                          return (
                            <p key={specialization.id}>
                              {specialization[localizeKey]}
                              {/* Add a comma if it's not the last specialization */}
                              {index + 1 !== item?.specializations?.length &&
                                ','}
                            </p>
                          );
                        })}
                      </SpecializationsList>
                    </div>
                  </div>
                  <Checkbox
                    id={String(item.value)}
                    checked={IsValueChecked(item)}
                    onChange={() => handleChange(item)}
                    disabled={disabled && IsValueChecked(item!)}
                  />
                </OptionCheckBox>
              ))}
            </div>
          ) : (
            ''
          )}
        </OptionsList>
      )}
    </DropdownStyled>
  );
};

export default DropdownProfessionals;
