import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { StyledButton } from './styles';

interface SecondaryButtonProps {
  styleType?: 'default' | 'color' | 'error';
  disabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

/**
 * A functional component that renders a secondary button with optional styles, disabled state,
 * children content, click event handler, and button type.
 *
 * @param {SecondaryButtonProps} props - An object containing the properties for the SecondaryButton
 *   component.
 * @returns {JSX.Element} - The rendered SecondaryButton component.
 */
const SecondaryButton: FC<SecondaryButtonProps> = ({
  styleType = 'default',
  disabled = false,
  children,
  onClick,
  type,
}) => {
  return (
    <StyledButton
      styleType={styleType}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default SecondaryButton;
