import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { CpSubscriptionResponseDto } from '@docbay/schemas';

import {
  HomeWorkIcon,
  GroupsIcon,
  WalletIcon,
  TimeIcon,
  VideoCameraIcon,
} from 'application/assets';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import {
  setAssignToPackage,
  setChosenPackage,
} from 'application/store/reducers/EmployeesManagement/EmployeesManagementSlice';
import {
  clearPaymentData,
  setSubscriptionDoctorId,
} from 'application/helpers/sessionStorage/assignEmployees';

import AssignEmployee from '../AssignEmployee';

import { SubscriptionsListProps } from './models';
import {
  Wrapper,
  SubscriptionStyled,
  TopSection,
  ContentStyled,
  InfoList,
  ActionSection,
} from './styles';

const SubscriptionsList: FC<SubscriptionsListProps> = ({
  clinicName,
  subscriptions,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [assigneeModalOpen, setAssigneeModalOpen] = useState<string | null>(
    null,
  );

  const { chosenPackage } = useAppSelector(
    (state) => state.employeesManagement,
  );

  useEffect(() => {
    if (chosenPackage) {
      setAssigneeModalOpen(chosenPackage.id);
    }
  }, [chosenPackage]);

  useEffect(() => {}, []);

  const onSubscribe = (subscription: CpSubscriptionResponseDto) => {
    setAssigneeModalOpen(subscription.id);
    dispatch(setChosenPackage(subscription));
    if (id) {
      setSubscriptionDoctorId(id);
      dispatch(setAssignToPackage([]));
    }
  };

  return (
    <Wrapper>
      <h1>{t('doctor_profile.available_subscriptions')}</h1>

      {subscriptions.map((subscription) => (
        <SubscriptionStyled key={subscription.id}>
          <TopSection>
            <h1>{subscription.title}</h1>
          </TopSection>
          <ContentStyled>
            <InfoList>
              <div>
                <HomeWorkIcon />
                <h2>{t('global.clinic')}:</h2>
                <p>{clinicName}</p>
              </div>
              <div>
                <TimeIcon />
                <h2>{t('global.duration')}:</h2>
                <p>
                  {subscription.durationInWeeks}{' '}
                  {t(
                    Number(subscription.durationInWeeks) > 1
                      ? 'global.weeks'
                      : 'global.week',
                  )}
                </p>
              </div>
              <div>
                <GroupsIcon />
                <h2>{t('global.employee_range')}:</h2>
                <p>{`${subscription.minNumberOfEmployees} - ${
                  subscription.maxNumberOfEmployees
                } ${t('global.employees')}`}</p>
              </div>
              <div>
                <VideoCameraIcon />
                <h2>{t('global.teleconsultation')}:</h2>
                <p>
                  {subscription.teleconsultationMinutes} {t('global.min')}
                </p>
              </div>
              <div>
                <WalletIcon />
                <h2>{t('global.price_per_employee')}:</h2>
                <p>€{subscription.price}</p>
              </div>
            </InfoList>
            <ActionSection>
              <button
                type="button"
                onClick={() => {
                  onSubscribe(subscription);
                }}
              >
                {t('global.subscribe')}
                <ChevronRight />
              </button>
            </ActionSection>
          </ContentStyled>
        </SubscriptionStyled>
      ))}
      <AssignEmployee
        opened={!!assigneeModalOpen}
        activeSubscription={assigneeModalOpen}
        onClose={() => setAssigneeModalOpen(null)}
        subscriptions={subscriptions}
      />
    </Wrapper>
  );
};

export default SubscriptionsList;
