import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import i18n from 'i18n';

import {
  ClinicIcon,
  SubscriptionsIcon,
  PinIcon,
  DashboardIcon,
  FolderSharedIcon,
  SettingsIcon,
} from 'application/assets';
import { featuresPermissions } from 'application/helpers/featuresPermissions';

import { Loader } from 'common';

import { PathNames } from './constants';

import ErrorPage from '../pages/ErrorPage';

const CompanyRegistrationPage = lazy(() =>
  import('../pages/CompanyRegistrationPage'),
);
const LoginPage = lazy(() => import('../../common/auth/signIn/Login'));
const ForgotPassword = lazy(() =>
  import('../../common/auth/forgotPassword/ForgotPasswordPage'),
);
const CreateNewPassword = lazy(() =>
  import('../../common/auth/forgotPassword/CreateNewPasswordPage'),
);
const ConfirmPasswordChangePage = lazy(() =>
  import('../../common/auth/forgotPassword/ConfirmPasswordChangePage'),
);
const HrManagementPage = lazy(() => import('../pages/HrManagementPage'));

const EmployeesManagementPage = lazy(() =>
  import('../pages/EmployeesManagementPage'),
);
const DepartmentsPage = lazy(() => import('../pages/DepartmentsPage'));
const LocationsPage = lazy(() => import('../pages/LocationsPage'));
const BenefitPlansPage = lazy(() => import('../pages/BenefitPlansPage'));
const DoctorSubscriptionPage = lazy(() =>
  import('../pages/DoctorSubscriptionPage'),
);
const FindDoctorPage = lazy(() => import('../pages/FindDoctorPage'));
const DoctorProfilePage = lazy(() => import('../pages/DoctorProfilePage'));
const SubscriptionPaymentPage = lazy(() =>
  import('../pages/SubscriptionPaymentPage'),
);
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const EmployeeProfilePage = lazy(() => import('../pages/EmployeeProfilePage'));
const CreditPaymentPage = lazy(() => import('../pages/CreditPaymentPage'));
const ProfilePage = lazy(() => import('../pages/ProfilePage'));

export const publicRouts = [
  {
    path: PathNames.login,
    title: 'Login',
    component: (
      <Suspense fallback={<Loader />}>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: 'Forgot Password',
    component: (
      <Suspense fallback={<Loader />}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmPassword,
    title: 'Confirm Password Change',
    component: (
      <Suspense fallback={<Loader />}>
        <ConfirmPasswordChangePage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createNewPassword,
    title: 'Create New Password',
    component: (
      <Suspense fallback={<Loader />}>
        <CreateNewPassword />
      </Suspense>
    ),
  },
  {
    path: PathNames.companyRegistration,
    title: 'Company registration',
    component: (
      <Suspense fallback={<Loader />}>
        <CompanyRegistrationPage />
      </Suspense>
    ),
  },

  {
    path: '/*',
    title: 'Error',
    component: <ErrorPage />,
  },
];

export const getCurrentRoutes = () => {
  const { hasCreditManagementPermission, hasSubscriptionManagementPermission } =
    featuresPermissions();

  const staffRoutesList = [
    {
      path: PathNames.hrManagement,
      title: 'HR Management',
      component: (
        <Suspense fallback={<Loader />}>
          <HrManagementPage />
        </Suspense>
      ),
      isSecondMenuItem: true,
    },
    {
      path: PathNames.employeesManagement,
      title: 'Employees Management',
      component: (
        <Suspense fallback={<Loader />}>
          <EmployeesManagementPage />
        </Suspense>
      ),
      isSecondMenuItem: true,
    },
  ];

  const settingsRoutesList = [
    //TODO: uncomment, when company billing page will be ready
    // {
    //   path: PathNames.companyBilling,
    //   title: 'Company billing',
    //   component: <></>,
    //   name: i18n.t('submenu_list.company_billing'),
    //   image: SubscriptionsIcon,
    //   isSecondMenuItem: true,
    // },
    ...(hasSubscriptionManagementPermission || hasCreditManagementPermission
      ? [
          {
            path: PathNames.locations,
            title: 'Locations',
            component: (
              <Suspense fallback={<Loader />}>
                <LocationsPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('submenu_list.locations'),
            image: PinIcon,
          },
          {
            path: PathNames.departments,
            title: 'Departments',
            component: (
              <Suspense fallback={<Loader />}>
                <DepartmentsPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('submenu_list.departments'),
            image: ClinicIcon,
          },
          {
            path: PathNames.benefitPlans,
            title: 'Benefit plans',
            component: (
              <Suspense fallback={<Loader />}>
                <BenefitPlansPage />
              </Suspense>
            ),
            isSecondMenuItem: true,
            name: i18n.t('submenu_list.benefit_plans'),
            image: SubscriptionsIcon,
          },
          ...(hasSubscriptionManagementPermission
            ? [
                {
                  path: PathNames.doctorSubscription,
                  title: 'Doctor subscription',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <DoctorSubscriptionPage />
                    </Suspense>
                  ),
                },
                {
                  path: PathNames.findDoctor,
                  title: 'Find doctor',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <FindDoctorPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.doctorProfile}/:id`,
                  title: 'Doctor profile',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <DoctorProfilePage />
                    </Suspense>
                  ),
                },
                {
                  path: PathNames.subscriptionPayment,
                  title: 'Subscription payment',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <SubscriptionPaymentPage />
                    </Suspense>
                  ),
                },
                {
                  path: `${PathNames.dashboardEmployeeProfile}/:id`,
                  title: 'Employee profile',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <EmployeeProfilePage />
                    </Suspense>
                  ),
                },
              ]
            : []),
          ...(hasCreditManagementPermission
            ? [
                {
                  path: PathNames.creditPayment,
                  title: 'Credits',
                  component: (
                    <Suspense fallback={<Loader />}>
                      <CreditPaymentPage />
                    </Suspense>
                  ),
                },
              ]
            : []),
        ]
      : []),
  ];

  const privateRoutes = [
    {
      path: PathNames.dashboard,
      title: 'Dashboard',
      component: (
        <Suspense fallback={<Loader />}>
          <DashboardPage />
        </Suspense>
      ),
      name: i18n.t('aside_menu.dashboard'),
      image: DashboardIcon,
      menuItem: true,
    },
    ...(staffRoutesList.length
      ? [
          {
            path: staffRoutesList[0].path,
            title: staffRoutesList[0].title,
            component: staffRoutesList[0].component,
            menuItem: true,
            name: i18n.t('aside_menu.staff'),
            image: FolderSharedIcon,
          },
          ...staffRoutesList,
        ]
      : []),
    ...(settingsRoutesList.length
      ? [
          {
            path: settingsRoutesList[0].path,
            title: settingsRoutesList[0].title,
            component: settingsRoutesList[0].component,
            menuItem: true,
            name: i18n.t('aside_menu.settings'),
            image: SettingsIcon,
          },
          ...settingsRoutesList,
        ]
      : []),
  ];

  const homePagePath = privateRoutes[0].path;

  const defaultRoutes = [
    {
      path: PathNames.home,
      title: '/',
      component: (
        <Suspense fallback={<Loader />}>
          <Navigate to={homePagePath} />
        </Suspense>
      ),
    },
    {
      path: `${PathNames.profile}/:id`,
      title: '/profile',
      component: (
        <Suspense fallback={<Loader />}>
          <ProfilePage />
        </Suspense>
      ),
      menuItem: true,
    },
  ];

  return [...privateRoutes, ...defaultRoutes];
};
