/**
 * Calculates the distance between two points on the earth's surface using the Haversine formula.
 * @param lat1 - The latitude of the first point in decimal degrees.
 * @param lon1 - The longitude of the first point in decimal degrees.
 * @param lat2 - The latitude of the second point in decimal degrees.
 * @param lon2 - The longitude of the second point in decimal degrees.
 * @returns The distance between the two points in kilometers.
 */
export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  // Convert a value from degrees to radians
  const toRad = (value: number): number => (value * Math.PI) / 180;

  // Radius of the earth in kilometers
  const earthRadius = 6371;

  // Calculate the differences between the latitudes and longitudes in radians
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);

  // Calculate the Haversine formula components
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  // Calculate the central angle in radians
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance between the two points using the radius of the earth
  const distance = earthRadius * c;
  return distance; // in kilometers
};
