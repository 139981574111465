import React, { FC, useRef, useState } from 'react';
import dayjs from 'dayjs';
import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { DatePickerStyled, Wrapper } from './styles';
import { DatePickerProps } from './models';

// Define the default format for the date picker
const defaultFormat = 'DD/MM/YYYY';

/**
 * A custom date picker functional component that renders a styled date picker input field.
 *
 * @param id - The unique identifier of the date picker input field.
 * @param label - The label to display above the date picker input field.
 * @param value - The current value of the date picker input field.
 * @param maxDate - The maximum allowable date for the date picker input field.
 * @param minDate - The minimum allowable date for the date picker input field.
 * @param format - (Optional) The format to display the selected date in. Default is 'DD/MM/YYYY'.
 * @param onChange - A callback function that will be called whenever the value of the date picker input field changes.
 * @param errorMessage - An error message to display below the date picker input field, if there is an error with the value.
 * @param disabled - (Optional) Whether the date picker input field should be disabled. Default is false.
 * @param openTo - The view that the date picker should open to by default.
 * @param views - The views that the date picker should display.
 * @param isError - (Optional) Whether an error should be displayed below the date picker input field, regardless of whether there is an actual error with the value. Default is false.
 *
 * @returns A React element representing the custom date picker functional component.
 */
const DatePickerCustom: FC<DatePickerProps> = ({
  id,
  label,
  value,
  maxDate,
  minDate,
  format = defaultFormat,
  onChange,
  errorMessage,
  disabled = false,
  openTo,
  views,
  isError = false,
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  return (
    <Wrapper
      ref={ref}
      isError={!!errorMessage}
      onMouseDown={() => setIsActive(true)}
    >
      <label htmlFor={id}>{label}</label>

      <DatePickerStyled
        isActive={isActive}
        value={dayjs(value)}
        maxDate={dayjs(maxDate)}
        minDate={minDate ? dayjs(minDate) : dayjs('01/01/1901')}
        onChange={(value) => onChange(dayjs(value as string).toString())}
        format={defaultFormat}
        isError={!!errorMessage || isError}
        disabled={disabled}
        openTo={openTo}
        views={views}
      />
      {errorMessage && <span>{errorMessage}</span>}
    </Wrapper>
  );
};

export default DatePickerCustom;
