import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAdminProfileById,
  getHrProfileById,
  profileChangePassword,
  profileEditAdmin,
  profileEditHr,
  profileSetNewEmail,
  profileVerifyCode,
  profileVerifyOldEmail,
} from './ActionCreators';
import { ProfileState } from './models';
import {
  HrDepartmentHrResponse,
  HrDepartmentAdminResponse,
} from '@docbay/schemas';
import {
  AuthLocalStorage,
  setUsersRefreshTokenSessionStorage,
} from 'application/helpers/sessionStorage/auth';

const initialState: ProfileState = {
  total: 0,
  page: 0,
  totalPages: 0,
  profileChanges: null,
  currentUser: null,
  isLoading: false,
  error: '',
  isAdded: false,
  isUpdated: false,
  isVerificationCodeExpired: false,
  isCreatePassword: false,
  isVerifiedOldEmail: false,
  isVerifiedOldEmailCode: false,
  isVerifiedOldPhone: false,
  isVerifiedOldPhoneCode: false,
  isResentActive: true,
  isWrongVerificationCode: false,
  isPasswordChanged: false,
};

const profileSlice = createSlice({
  name: 'secretariesSlice',
  initialState,
  reducers: {
    setIsPasswordChanged: (state, actions) => {
      state.isPasswordChanged = false;
    },
    resetIsUpdatedSecretaryData: (state) => {
      state.isUpdated = false;
      state.error = '';
    },
    resetEmailData: (state) => {
      state.isVerifiedOldEmail = false;
      state.isVerifiedOldEmailCode = false;
      state.isWrongVerificationCode = false;
    },
    resetVerifiedOldEmailCode: (state) => {
      state.isVerifiedOldEmailCode = false;
    },
    setCurrentData: (state, action) => {
      state.currentUser = action.payload;
    },
    resetVerifiedOldPhoneCode: (state) => {
      state.isVerifiedOldPhoneCode = false;
    },
    resetPhoneData: (state) => {
      state.isVerifiedOldPhone = false;
      state.isVerifiedOldPhoneCode = false;
      state.isWrongVerificationCode = false;
    },
    setIsResentActive: (state, actions) => {
      state.isResentActive = actions.payload;
    },
    setIsWrongVerificationCode: (state, actions) => {
      state.isWrongVerificationCode = actions.payload;
    },
    resetSecretaryError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    //get profile data
    builder.addCase(getAdminProfileById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = action.payload;
    });

    builder.addCase(getHrProfileById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentUser = action.payload;
    });

    //verify old email
    builder.addCase(profileVerifyOldEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmail = true;
    });

    //verify code
    builder.addCase(profileVerifyCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(profileVerifyCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isVerifiedOldEmailCode = !!action.payload;
    });

    //set new email
    builder.addCase(profileSetNewEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
      state.isWrongVerificationCode = true;
    });
    builder.addCase(profileSetNewEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.token) {
        setUsersRefreshTokenSessionStorage(action.payload);

        const newData = {
          ...state.currentUser,
          email: String(sessionStorage.getItem('newEmail')),
        };

        state.currentUser = newData as
          | HrDepartmentAdminResponse
          | HrDepartmentHrResponse;

        const profiles = JSON.parse(
          localStorage.getItem(AuthLocalStorage.profiles) as string,
        );

        const newProfile = {
          ...profiles[0],
          email: String(sessionStorage.getItem('newEmail')),
          token: action.payload.token,
        };

        localStorage.setItem(
          AuthLocalStorage.profiles,
          JSON.stringify([newProfile]),
        );
        sessionStorage.removeItem('oldEmail');
        sessionStorage.removeItem('newEmail');
      }
    });

    builder.addCase(profileEditHr.fulfilled, (state, action) => {
      state.isUpdated = true;
      state.currentUser = action.payload;
    });
    builder.addCase(profileEditAdmin.fulfilled, (state, action) => {
      state.isUpdated = true;
      state.currentUser = action.payload;
    });
    builder.addCase(profileChangePassword.fulfilled, (state) => {
      state.isLoading = false;
      state.error = '';
      state.isPasswordChanged = true;
    });
  },
});

export const {
  setIsPasswordChanged,
  resetEmailData,
  resetVerifiedOldEmailCode,
  setIsResentActive,
  setIsWrongVerificationCode,
  resetSecretaryError,
} = profileSlice.actions;

export default profileSlice.reducer;
