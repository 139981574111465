import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LocationErrorIcon } from 'application/assets';

import Modal from '../Modal';
import SecondaryButton from '../SecondaryButton';

import { LocationErrorModalProps } from './models';
import { ModalStyled } from './styles';

/**
 * Functional component for displaying a modal error message when the user's location is not allowed.
 *
 * @param opened - A boolean indicating whether the modal should be displayed or not.
 * @param onClose - A function to call when the user closes the modal.
 */
const LocationErrorModal: FC<LocationErrorModalProps> = ({
  opened,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Modal opened={opened} onClose={onClose}>
      <ModalStyled>
        <LocationErrorIcon />
        <h1>{t('errors.location_not_allowed')}</h1>
        <SecondaryButton type="button" styleType="color" onClick={onClose}>
          {t('global.got_it')}
        </SecondaryButton>
      </ModalStyled>
    </Modal>
  );
};

export default LocationErrorModal;
