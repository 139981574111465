import {
  HrDepartmentAdminConfirmSignUpRequest,
  HrDepartmentCompanyCreateRequest,
  ResendConfirmationCodeDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { CompanyRegistrationAPI } from 'integration/api/companyRegistration';

/**
 * Redux thunk to handle the company sign-up process
 *
 * @param params - The request object containing the necessary information for company sign-up
 * @returns An async function that returns the response data on success or an error on failure
 */
const companySignUp = createAsyncThunk(
  RequestEnum.companySignUp,
  async (params: HrDepartmentCompanyCreateRequest, { rejectWithValue }) => {
    try {
      const { data } = await CompanyRegistrationAPI.companySignUp(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to handle the confirmation of company sign-up
 *
 * @param params - The request object containing the necessary information for confirming company sign-up
 * @returns An async function that returns the response data on success or an error on failure
 */
const companyConfirmSingUp = createAsyncThunk(
  RequestEnum.companyConfirmSingUp,
  async (
    params: HrDepartmentAdminConfirmSignUpRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await CompanyRegistrationAPI.companyConfirmSingUp(
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Redux thunk to handle the resend of confirmation code
 *
 * @param params - The request object containing the necessary information for resending the confirmation code
 * @returns An async function that returns the response data on success or an error on failure
 */
const resendConfirmationCode = createAsyncThunk(
  RequestEnum.resendConfirmationCode,
  async (params: ResendConfirmationCodeDto, { rejectWithValue }) => {
    try {
      const { data } = await CompanyRegistrationAPI.resendConfirmationCode(
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const CompanyRegistrationActions = {
  companySignUp,
  companyConfirmSingUp,
  resendConfirmationCode,
};
