import React, { useEffect } from 'react';

/**
 * useOnClickOutside custom hook
 *
 * @param ref - a ref to an element within which clicks should not trigger the handler
 * @param handler - a function to call when a click occurs outside of the ref element
 */
const useOnClickOutside = (
  ref: React.MutableRefObject<any>,
  handler: (value: boolean) => void,
) => {
  // Add the listener to the document's mousedown and touchstart events
  useEffect(() => {
    // Define a listener function that will be called on mousedown and touchstart events
    const listener = (event: MouseEvent | TouchEvent) => {
      // If the event target is within the ref element, do nothing
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      // Otherwise, call the handler function with a boolean value indicating whether the event was triggered by a mouse or touch event
      handler(!!event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    // Remove the listener from the document's events when the component unmounts or the ref or handler changes
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
