import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEnum } from 'application/store/reducers/request/model';
import { AxiosError } from 'axios';
import {
  HrDepartmentAdminUpdateRequest,
  HrDepartmentHrUpdateRequest,
  UserChangeEmailDto,
  UserChangePasswordDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';

import { UsersAPI } from 'integration/api/users';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
import { HrsManagementAPI } from 'integration/api/hrManagement';

/**
 * Fetches admin profile by ID.
 * @param id - The ID of the admin to fetch.
 * @returns Admin profile data.
 * @throws Axios error if fetching fails.
 */
export const getAdminProfileById = createAsyncThunk(
  RequestEnum.getAdminProfileById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.getAdminProfileById(id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Fetches HR profile by ID.
 * @param id - The ID of the HR to fetch.
 * @returns HR profile data.
 * @throws Axios error if fetching fails.
 */
export const getHrProfileById = createAsyncThunk(
  RequestEnum.getHrProfileById,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.getHrProfileById(id);

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Edits HR profile.
 * @param params - Object containing the update request and HR ID.
 * @returns Updated HR profile data.
 * @throws Error if editing fails.
 */
export const profileEditHr = createAsyncThunk(
  RequestEnum.profileEditHr,
  async (
    params: { data: HrDepartmentHrUpdateRequest; hrId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await HrsManagementAPI.editHr(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Edits admin profile.
 * @param params - Object containing the update request and admin ID.
 * @returns Updated admin profile data.
 * @throws Error if editing fails.
 */
export const profileEditAdmin = createAsyncThunk(
  RequestEnum.profileEditAdmin,
  async (
    params: { data: HrDepartmentAdminUpdateRequest; hrAdminId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await HrsManagementAPI.editAdmin(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

/**
 * Verifies old email.
 * @param params - Object containing the email and user type.
 * @returns Verification data.
 * @throws Axios error if verification fails.
 */
export const profileVerifyOldEmail = createAsyncThunk(
  RequestEnum.profileVerifyOldEmail,
  async (params: { email: string; userType: string }, { rejectWithValue }) => {
    try {
      const language = getLanguageFromLocalStorage();
      const { data } = await UsersAPI.userVerifyOldEmail({
        ...params,
        language,
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Verifies code.
 * @param params - Verification code DTO.
 * @returns Verification data.
 * @throws Axios error if verification fails.
 */
export const profileVerifyCode = createAsyncThunk(
  RequestEnum.profileVerifyCode,
  async (params: UserVerifyCodeDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersVerifyCode(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Sets new email.
 * @param params - New email DTO.
 * @returns Updated user data.
 * @throws Axios error if setting fails.
 */
export const profileSetNewEmail = createAsyncThunk(
  RequestEnum.profileSetNewEmail,
  async (params: UserChangeEmailDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.userSetNewEmail(params);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

/**
 * Changes password.
 * @param data - Password change DTO.
 * @returns Updated user data.
 * @throws Axios error if changing fails.
 */
export const profileChangePassword = createAsyncThunk(
  RequestEnum.profileChangePassword,
  async (data: UserChangePasswordDto, { rejectWithValue }) => {
    try {
      const { data: responseData } = await UsersAPI.userChangePassword(data);
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);
