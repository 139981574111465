import React, { FC } from 'react';

import {
  FlagFranceIcon,
  FlagPortugalIcon,
  FlagUkraineIcon,
  FlagSpainIcon,
} from 'application/assets';

import { FlagProps } from './models';

/**
 * Functional component that renders a flag icon based on the provided country code.
 *
 * @param code - A string representing the ISO 3166-1 alpha-2 country code (e.g. "fr" for France).
 */
const Flag: FC<FlagProps> = ({ code }) => {
  switch (code.toLowerCase()) {
    case 'fr':
      return <FlagFranceIcon />;
    case 'ua':
      return <FlagUkraineIcon />;
    case 'es':
      return <FlagSpainIcon />;
    default:
      return <FlagPortugalIcon />;
  }
};

export default Flag;
