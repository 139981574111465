import axios, { AxiosRequestHeaders } from 'axios';

import dayjs from 'dayjs';

import {
  AuthLocalStorage,
  getProfilesFromSessionStorage,
  getToken,
  Profile,
} from 'application/helpers/sessionStorage/auth';
import { ApplicationType, x_app_type } from 'application/constants/apiHeaders';
import { store } from 'application/store';
import { UsersActions } from 'application/store/reducers/Users/actions';

// Create a new axios instance with the specified base URL and headers
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json',
    [x_app_type]: ApplicationType.HrDepartmentWeb,
  },
});

// Add a request interceptor

// Add a request interceptor to handle token refresh and authorization header setting
axiosInstance.interceptors.request.use(
  async (config) => {
    // Get the user profiles from session storage
    const profile: Profile[] = getProfilesFromSessionStorage()!;
    // Get the access token expiration date from local storage
    const date = localStorage.getItem(AuthLocalStorage.accessTokenExpiresAt);
    // Subtract one minute from the expiration date to allow for some buffer time
    const minusMinuteDate = date
      ? dayjs(date).subtract(1, 'minute').toISOString()
      : null;
    // Check if the current date is valid and within one minute of the token expiration
    const isDateValid =
      dayjs().isValid() && dayjs(minusMinuteDate).diff(dayjs(), 'minute') <= 1;
    // Check if the token has expired
    const isExpiredToken = minusMinuteDate
      ? dayjs().isAfter(minusMinuteDate)
      : false;

    // If the token is invalid or expired, attempt to refresh it using the refresh token and email from the user profile
    if (!isDateValid || isExpiredToken) {
      if (!profile?.length) return;

      const data = await store.dispatch(
        UsersActions.usersRefreshToken({
          refreshToken: profile[0].refreshToken,
          email: profile[0].email,
        }),
      );

      // If the token refresh was successful, update the authorization header with the new access token
      if (data && data.meta.requestStatus === 'fulfilled') {
        return {
          ...config,
          ...(getToken()
            ? {
                headers: {
                  ...config.headers,
                  Authorization: `Bearer ${getToken()}`,
                },
              }
            : {}),
        } as any;
      }
    }

    // If the token is valid and not expired, set the authorization header with the current access token
    const token = getToken();
    if (!token) return;

    return {
      ...config,
      ...((token
        ? {
            headers: {
              ...config.headers,
              Authorization: `Bearer ${token}`,
            },
          }
        : {}) as AxiosRequestHeaders),
    };
  },
  function (error) {
    // Handle request errors
    return Promise.reject(error);
  },
);

// Add a response interceptor to handle error responses
axiosInstance.interceptors.response.use(
  function (response) {
    // Handle response data
    return response;
  },
  function (error) {
    // Handle response error
    return Promise.reject(error);
  },
);

// Export the axios instance for use in other parts of the application
export default axiosInstance;
