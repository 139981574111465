import React, { FC } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { CloseIcon, FailedIcon } from 'application/assets';

import { ModalProps } from './models';
import { Wrapper } from './styles';
import { SecondaryButton } from '../index';
import { useTranslation } from 'react-i18next';

/**
 * Functional component for displaying a modal when a payment fails.
 *
 * @param opened - A boolean indicating whether the modal should be opened or closed.
 * @param onClose - A function to call when the user clicks the close icon or the "OK" button.
 * @param actionText - A string to display in the modal, describing what went wrong with the payment.
 * @param title - A string to display as the title of the modal.
 */
const PaymentFailedModal: FC<ModalProps> = ({
  opened,
  onClose,
  actionText,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <ModalMui open={opened}>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <div>
          <FailedIcon />
          <p>
            <span>{title}</span>
          </p>
          <p>{actionText}</p>
        </div>
        <SecondaryButton type={'button'} onClick={onClose}>
          {t('global.ok')}
        </SecondaryButton>
      </Wrapper>
    </ModalMui>
  );
};

export default PaymentFailedModal;
