import { UserPermissions } from 'application/constants/userPermissions';
import { getProfilePermissions } from './sessionStorage/auth';

export const featuresPermissions = () => {
  // Get the profile permissions from session storage
  const profilePermissions = getProfilePermissions();

  /**
   * Checks if the user has a specific permission.
   * @param feature - The permission to check for.
   * @returns True if the user has the permission, false otherwise.
   */
  const checkPermission = (feature: UserPermissions) => {
    // If the user doesn't have any permissions, return true
    if (!profilePermissions?.length) return true;

    // Return whether or not the user's permissions include the specified feature
    return profilePermissions.includes(feature);
  };

  /**
   * Checks if the user has permission to manage subscriptions.
   * @returns True if the user has the subscription management permission, false otherwise.
   */
  const hasSubscriptionManagementPermission = checkPermission(
    UserPermissions.subscriptionManagement,
  );

  /**
   * Checks if the user has permission to manage departments.
   * @returns True if the user has the department management permission, false otherwise.
   */
  const hasDepartmentManagementPermission = checkPermission(
    UserPermissions.departmentManagement,
  );

  /**
   * Checks if the user has permission to manage credits.
   * @returns True if the user has the credit management permission, false otherwise.
   */
  const hasCreditManagementPermission = checkPermission(
    UserPermissions.creditManagement,
  );

  /**
   * Checks if the user has permission to manage companies.
   * @returns True if the user has the company management permission, false otherwise.
   */
  const hasCompanyManagementPermission = checkPermission(
    UserPermissions.companyManagement,
  );

  /**
   * Checks if the user has permission to manage admins.
   * @returns True if the user has the admin management permission, false otherwise.
   */
  const hasAdminManagementPermission = checkPermission(
    UserPermissions.adminManagement,
  );

  /**
   * Checks if the user has permission to manage HR.
   * @returns True if the user has the HR management permission, false otherwise.
   */
  const hasHrManagementPermission = checkPermission(
    UserPermissions.hrManagement,
  );

  /**
   * Checks if the user has permission to manage locations.
   * @returns True if the user has the location management permission, false otherwise.
   */
  const hasLocationManagementPermission = checkPermission(
    UserPermissions.locationManagement,
  );

  /**
   * Checks if the user has permission to manage employees.
   * @returns True if the user has the employee management permission, false otherwise.
   */
  const hasEmployeeManagementPermission = checkPermission(
    UserPermissions.employeeManagement,
  );

  return {
    hasSubscriptionManagementPermission,
    hasDepartmentManagementPermission,
    hasCreditManagementPermission,
    hasCompanyManagementPermission,
    hasAdminManagementPermission,
    hasHrManagementPermission,
    hasLocationManagementPermission,
    hasEmployeeManagementPermission,
  };
};
