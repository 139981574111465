import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { StyledButton } from './styles';

interface PrimaryButtonProps {
  styleType?: 'default' | 'error';
  disabled?: boolean;
  children: ReactNode;
  onClick?: () => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
}

/**
 * A functional component that renders a styled button with customizable properties.
 *
 * @param styleType - The style of the button, either 'default' or 'error'.
 * @param disabled - Whether the button is disabled.
 * @param children - The content to display within the button.
 * @param onClick - An optional function to call when the button is clicked.
 * @param type - The HTML type attribute for the button.
 */
const PrimaryButton: FC<PrimaryButtonProps> = ({
  styleType = 'default',
  disabled = false,
  children,
  onClick,
  type,
}) => {
  return (
    <StyledButton
      styleType={styleType}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default PrimaryButton;
