import {
  HrDepartmentAdminCreateRequest,
  HrDepartmentCompanyResponse,
} from '@docbay/schemas';

export enum CompanyDataSessionStorage {
  STEP = 'STEP',
  FOCUS_ON_EMAIL = 'FOCUS_ON_EMAIL',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  COMPANY_INFORMATION = 'COMPANY_INFORMATION',
  TIMER_END_DATE = 'TIMER_END_DATE',
  NOT_ASK_ANYMORE = 'NOT_ASK_ANYMORE',
  HAS_EMAIL_ERROR = 'HAS_EMAIL_ERROR',
  HAS_PHONE_ERROR = 'HAS_PHONE_ERROR',
  EMAIL_OLD = 'EMAIL_OLD',
  PHONE_OLD = 'PHONE_OLD',
  USER_EMAIL = 'USER_EMAIL',
}

/**
 * Sets the provided data to session storage under the specified key.
 *
 * @param {CompanyDataSessionStorage} key - The key under which the data will be stored in session storage.
 * @param {any} data - The data to store in session storage. Must be serializable to JSON.
 */
export const setCompanyDataToSessionStorage = (
  key: CompanyDataSessionStorage,
  data: any,
) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

/**
 * Retrieves the data stored in session storage under the specified key, and parses it from JSON.
 *
 * @param {CompanyDataSessionStorage} key - The key of the data to retrieve from session storage.
 * @returns {any | null} - The parsed data if found, or null if not found.
 */
export const getCompanyDataFromSessionStorage = (
  key: CompanyDataSessionStorage,
) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

/**
 * Retrieves the personal information data stored in session storage.
 *
 * @returns {HrDepartmentAdminCreateRequest | null} - The parsed personal information data if found, or null if not found.
 */
export const getPersonalInfoFromSessionStorage = () => {
  const data: HrDepartmentAdminCreateRequest | null =
    getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.PERSONAL_INFORMATION,
    );
  return data;
};

/**
 * Retrieves the company information data stored in session storage.
 *
 * @returns {HrDepartmentCompanyResponse | null} - The parsed company information data if found, or null if not found.
 */
export const getCompanyInfoFromSessionStorage = () => {
  const data: HrDepartmentCompanyResponse | null =
    getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.COMPANY_INFORMATION,
    );
  return data;
};

/**
 * Checks if the email input should be focused on based on the session storage value.
 *
 * @returns {boolean} - A boolean indicating whether the email input should be focused on or not.
 */
export const focusOnEmailInput = () => {
  const focusOnEmail = sessionStorage.getItem(
    CompanyDataSessionStorage.FOCUS_ON_EMAIL,
  );
  const isFocused = focusOnEmail ? JSON.parse(focusOnEmail || '') : false;
  return isFocused;
};

/**
 * Sets the session storage value indicating whether the email input should be focused on or not.
 *
 * @param {boolean} value - A boolean indicating whether the email input should be focused on or not.
 */
export const setFocusOnEmailSessionStorage = (value: boolean) => {
  sessionStorage.setItem(
    CompanyDataSessionStorage.FOCUS_ON_EMAIL,
    JSON.stringify(value),
  );
};

/**
 * Clears all company data stored in session storage.
 */
export const clearCompanyDataFromSessionStorage = () => {
  const itemsToDelete = [
    CompanyDataSessionStorage.STEP,
    CompanyDataSessionStorage.FOCUS_ON_EMAIL,
    CompanyDataSessionStorage.PERSONAL_INFORMATION,
    CompanyDataSessionStorage.COMPANY_INFORMATION,
    CompanyDataSessionStorage.TIMER_END_DATE,
    CompanyDataSessionStorage.NOT_ASK_ANYMORE,
    CompanyDataSessionStorage.HAS_EMAIL_ERROR,
    CompanyDataSessionStorage.HAS_PHONE_ERROR,
    CompanyDataSessionStorage.EMAIL_OLD,
    CompanyDataSessionStorage.PHONE_OLD,
    CompanyDataSessionStorage.USER_EMAIL,
  ];
  itemsToDelete.forEach((item) => {
    sessionStorage.removeItem(item);
  });
};

/**
 * Deletes the specified company data from session storage.
 *
 * @param {CompanyDataSessionStorage[]} itemsToDelete - The keys of the company data to delete from session storage.
 */
export const deleteCompanyDataFromSessionStorage = (
  itemsToDelete: CompanyDataSessionStorage[],
) => {
  itemsToDelete.forEach((item) => {
    sessionStorage.removeItem(item);
  });
};
