import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { commonColors } from '../../theme';
import React from 'react';

/**
 * Styled component for a customized tooltip.
 *
 * @param {TooltipProps} props - Props to pass to the Tooltip component.
 * @return {JSX.Element} The styled tooltip component.
 */
const CustomizedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
    fontWeight: 500,
    padding: '8px',
    backgroundColor: commonColors.grey700,
    maxWidth: '144px',
  },
  [`& .${tooltipClasses.tooltipArrow} span:before`]: {
    backgroundColor: commonColors.grey700,
  },
}));

export default CustomizedTooltip;
