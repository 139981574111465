import React, {
  FC,
  useMemo,
  useRef,
  useState,
  KeyboardEvent,
  MouseEvent,
  useEffect,
} from 'react';
import {
  ExpandMoreIcon,
  CloseIcon,
  WarningTriangleIcon,
  ConfirmDeleteListIcon,
  CheckCircleIcon,
  LoaderIcon,
} from 'application/assets';
import useOnClickOutside from 'application/hooks/useOnClickOutside';
import Input from '../Input';
import { DropdownProps } from './models';
import {
  DropdownStyled,
  EmptyInput,
  OptionItem,
  OptionsList,
  OptionItemWrapper,
  DeleteSection,
  Message,
  ButtonWrapper,
  ErrorList,
  LoaderSection,
} from './styles';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { useDebounce } from 'application/hooks/useDebounce';
import {
  getDepartmentsByCompany,
  createDepartment,
  deleteDepartment,
} from 'application/store/reducers/Departments/actions';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from '../index';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { resetDeletedDepartment } from 'application/store/reducers/Departments/DepartmentsSlice';
import { ErrorCodes } from 'application/constants/error';
import { requestSelector } from 'application/store/reducers/request/selectors';

/**
 * Functional component for a dropdown menu that allows users to select, create, and delete departments.
 *
 * @param id - A unique identifier for the dropdown element.
 * @param label - The label for the dropdown element.
 * @param placeholder - The placeholder text for the input field in the dropdown.
 * @param searchPlaceholder - The placeholder text for the search input field in the dropdown.
 * @param value - The current value of the dropdown, representing the selected department's ID.
 * @param onChange - A function that is called when a new department is selected.
 * @param hint - An optional hint message to display below the dropdown.
 * @param disabled - A boolean indicating whether the dropdown is disabled.
 * @param errorMessage - An optional error message to display below the dropdown.
 * @param withSearch - A boolean indicating whether to include a search input field in the dropdown.
 * @param LeftIcon - An optional icon to display on the left side of the dropdown.
 */
const DepartmentDropdown: FC<DropdownProps> = ({
  id,
  label,
  placeholder,
  searchPlaceholder,
  value,
  onChange,
  hint,
  disabled,
  errorMessage,
  withSearch,
  LeftIcon,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const searchValue = useDebounce(searchItem, 500);
  const [departmentForDelete, setDepartmentDelete] = useState('');
  const [cantDelete, setCantDelete] = useState(false);

  const { departments, deleted } = useAppSelector((state) => state.departments);

  const {
    createDepartment: createDepartmentAction,
    deleteDepartment: deleteDepartmentAction,
  } = useAppSelector(requestSelector);

  const isLoading = createDepartmentAction?.loading;
  const isDeleteLoading = deleteDepartmentAction?.loading;

  // Fetch departments from the server if the list is empty
  useEffect(() => {
    !departments.length && dispatch(getDepartmentsByCompany());
  }, []);

  // Handle clicks outside of the dropdown component
  useOnClickOutside(ref, () => setIsActive(false));

  // Memoize the option list based on search value and available departments
  const optionList = useMemo(() => {
    if (!searchItem) return departments;

    return departments?.filter((item) =>
      String(item?.name!)?.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [searchValue, departments]);

  // Get the name of the currently selected department
  const getValue = () => {
    const currentOption = optionList.find(
      (item) => String(item.id) === String(value),
    );
    return currentOption?.name;
  };

  // Handle key presses in the search input field to add a new department
  const handleAddDepartment = async (event: KeyboardEvent<HTMLElement>) => {
    // Check if the option list is empty, the Enter key was pressed, and the search item has content
    if (!optionList.length && event.code === 'Enter' && searchItem.length) {
      event.preventDefault();

      const response = await dispatch(createDepartment(searchItem));

      isResponseFulfilled(response, () => {
        setSearchItem((event.target as HTMLInputElement).value);
        // Fetch departments from the server again to update the list with the new department
        dispatch(getDepartmentsByCompany());
      });
    }
  };

  const handleRemoveDepartment = (e: MouseEvent<SVGSVGElement>, id: string) => {
    e.stopPropagation();

    setDepartmentDelete(id);
  };

  const onSubmitDelete = async (isForceDelete?: boolean) => {
    const response = await dispatch(
      deleteDepartment({ departmentForDelete, isForceDelete }),
    );

    isResponseFulfilled(
      response,
      () => {},
      () => {
        if (
          response.payload?.response?.data?.errorCode ===
          ErrorCodes.HR_DEPARTMENT_HAS_EMPLOYEES_CONNECTED
        ) {
          setCantDelete(true);
        }
      },
    );
  };

  const handleCancelDelete = () => {
    setCantDelete(false);
    setDepartmentDelete('');
    dispatch(resetDeletedDepartment());
  };

  const handleCloseDeleteSection = () => {
    setCantDelete(false);
    setDepartmentDelete('');
    dispatch(resetDeletedDepartment());
    dispatch(getDepartmentsByCompany());
  };

  return (
    <DropdownStyled
      isActive={isActive}
      disabled={disabled}
      ref={ref}
      isError={!!errorMessage}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <section onMouseDown={() => !disabled && setIsActive(!isActive)}>
        {LeftIcon ? (
          <div>
            <LeftIcon />
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </div>
        ) : (
          <>
            {!value.length ? <span>{placeholder}</span> : <h2>{getValue()}</h2>}
          </>
        )}
        <ExpandMoreIcon />
      </section>
      {isActive && (
        <OptionsList className="optionList">
          {withSearch && (
            <Input
              id={'search'}
              type="search"
              value={searchItem}
              onChange={(e) => setSearchItem(e.target?.value || '')}
              placeholder={searchPlaceholder}
              onClear={() => setSearchItem('')}
              onKeyDown={handleAddDepartment}
            />
          )}
          {!optionList.length ? (
            <>
              {isLoading || isDeleteLoading ? (
                <LoaderSection>
                  <LoaderIcon />
                </LoaderSection>
              ) : (
                <EmptyInput>
                  {t('department_dropdown.create_department_text')}
                </EmptyInput>
              )}
            </>
          ) : (
            optionList.map((item) => (
              <OptionItemWrapper
                forDelete={item.id === departmentForDelete}
                key={item.name}
              >
                <OptionItem
                  onClick={() => {
                    onChange(item);
                    setIsActive(false);
                  }}
                  selected={value === String(item.id)}
                >
                  <span>{item.name}</span>

                  <CloseIcon
                    onClick={(e: MouseEvent<SVGSVGElement>) =>
                      handleRemoveDepartment(e, item.id)
                    }
                  />
                </OptionItem>
                {deleted && item.id === departmentForDelete && (
                  <DeleteSection>
                    <Message>
                      <CheckCircleIcon />
                      {t('department_dropdown.department_deleted')}
                    </Message>
                    <ButtonWrapper>
                      <SecondaryButton
                        type={'button'}
                        onClick={handleCloseDeleteSection}
                      >
                        {t('global.got_it')}
                      </SecondaryButton>
                    </ButtonWrapper>
                  </DeleteSection>
                )}

                {isDeleteLoading && item.id === departmentForDelete ? (
                  <LoaderSection>
                    <LoaderIcon />
                  </LoaderSection>
                ) : (
                  !deleted &&
                  item.id === departmentForDelete &&
                  (cantDelete ? (
                    <DeleteSection>
                      <Message>
                        {t('department_dropdown.cant_be_deleted_text')}
                      </Message>
                      <ErrorList>
                        <div>
                          <ConfirmDeleteListIcon />
                          {t('department_dropdown.cant_be_deleted_text_item_1')}
                        </div>
                        <div>
                          <ConfirmDeleteListIcon />
                          {t('department_dropdown.cant_be_deleted_text_item_2')}
                        </div>
                      </ErrorList>
                      <ButtonWrapper>
                        <SecondaryButton
                          type={'button'}
                          onClick={handleCancelDelete}
                        >
                          {t('global.cancel')}
                        </SecondaryButton>
                        <PrimaryButton
                          onClick={() => onSubmitDelete(true)}
                          styleType={'error'}
                          type={'button'}
                        >
                          {t('global.confirm')}
                        </PrimaryButton>
                      </ButtonWrapper>
                    </DeleteSection>
                  ) : (
                    <DeleteSection>
                      <Message>
                        <WarningTriangleIcon />
                        {t('department_dropdown.delete_department_text')}
                      </Message>
                      <ButtonWrapper>
                        <SecondaryButton
                          type={'button'}
                          onClick={() => setDepartmentDelete('')}
                        >
                          {t('global.cancel')}
                        </SecondaryButton>
                        <PrimaryButton
                          onClick={() => onSubmitDelete(false)}
                          styleType={'error'}
                          type={'button'}
                        >
                          {t('global.confirm')}
                        </PrimaryButton>
                      </ButtonWrapper>
                    </DeleteSection>
                  ))
                )}
              </OptionItemWrapper>
            ))
          )}
        </OptionsList>
      )}

      {(hint || errorMessage) && (
        <span>{errorMessage ? errorMessage : hint}</span>
      )}
    </DropdownStyled>
  );
};

export default DepartmentDropdown;
