import {
  HrDepartmentAdminConfirmSignUpRequest,
  HrDepartmentAdminConfirmSignupResponse,
  HrDepartmentCompanyCreateRequest,
  HrDepartmentCompanyResponse,
  ResendConfirmationCodeDto,
} from '@docbay/schemas';

import axiosNonTokenInstance from './axiosNonTokenInstance';

/**
 * API methods for company registration.
 */
export const CompanyRegistrationAPI = {
  /**
   * Sign up a new company.
   *
   * @param data - An object containing the necessary information to sign up a new company, such as
   *               name, email, and password.
   * @returns A promise that resolves with an object representing the response from the server,
   *          which contains details about the newly created company.
   */
  companySignUp: (data: HrDepartmentCompanyCreateRequest) => {
    return axiosNonTokenInstance.post<HrDepartmentCompanyResponse>(
      '/hr-department/company/sign-up',
      data,
    );
  },

  /**
   * Confirm a company's sign up.
   *
   * @param data - An object containing the necessary information to confirm a company's sign up, such
   *               as confirmation code and email address.
   * @returns A promise that resolves with an object representing the response from the server,
   *          which contains details about the confirmed company.
   */
  companyConfirmSingUp: (data: HrDepartmentAdminConfirmSignUpRequest) => {
    return axiosNonTokenInstance.post<HrDepartmentAdminConfirmSignupResponse>(
      '/hr-department/company/confirm-sign-up',
      data,
    );
  },

  /**
   * Resend a confirmation code to a company's email address.
   *
   * @param data - An object containing the necessary information to resend a confirmation code, such
   *               as the company's email address.
   * @returns A promise that resolves with an object representing the response from the server,
   *          which may contain details about the resending process or any errors encountered.
   */
  resendConfirmationCode: (data: ResendConfirmationCodeDto) => {
    return axiosNonTokenInstance.post(
      '/hr-department/company/resend-confirmation-code',
      data,
    );
  },
};
